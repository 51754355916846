import { User } from "@/types";
import { combineReducers } from "@reduxjs/toolkit";

import { default as user } from "./user";

export interface RootState {
  user: User;
}

const rootReducer = combineReducers({
  user,
});

export default rootReducer;
