import { Breadcrumbs, FullContainer, InnerContainer, Navbar } from "@/components";
import { SearchStocksView } from "@/views";
import { H3 } from "@blueprintjs/core";
import { UilComparison } from "@iconscout/react-unicons";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "35rem",
    padding: "1rem 0 3rem",
  },
};

const SearchStocksPage = () => {
  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ alignItems: "center" }}>
        <div className="fade-in" style={styles.container}>
          <Breadcrumbs
            items={[
              { text: "My Brief", href: "/my/home" },
              { text: "Search stocks", href: "/my/stocks", isCurrent: true },
            ]}
            style={{ marginBottom: "2rem" }}
          />
          <H3 style={{ marginBottom: 20 }}>
            <UilComparison size={24} style={{ marginRight: 5, marginBottom: -3 }} />
            Search stocks
          </H3>
          <SearchStocksView />
        </div>
      </InnerContainer>
    </FullContainer>
  );
};

export default SearchStocksPage;
