import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Colors, Divider } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import { useDispatch } from "react-redux";
import { RESET_USER } from "@/store/types";
import { setAuthToken, useWidth } from "@/utils";
import { useSelector } from "react-redux";
import { RootState } from "@/store/reducers";
import { UilFeedback, UilSetting, UilSignOutAlt, UilUserCircle } from "@iconscout/react-unicons";
import { JwtRole } from "@/types";

const styles: Record<string, React.CSSProperties> = {
  buttonList: {
    display: "flex",
    flexDirection: "column",
    padding: "0.5rem",
  },
};

const UserDropdown = () => {
  const { sm } = useWidth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state: RootState) => state.user);

  const isAdmin = user?.roles?.includes(JwtRole.ADMIN);
  const isListUser = user?.roles?.includes(JwtRole.LIST_USER);

  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch({ type: RESET_USER });
    setAuthToken();
    navigate("/login");
  };

  return (
    <Popover2
      content={
        <div style={styles.buttonList}>
          <Link to="/my/settings">
            <Button fill minimal icon={<UilSetting size={18} />} text="Settings" />
          </Link>
          <Link to="/my/feedback">
            <Button fill minimal icon={<UilFeedback size={18} />} text="Feedback" />
          </Link>
          <Divider />
          {isAdmin && (
            <>
              <Link to="/admin">
                <Button fill minimal icon="dashboard" text="Admin" />
              </Link>
              <Divider />
            </>
          )}
          {isListUser && (
            <>
              <Link to="/lists">
                <Button fill minimal icon="list" text="Email Lists" />
              </Link>
              <Divider />
            </>
          )}
          <Button fill onClick={onLogout} minimal icon={<UilSignOutAlt size={18} />} text="Logout" />
        </div>
      }
      interactionKind="click"
      isOpen={isOpen}
      onInteraction={(state) => setIsOpen(state)}
      placement="bottom"
    >
      <Button
        minimal
        icon={<UilUserCircle size={18} />}
        color={Colors.DARK_GRAY1}
        text={sm ? "" : user.email.substring(0, 21) + (user.email.length > 21 ? "..." : "")}
      />
    </Popover2>
  );
};

export default UserDropdown;
