import { Intent, ProgressBar } from "@blueprintjs/core";
import { useEffect, useState } from "react";

interface Props {
  seconds: number;
  style?: React.CSSProperties;
  intent?: Intent;
}

const LoadingBar = ({ seconds, intent = Intent.PRIMARY, style = {} }: Props) => {
  const [progress, setProgress] = useState<number>(0.0);

  const rate = 0.01;

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress + rate > 0.98) {
          setProgress(0.98);
          clearInterval(interval);
        }
        return prevProgress + rate;
      });
    }, seconds * 1000.0 * rate);
  }, []);

  return (
    <div style={style}>
      <ProgressBar animate value={progress} intent={intent} />
    </div>
  );
};

export default LoadingBar;
