import { FullContainer, InnerContainer, Input, Navbar } from "@/components";
import { AddSectionView } from "@/views";
import { H2, Intent } from "@blueprintjs/core";
import { toaster } from "@/utils";
import { useJoin, useSaveInitialSection } from "@/hooks/data";
import { useDispatch } from "react-redux";
import { SET_USER } from "@/store/types";
import { useNavigate } from "react-router-dom";
import { useSearchParamsState } from "@/hooks";
import { SearchParamKey } from "@/types";
import { useState } from "react";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "35rem",
    padding: "3rem 0 15rem",
  },
};

const AddInitialSectionPage = () => {
  const [searchParamsEmail] = useSearchParamsState(SearchParamKey.INITIAL_USER_EMAIL);
  const [email, setEmail] = useState<string>(searchParamsEmail || "");

  const { saveInitialSection } = useSaveInitialSection();

  const navigate = useNavigate();
  const { join } = useJoin();
  const dispatch = useDispatch();

  const onSubmit = async ({ originalPrompt, enhancedPrompt }): Promise<void> => {
    try {
      if (!email) {
        toaster.show({ message: "Please enter an email", intent: Intent.DANGER });
        return;
      }
      const initialSavedSection = await saveInitialSection({
        originalPrompt,
        enhancedPrompt,
      });
      const loggedInUser = await join({ email, initialSectionId: initialSavedSection.id });

      dispatch({ type: SET_USER, payload: loggedInUser });
      navigate("/my/home#converted");
    } catch (err) {}
  };

  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ alignItems: "center" }}>
        <div className="fade-in" style={styles.container}>
          <div style={{ display: "flex", alignItems: "center", gap: 10, marginBottom: 20 }}>
            <img src={require("@/assets/icon-tight.png")} style={{ height: "1.5rem" }} />
            <H2 style={{ marginBottom: 0 }}>Get Started</H2>
          </div>
          <Input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" type="email" style={{ marginBottom: 20 }} />
          <AddSectionView addSectionText="Looks Good" onAddSection={onSubmit} />
        </div>
      </InnerContainer>
    </FullContainer>
  );
};

export default AddInitialSectionPage;
