import { parse, ParseLocalConfig, ParseResult } from "papaparse";

export const parseCsv = (file: File, options: Omit<ParseLocalConfig, "complete"> = {}): Promise<ParseResult<any>> => {
  return new Promise((resolve, reject) => {
    parse(file, {
      complete: resolve,
      error: reject,
      ...options,
    });
  });
};
