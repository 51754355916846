import { useEffect, useState } from "react";

import { Input } from "@/components";
import { Button, Intent, Text } from "@blueprintjs/core";
import { useStocks } from "@/hooks/data";
import { CachedData, LocalStorageKey, Ticker } from "@/types";
import StockRow from "./StockRow";
import { useLocalStorageState } from "@/hooks";
import moment from "moment";
import { UilSearchAlt } from "@iconscout/react-unicons";

const styles: Record<string, React.CSSProperties> = {
  searchContainer: {
    display: "flex",
    gap: 10,
  },
};

const SearchStocksView = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearchCache] = useLocalStorageState<CachedData<string>>(LocalStorageKey.CACHE_SEARCH_STOCK_QUERY, {
    data: "",
    exp: Math.min(),
  });
  const [tickers, setTickers] = useLocalStorageState<Array<Ticker>>(LocalStorageKey.CACHE_SEARCH_STOCK_RESULTS, []);
  const [hasSearched, setHasSearched] = useState<boolean>(false);

  const { searchStocks } = useStocks();

  const loadStocks = async () => {
    try {
      setLoading(true);
      setTickers([]);
      const t = await searchStocks(search.data);
      setTickers(t);
      setHasSearched(true);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    loadStocks();
  };

  useEffect(() => {
    if (search && moment().unix() < search.exp) {
      if (tickers && tickers.length) {
        setTickers(tickers);
      } else {
        loadStocks();
      }
    } else {
      setSearchCache({ data: "", exp: moment().add({ minutes: 1 }).unix() });
      setTickers([]);
    }
  }, []);

  return (
    <>
      <form style={styles.searchContainer} onSubmit={onSubmit}>
        <Input
          autoFocus
          fill
          value={search.data}
          onChange={(e) => {
            setSearchCache({ data: e.target.value, exp: moment().add({ minutes: 1 }).unix() });
            setTickers([]);
          }}
          maxLength={100}
          placeholder="ex: Apple Inc or AAPL"
        />
        <div>
          <Button disabled={!search.data} type="submit" rightIcon={<UilSearchAlt size={18} />} intent={Intent.PRIMARY} loading={loading}>
            Search
          </Button>
        </div>
      </form>
      {!hasSearched && (
        <Text style={{ marginTop: "1rem" }}>
          <i>Search by symbol or company name.</i>
        </Text>
      )}
      {hasSearched && !Boolean(tickers.length) && (
        <Text style={{ marginTop: "1rem" }}>
          <i>No stocks found.</i>
        </Text>
      )}
      <div style={{ marginTop: "1rem" }} />
      {tickers.map((ticker) => (
        <StockRow ticker={ticker} key={ticker.ticker} />
      ))}
    </>
  );
};

export default SearchStocksView;
