import { useBriefSendFailureMetrics } from "@/hooks/data";
import { Divider, H4, HTMLTable } from "@blueprintjs/core";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const AdminBriefSendFailureMetrics = () => {
  const { briefSendFailureMetrics, loading } = useBriefSendFailureMetrics();

  return (
    <>
      <H4>Brief Send Success Rate</H4>
      <Divider style={{ margin: 0 }} />
      <HTMLTable style={{ width: "100%" }} bordered>
        <thead>
          <tr>
            <th>Date</th>
            <th>Total</th>
            <th>Failed</th>
            <th>Fail %</th>
          </tr>
        </thead>
        <tbody>
          {briefSendFailureMetrics.map(({ day, numSent, numFailed, failurePercentage }) => (
            <tr key={day}>
              <td>{moment(day).format("MMM Do")}</td>
              <td>{numSent}</td>
              <td>{numFailed}</td>
              <td>{failurePercentage.toFixed(2)}%</td>
            </tr>
          ))}
          {!Boolean(briefSendFailureMetrics.length) && !loading && (
            <tr>
              <td colSpan={4}>No data</td>
            </tr>
          )}
        </tbody>
      </HTMLTable>
      {loading && <Skeleton count={10} height={10} />}
    </>
  );
};

export default AdminBriefSendFailureMetrics;
