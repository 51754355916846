import { clientAxiosInstance } from "@/services";
import { Article, CachedData, LocalStorageKey } from "@/types";
import { useLocalStorage } from "@/hooks";
import moment from "moment";
import { useEffect, useState } from "react";

interface UseSectionNews {
  loading: boolean;
  articles: Array<Article>;
}

export const useSectionNews = (sectionId: string): UseSectionNews => {
  const { getValue: getCachedNews, setValue: setCachedNews } = useLocalStorage<CachedData<Array<Article>>>();

  const [loading, setLoading] = useState<boolean>(true);
  const [articles, setArticles] = useState<Array<Article>>([]);

  const getSectionNews = async () => {
    setLoading(true);
    try {
      const cacheNewsKey = `${sectionId}_${LocalStorageKey.CACHE_SECTION_NEWS}`;

      const cachedNews = getCachedNews(cacheNewsKey);
      if (cachedNews && moment().unix() < cachedNews.exp) {
        setArticles(cachedNews.data);
      } else {
        const response = await clientAxiosInstance.get<Array<Article>>(`/sections/${sectionId}/news`);
        setCachedNews(cacheNewsKey, {
          exp: moment().add({ minutes: 15 }).unix(),
          data: response.data,
        });
        setArticles(response.data);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    setArticles([]);
    getSectionNews();
  }, [sectionId]);

  return {
    loading,
    articles,
  };
};
