import axios from "axios";
import { CURATED_URL } from "@/utils/constants";
import { setAuthToken } from "@/utils";
import { LocalStorageKey } from "@/types";

export const curatedAxiosInstance = axios.create({
  baseURL: CURATED_URL,
});

curatedAxiosInstance.interceptors.request.use((config) => {
  const token = localStorage[LocalStorageKey.JWT_TOKEN];
  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

curatedAxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log(error);
    if (error.response.status === 401) {
      setAuthToken();
      window.location.href = "/login";
    }
    throw new Error(error?.response?.data?.error || error?.response?.data?.message || error?.message);
  }
);
