import { clientAxiosInstance } from "@/services";
import { toaster } from "@/utils";
import { Intent } from "@blueprintjs/core";

export const login = async (input: { email: string }): Promise<void> => {
  try {
    await clientAxiosInstance.post("/login", input);
  } catch (e) {
    toaster.show({
      message: e?.message || "Unknown error logging in.",
      intent: Intent.DANGER,
    });
    throw e;
  }
};
