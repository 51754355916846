import { FullContainer, InnerContainer, LogoutNavbar } from "@/components";
import { useSections } from "@/hooks/data";
import { toaster } from "@/utils";
import { AddSectionView } from "@/views";
import { H3, Intent, Text } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "35rem",
    padding: "3rem 0",
  },
};

const OnboardingAddSection = () => {
  const navigate = useNavigate();

  const { addSection } = useSections();

  const onSave = async ({ originalPrompt, enhancedPrompt }) => {
    try {
      await addSection({
        originalPrompt,
        enhancedPrompt,
      });
      navigate("/my/home");
    } catch (e) {
      toaster.show({ message: e.message, intent: Intent.DANGER });
    }
  };

  return (
    <FullContainer>
      <LogoutNavbar title="Onboarding" />
      <InnerContainer style={{ alignItems: "center" }}>
        <div style={styles.container}>
          <H3>Let's add your first section</H3>
          <Text style={{ marginBottom: 30 }}>
            In your brief, each section represents a topic and will be populated with articles that are relevant to your specific interest.
          </Text>
          <AddSectionView addSectionText="Looks Good" onAddSection={onSave} />
        </div>
      </InnerContainer>
    </FullContainer>
  );
};

export default OnboardingAddSection;
