import { clientAxiosInstance } from "@/services";
import { toaster } from "@/utils";
import { Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";

interface LinkClickMetric {
  date: string;
  oneClick: number;
  twoClicks: number;
}

export interface UseLinkClicksMetrics {
  loading: boolean;
  linkClicks: Array<LinkClickMetric>;
  reload: () => Promise<void>;
}

export const useLinkClicksMetrics = (): UseLinkClicksMetrics => {
  const [loading, setLoading] = useState<boolean>(true);
  const [linkClicks, setLinkClicks] = useState<Array<LinkClickMetric>>([]);

  const getOneLinkClicks = async () => {
    try {
      const response = await clientAxiosInstance.get<Record<string, number>>("/admin/metrics/link-clicks/one");
      return response.data;
    } catch (e) {
      toaster.show({ intent: Intent.DANGER, message: e.message });
    }
  };

  const getTwoLinkClicks = async () => {
    setLoading(true);
    try {
      const response = await clientAxiosInstance.get<Record<string, number>>("/admin/metrics/link-clicks/two");
      return response.data;
    } catch (e) {
      toaster.show({ intent: Intent.DANGER, message: e.message });
    }
  };

  const getLinkClicks = async () => {
    setLoading(true);
    const oneClicks = await getOneLinkClicks();
    const twoClicks = await getTwoLinkClicks();
    setLinkClicks(
      Object.keys(oneClicks).map((date) => ({
        date,
        oneClick: oneClicks[date] || 0,
        twoClicks: twoClicks[date] || 0,
      }))
    );
    setLoading(false);
  };

  useEffect(() => {
    getLinkClicks();
  }, []);

  return {
    loading,
    linkClicks,
    reload: getLinkClicks,
  };
};
