import { TickerWithArticles } from "@/types";
import { chartDecreasingEmoji, chartIncreasingEmoji } from "@/utils";
import { Colors, Divider, H4 } from "@blueprintjs/core";
import TickerSection from "./TickerSection";
import React from "react";

interface Props {
  tickers: Array<TickerWithArticles>;
}

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    background: `linear-gradient(-55deg, ${Colors.RED3} 50%, ${Colors.GREEN3} 50%)`,
    padding: 4,
    borderRadius: 8,
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    background: "white",
    borderRadius: 4,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.75rem 0",
    borderBottom: `1px solid ${Colors.LIGHT_GRAY2}`,
  },
};

const TickerSections = ({ tickers }: Props) => {
  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.header}>
          <H4 style={{ margin: 0 }}>
            <span dangerouslySetInnerHTML={{ __html: chartIncreasingEmoji }} /> Markets{" "}
            <span dangerouslySetInnerHTML={{ __html: chartDecreasingEmoji }} />
          </H4>
        </div>
        {tickers.map((ticker, i) => (
          <React.Fragment key={ticker.ticker}>
            <TickerSection ticker={ticker} />
            {i !== tickers.length - 1 && <Divider style={{ borderColor: Colors.LIGHT_GRAY2, margin: 0 }} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default TickerSections;
