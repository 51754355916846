import {
  AddSectionPage,
  FeedbackPage,
  NewsPage,
  NotFound,
  SearchStocksPage,
  SectionsPage,
  SettingsPage,
  StockDetailPage,
  ViewBriefPage,
} from "@/scenes";
import { RootState } from "@/store/reducers";
import { Spinner } from "@blueprintjs/core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ConfirmEmailProxy } from "./ConfirmEmail";
import { tryToGetUser } from "../getUser";
import { OnboardingProxy } from "./OnboardingProxy";
import { IsSubscribedProxy } from "./IsSubscribedProxy";
import { SearchParamKey } from "@/types";

const MyRouter = () => {
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const redirectToLogin = () => {
    navigate({
      pathname: "/login",
      search: createSearchParams({
        [SearchParamKey.LOGIN_REDIRECT]: pathname,
      }).toString(),
    });
  };

  useEffect(() => {
    if (!user) tryToGetUser(dispatch, redirectToLogin);
  }, []);

  return (
    <>
      {!user && <Spinner size={20} style={{ position: "fixed", top: "50%", left: "50%" }} />}
      {user && (
        <ConfirmEmailProxy user={user}>
          <OnboardingProxy user={user}>
            <IsSubscribedProxy>
              <Routes>
                <Route path="settings" element={<SettingsPage />} />
                <Route path="feedback" element={<FeedbackPage />} />
                <Route path="home" element={<SectionsPage />} />
                <Route path="brief" element={<ViewBriefPage />} />
                <Route path="news" element={<NewsPage />} />
                <Route path="add-section" element={<AddSectionPage />} />
                <Route path="stocks" element={<SearchStocksPage />} />
                <Route path="stocks/:symbol" element={<StockDetailPage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </IsSubscribedProxy>
          </OnboardingProxy>
        </ConfirmEmailProxy>
      )}
    </>
  );
};

export default MyRouter;
