import { clientAxiosInstance } from "@/services";
import { Article, Ticker, IDailyOpenClose, IAggsResults, CachedData, LocalStorageKey } from "@/types";
import moment, { Moment } from "moment-timezone";
import { useLocalStorage } from "@/hooks";

interface OpenCloseData {
  result: IDailyOpenClose;
  date: string;
}

interface TwoDayOpenCloseData {
  current: OpenCloseData;
  previous: OpenCloseData;
}

interface UseStock {
  getStockDetail: (symbol: string) => Promise<Ticker>;
  getStockNews: (symbol: string) => Promise<Array<Article>>;
  getStockDailyOpenClose: (symbol: string) => Promise<TwoDayOpenCloseData>;
  getAggregateData: (symbol: string, today: string) => Promise<Array<IAggsResults>>;
}

export const useStock = (): UseStock => {
  const { getValue: getOpenClose, setValue: setOpenClose } = useLocalStorage<CachedData<TwoDayOpenCloseData>>();
  const { getValue: getCachedNews, setValue: setCachedNews } = useLocalStorage<CachedData<Array<Article>>>();

  const getStockDetail: UseStock["getStockDetail"] = async (symbol) => {
    const ticker = await clientAxiosInstance.get<Ticker>(`/stocks/${symbol}`);
    return ticker.data;
  };

  const getStockNews: UseStock["getStockNews"] = async (symbol) => {
    const cacheNewsKey = `${symbol}_${LocalStorageKey.CACHE_TICKER_NEWS}`;

    const cachedNews = getCachedNews(cacheNewsKey);
    if (cachedNews && moment().unix() < cachedNews.exp) {
      return cachedNews.data;
    }
    const articles = await clientAxiosInstance.get<Array<Article>>(`/stocks/${symbol}/news`);
    setCachedNews(cacheNewsKey, {
      exp: moment().add({ minutes: 15 }).unix(),
      data: articles.data,
    });
    return articles.data;
  };

  const getAggregateData: UseStock["getAggregateData"] = async (symbol, today) => {
    const response = await clientAxiosInstance.get<Array<IAggsResults>>(`/stocks/${symbol}/aggregate`, {
      params: {
        from: moment(today).tz("America/New_York").startOf("day").unix() * 1000,
        to: moment(today).tz("America/New_York").endOf("day").unix() * 1000,
      },
    });
    return response.data;
  };

  const fetchStockDailyOpenClose = async (symbol: string, date: Moment, retries: number = 0): Promise<OpenCloseData> => {
    try {
      const result = await clientAxiosInstance.get<IDailyOpenClose>(`/stocks/${symbol}/daily-open-close`, {
        params: { date: date.format("YYYY-MM-DD") },
      });
      if (!result.data.open) throw new Error("No open price found. Revert to previous day.");
      if (!result.data.close) {
        const aggregates = await getAggregateData(symbol, date.format("YYYY-MM-DD"));
        const current = aggregates?.[aggregates.length - 1]?.c;
        if (!current) throw new Error("No close price found. Revert to previous day.");
        result.data.close = current;
      }
      return {
        result: result.data,
        date: moment(result.data.from).format(),
      };
    } catch (e) {
      if (retries >= 4) throw e;
      return fetchStockDailyOpenClose(symbol, date.subtract({ days: 1 }), retries + 1);
    }
  };

  const getStockDailyOpenClose: UseStock["getStockDailyOpenClose"] = async (symbol) => {
    const cacheOpenClosesKey = `${symbol}_${LocalStorageKey.CACHE_OPEN_CLOSES}`;

    const cachedOpenCloseData = getOpenClose(cacheOpenClosesKey);
    if (cachedOpenCloseData && moment().unix() < cachedOpenCloseData.exp) {
      return cachedOpenCloseData.data;
    }

    const current = await fetchStockDailyOpenClose(symbol, moment());
    const previous = await fetchStockDailyOpenClose(symbol, moment(current.date).clone().subtract({ days: 1 }));

    setOpenClose(cacheOpenClosesKey, {
      exp: moment().add({ minutes: 5 }).unix(),
      data: { current, previous },
    });

    return { current, previous };
  };

  return {
    getStockDetail,
    getStockNews,
    getStockDailyOpenClose,
    getAggregateData,
  };
};
