import { ArticleSource } from "@/types";
import { mapArticleSourceToEmoji, mapArticleSourceToLabel } from "@/utils";
import { Text } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";

interface Props {
  articleSource: ArticleSource;
  showTooltip?: boolean;
}

const ArticleSourceIcon = ({ articleSource, showTooltip = true }: Props) => (
  <Tooltip2
    hoverOpenDelay={1000}
    minimal
    content={showTooltip ? <Text style={{ fontSize: "0.75rem" }}>{mapArticleSourceToLabel(articleSource)}</Text> : null}
  >
    <span
      dangerouslySetInnerHTML={{
        __html: mapArticleSourceToEmoji(articleSource),
      }}
    />
  </Tooltip2>
);

export default ArticleSourceIcon;
