import { clientAxiosInstance } from "@/services";
import { BriefSendFailureMetric } from "@/types";
import { toaster } from "@/utils";
import { Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";

interface UseBriefSendFailureMetrics {
  loading: boolean;
  briefSendFailureMetrics: Array<BriefSendFailureMetric>;
}

export const useBriefSendFailureMetrics = (): UseBriefSendFailureMetrics => {
  const [loading, setLoading] = useState<boolean>(false);
  const [briefSendFailureMetrics, setBriefSendFailureMetrics] = useState<Array<BriefSendFailureMetric>>([]);

  const getBriefSendFailureMetrics = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await clientAxiosInstance.get<Array<BriefSendFailureMetric>>("/admin/metrics/brief-sends/failure-rate");
      setBriefSendFailureMetrics(response.data);
    } catch (e) {
      console.log(e);
      toaster.show({
        message: e.message,
        intent: Intent.DANGER,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getBriefSendFailureMetrics();
  }, []);

  return {
    loading,
    briefSendFailureMetrics,
  };
};
