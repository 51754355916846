import { FullScreenSpinner } from "@/components";
import { SET_USER } from "@/store/types";
import { toaster } from "@/utils";
import { Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { verifyEmail } from "./verifyEmail";
import { useSearchParamsState } from "@/hooks";
import { SearchParamKey } from "@/types";

const VerifyEmailPage = () => {
  const [loginRedirect] = useSearchParamsState(SearchParamKey.LOGIN_REDIRECT);
  const [loading, setLoading] = useState<boolean>(true);
  const { jwt } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!jwt) navigate("/login");

  useEffect(() => {
    (async () => {
      try {
        const user = await verifyEmail(jwt);
        dispatch({ type: SET_USER, payload: user });
        toaster.show({
          message: "Successfully logged in!",
          intent: Intent.SUCCESS,
        });
      } catch (e) {
        toaster.show({
          message: e?.message || "Unknown error verifying email.",
          intent: Intent.DANGER,
        });
      }
      navigate(loginRedirect || "/my/home");
      setLoading(false);
    })();
  }, []);

  return loading ? <FullScreenSpinner /> : <></>;
};

export default VerifyEmailPage;
