import { MultilineInput } from "@/components";
import { BriefDisplayView } from "@/views";
import { Button } from "@blueprintjs/core";
import { useState } from "react";
import { useAdminManualTest } from "./useAdminManualTest";

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  margin: "0 auto",
  paddingBottom: "10rem",
  maxWidth: 500,
  gap: "5rem",
};

const formStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
};

const AdminManualTest = () => {
  const [userAttributes, setUserAttributes] = useState<string>("");

  const { fetchBriefSend, briefSend, loading } = useAdminManualTest();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchBriefSend(userAttributes);
  };

  return (
    <div style={containerStyle}>
      <form onSubmit={handleSubmit} style={formStyle}>
        <MultilineInput allowEnter onChange={(e) => setUserAttributes(e.target.value)} value={userAttributes} />
        <Button type="submit">Submit</Button>
      </form>
      <BriefDisplayView briefSend={briefSend} loading={loading} />
    </div>
  );
};

export default AdminManualTest;
