export enum LocalStorageKey {
  JWT_TOKEN = "jwtToken",
  NEWS_PAGE = "newsPageArticles",
  NEWS_PAGE_EXP = "newsPageArticlesExp",
  CACHE_SEARCH_STOCK_QUERY = "cachedSearchStockQuery",
  CACHE_SEARCH_STOCK_RESULTS = "cachedSearchStockResults",
  CACHE_SEARCH_STOCK_EXP = "cachedSearchStockExp",
  CACHE_OPEN_CLOSES = "cachedOpenCloses",
  CACHE_TICKER_NEWS = "cachedTickerNews",
  CACHE_INITIAL_SECTION = "cachedInitialSection",
  CACHE_SECTION_NEWS = "cachedSectionNews",
  POPULAR_NEWS = "popularNews",
}
