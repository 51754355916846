import React from "react";

import { Section } from "@/types";

import { DragDropContext, Droppable, DropResult, DroppableProvided, DroppableStateSnapshot } from "react-beautiful-dnd";
import SectionRow from "./SectionRow";
import { toaster } from "@/utils";
import { Intent } from "@blueprintjs/core";
import { UseSections } from "@/hooks/data";

const styles: Record<string, React.CSSProperties> = {
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
};

interface Props {
  sections: Array<Section>;
  useSectionsInstance: UseSections;
}

const DragDropContextJSX: any = DragDropContext;
const DroppableJSX: any = Droppable;

const SectionsList = ({ sections, useSectionsInstance }: Props) => {
  const { reorderSections, reloadSections } = useSectionsInstance;

  const onDragEnd = async (result: DropResult) => {
    try {
      if (!result.destination) return;
      if (result.destination.index === result.source.index) return;
      const { source, destination } = result;
      const draggedSection = sections[source.index];
      const aboveDestinationSection = sections?.[destination.index < source.index ? destination.index - 1 : destination.index];
      const belowDestinationSection = sections?.[destination.index < source.index ? destination.index : destination.index + 1];
      const belowSourceSection = sections?.[source.index + 1];
      const aboveSourceSection = sections?.[source.index - 1];

      const payload = {
        draggedSection: {
          ...draggedSection,
          topSectionId: aboveDestinationSection?.id || null,
        },
        belowDestinationSection: belowDestinationSection
          ? {
              ...belowDestinationSection,
              topSectionId: draggedSection?.id || null,
            }
          : null,
        belowSourceSection: belowSourceSection
          ? {
              ...belowSourceSection,
              topSectionId: aboveSourceSection?.id || null,
            }
          : null,
      };

      await reorderSections(payload, result);
    } catch (e) {
      reloadSections();
      console.log(e);
      toaster.show({ message: e?.message || "Error reordering section.", intent: Intent.DANGER });
    }
  };

  return (
    <>
      <DragDropContextJSX onDragEnd={onDragEnd}>
        <DroppableJSX droppableId="sections-list-dnd">
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={styles.sectionContainer}>
              {sections.map((section, index) => (
                <SectionRow useSectionsInstance={useSectionsInstance} key={section.id} index={index} section={section} />
              ))}
              {provided.placeholder}
            </div>
          )}
        </DroppableJSX>
      </DragDropContextJSX>
    </>
  );
};

export default SectionsList;
