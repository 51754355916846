import { Input, LoadingBar, SuggestionChips } from "@/components";
import { UseInstantBrief } from "@/hooks/data";
import { PromptType } from "@/types";
import { useWidth } from "@/utils";
import { Button, Colors, Divider, H1, H4, Intent, Text } from "@blueprintjs/core";
import PromptTypeDropdown from "./PromptTypeDropdown";

const columnStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

interface Props {
  handleGetBriefedClick: (topic: string) => void;
  setPromptStateInstance: [string, React.Dispatch<React.SetStateAction<string>>];
  useInstantBriefInstance: UseInstantBrief;
}

const LandingForm = ({ handleGetBriefedClick, setPromptStateInstance, useInstantBriefInstance }: Props) => {
  const { loading, usePromptTypeStateInstance } = useInstantBriefInstance;
  const [prompt, setPrompt] = setPromptStateInstance;
  const [promptType] = usePromptTypeStateInstance;
  const { xs, sm } = useWidth();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleGetBriefedClick(prompt);
  };

  return (
    <form onSubmit={handleSubmit} style={columnStyles}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "0.25rem",
          height: "100%",
          paddingBottom: "1rem",
        }}
      >
        <img
          alt="ittybrief icon"
          style={{
            height: xs || sm ? "2.25rem" : "3rem",
            width: "auto",
            objectFit: "contain",
            marginRight: 10,
          }}
          src={require("@/assets/icon-tight.png")}
        />
        <H1 style={{ color: Colors.RED3, margin: 0, fontSize: xs || sm ? "2.5rem" : "4rem" }}>Instant Brief</H1>
      </div>
      <Text style={{ padding: "0 5%", textAlign: "center", fontSize: "1rem", marginBottom: "1rem" }}>
        Get an <u>instant news brief</u> on any topic. {!xs && <br />}
        Provide a topic and get briefed!
      </Text>
      <div style={{ width: "100%" }}>
        <Input
          rightElement={<PromptTypeDropdown useInstantBriefInstance={useInstantBriefInstance} />}
          style={{ borderRadius: 1000, width: "100%" }}
          maxLength={50}
          onChange={(e) => setPrompt(e.target.value)}
          large
          leftIcon="search"
          value={prompt}
          autoFocus
        />
      </div>
      <div style={{ marginTop: 10, display: "flex", alignItems: "center", width: "100%", flexDirection: "column" }}>
        {loading && <LoadingBar style={{ width: "100%" }} seconds={promptType === PromptType.BROAD ? 9 : 6} intent={Intent.DANGER} />}
        <Button
          loading={loading}
          style={{ width: "10rem", borderRadius: 1000, display: loading ? "none" : "block", textAlign: "center" }}
          type="submit"
          large
          intent={Intent.DANGER}
        >
          Get briefed!
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          padding: "3rem 0 1rem",
          width: "100%",
        }}
      >
        <Divider style={{ width: "20%" }} />
        <H4 style={{ textAlign: "center", paddingTop: "2%" }}>Popular topics</H4>
        <Divider style={{ width: "20%" }} />
      </div>
      <SuggestionChips intent={Intent.DANGER} onClick={handleGetBriefedClick} count={xs ? 6 : 10} />
      {/* <GoogleAd type={GoogleAdType.WIDE} style={{ marginTop: "3rem", width: "100%" }} /> */}
    </form>
  );
};

export default LandingForm;
