import { useState } from "react";
import { FullContainer, InnerContainer, LogoutNavbar } from "@/components";
import { SetDistributionTimeView } from "@/views";
import { Button, H3, Intent, Text } from "@blueprintjs/core";
import { updateIsOnboarded } from "./updateIsOnboarded";
import { useNavigate } from "react-router-dom";
import { toaster } from "@/utils";
import { useDispatch } from "react-redux";
import { UPDATE_USER } from "@/store/types";
import { UilCheckCircle } from "@iconscout/react-unicons";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "35rem",
    padding: "3rem 0",
  },
};

const OnboardingChooseTime = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinishOnboarding = async () => {
    setLoading(true);
    try {
      await updateIsOnboarded();
      dispatch({ type: UPDATE_USER, payload: { isOnboarded: true } });
      toaster.show({
        message: "Congrats, you're all set! Check your inbox for your first brief!",
        intent: Intent.SUCCESS,
      });
      navigate("/my/home");
    } catch (e) {
      console.log(e);
      toaster.show({
        message: e?.message || "Error updating onboarding status. Please contact jack@ittybrief.com",
        intent: Intent.DANGER,
      });
    }
    setLoading(false);
  };

  return (
    <FullContainer>
      <LogoutNavbar title="Onboarding" />
      <InnerContainer style={{ alignItems: "center" }}>
        <div style={styles.container}>
          <H3>Choose your delivery time</H3>
          <Text style={{ marginBottom: 30 }}>
            Each day, your brief will arive in your inbox at the following time. You can change it to whatever you'd like! The news in your
            brief will be from within 24 hours prior to delivery.
          </Text>
          <SetDistributionTimeView />
          <div style={{ display: "flex", justifyContent: "center", marginTop: 75 }}>
            <Button loading={loading} onClick={onFinishOnboarding} icon={<UilCheckCircle size={18} />} intent={Intent.PRIMARY}>
              All done
            </Button>
          </div>
        </div>
      </InnerContainer>
    </FullContainer>
  );
};

export default OnboardingChooseTime;
