import { clientAxiosInstance } from "@/services";
import { toaster } from "@/utils";
import { Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";

export interface UseUserSignupMetrics {
  loading: boolean;
  userSignups: Record<string, number> | undefined;
  reload: () => Promise<void>;
}

export const useUserSignupMetrics = (): UseUserSignupMetrics => {
  const [loading, setLoading] = useState<boolean>(true);
  const [userSignups, setUserSignups] = useState<Record<string, number>>();

  const getUserSignups = async () => {
    setLoading(true);
    try {
      const response = await clientAxiosInstance.get<Record<string, number>>("/admin/metrics/users/signups");
      setUserSignups(response.data);
    } catch (e) {
      toaster.show({ intent: Intent.DANGER, message: e.message });
    }
    setLoading(false);
  };

  useEffect(() => {
    getUserSignups();
  }, []);

  return {
    loading,
    userSignups,
    reload: getUserSignups,
  };
};
