import { Footer, FullContainer, Navbar } from "@/components";
import StepPhotos from "./StepPhotos";
import JoinThousands from "./JoinThousands";

const AboutPage = () => {
  return (
    <FullContainer>
      <Navbar />
      <div style={{ minHeight: "100vh" }}>
        <StepPhotos />
        <JoinThousands />
        <Footer />
      </div>
    </FullContainer>
  );
};

export default AboutPage;
