import { FullContainer, InnerContainer, LogoutNavbar } from "@/components";
import { Button, Card, H3, Intent, Text } from "@blueprintjs/core";
import { UilArrowCircleRight } from "@iconscout/react-unicons";
import React from "react";
import { Link } from "react-router-dom";

const styles: Record<string, React.CSSProperties> = {
  card: {
    padding: 20,
    maxWidth: "18rem",
    display: "flex",
    flexDirection: "column",
  },
};

const OnboardingGreeting = () => {
  return (
    <FullContainer>
      <LogoutNavbar title="Onboarding" />
      <InnerContainer
        style={{
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <Card style={styles.card}>
          <H3 style={{ whiteSpace: "nowrap" }}>Let's get started</H3>
          <Text>We'll add the first section to your brief and you'll receive your first brief!</Text>
          <Link style={{ width: "100%" }} to="/my/onboarding/add-section">
            <Button fill intent={Intent.PRIMARY} style={{ marginTop: 10 }} rightIcon={<UilArrowCircleRight size={18} />}>
              Next
            </Button>
          </Link>
        </Card>
      </InnerContainer>
    </FullContainer>
  );
};

export default OnboardingGreeting;
