import { FullContainer, InnerContainer, Navbar } from "@/components";
import { useSearchParamsState } from "@/hooks";
import { SearchParamKey } from "@/types";
import { useWidth } from "@/utils";
import { Divider, H2, Icon, Tab, Tabs, Text } from "@blueprintjs/core";
import { MarketNewsPage } from "./MarketNewsPage";
import { ReactNode, useEffect } from "react";
import { SectionsNewsPage } from "./SectionsNewsPage";
import { UilNewspaper } from "@iconscout/react-unicons";
import { PopularNewsPage } from "./PopularNewsPage";

enum NewsPageKey {
  INTERESTS = "interests",
  MARKET = "market",
  POPULAR = "popular",
}

interface NewsPageMetadata {
  tabLabel: ReactNode;
  title: string;
}

const NewsPageMapping: Record<NewsPageKey, NewsPageMetadata> = {
  [NewsPageKey.INTERESTS]: {
    tabLabel: (
      <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
        <UilNewspaper size={18} />
        <Text>Feed</Text>
      </div>
    ),
    title: "News Feed",
  },
  [NewsPageKey.MARKET]: {
    tabLabel: (
      <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
        <Icon icon="timeline-line-chart" />
        <Text>Market</Text>
      </div>
    ),
    title: "Market News",
  },
  [NewsPageKey.POPULAR]: {
    tabLabel: (
      <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
        <Icon icon="star" />
        <Text>Popular</Text>
      </div>
    ),
    title: "Popular News",
  },
};

const NewsPage = () => {
  const [newsPage, setNewsPage] = useSearchParamsState(SearchParamKey.NEWS_PAGE, NewsPageKey.INTERESTS);
  const { sm } = useWidth();

  useEffect(() => {
    if (!newsPage) {
      setNewsPage(NewsPageKey.INTERESTS);
    }
  }, [newsPage]);

  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ paddingTop: sm ? "2.5rem" : "3rem", alignItems: "center" }}>
        <H2 style={{ marginBottom: 10 }}>{NewsPageMapping[newsPage]?.title}</H2>
        <Text style={{ fontSize: "0.75rem" }}>
          <i>Updated every 5 minutes</i>
        </Text>
        <div style={{ width: "100%", height: "3.5rem", display: "flex", alignItems: "flex-end" }}>
          <Tabs
            animate={false}
            large
            id="news-page-tabs"
            onChange={(newTabId) => setNewsPage(newTabId as NewsPageKey)}
            selectedTabId={newsPage}
          >
            {Object.values(NewsPageKey).map((id) => (
              <Tab key={id} id={id} title={NewsPageMapping[id].tabLabel} />
            ))}
          </Tabs>
        </div>
        <Divider style={{ width: "100%", marginTop: 0 }} />
      </InnerContainer>

      {newsPage === NewsPageKey.INTERESTS && <SectionsNewsPage />}
      {newsPage === NewsPageKey.MARKET && <MarketNewsPage />}
      {newsPage === NewsPageKey.POPULAR && <PopularNewsPage />}
    </FullContainer>
  );
};

export default NewsPage;
