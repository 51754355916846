import { RootState } from "@/store/reducers";
import { ReactNode } from "react";
import ConfirmEmail from "./ConfirmEmail";

interface Props {
  user: RootState["user"];
  children: ReactNode;
}

const ConfirmEmailProxy = ({ children, user }: Props) => {
  if (user.emailConfirmed) return <>{children}</>;
  return <ConfirmEmail user={user} />;
};

export default ConfirmEmailProxy;
