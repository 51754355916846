import React from "react";

import { InnerContainer, FullContainer, Navbar, Footer } from "@/components";
import { Button, Colors, H1, H2, H4, Icon, Text, UL } from "@blueprintjs/core";
import { useWidth } from "@/utils";
import { Link } from "react-router-dom";
import { ArticleSource } from "@/types";
import { ArticleSourceIcon } from "@/components";
import ScrollingArticles from "./ScrollingArticles";

const styles: Record<string, React.CSSProperties> = {
  outer: {
    minHeight: "100vh",
  },
  headerContainer: {
    background: Colors.BLUE3,
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: Colors.LIGHT_GRAY5,
    width: "100%",
    textAlign: "center",
  },
  headerImg: {
    width: "40%",
    boxShadow: `0 0 5px 0 ${Colors.BLUE5}`,
    height: "100%",
  },
  column: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    width: "100%",
    maxWidth: "35rem",
    display: "flex",
    flexDirection: "column",
    gap: 10,
    textAlign: "justify",
  },
};

const FeaturesPage = () => {
  const { sm } = useWidth();

  return (
    <FullContainer>
      <Navbar />
      <div style={styles.outer}>
        <div
          style={{
            ...styles.headerContainer,
            padding: sm ? "3rem 0" : "5rem 0",
          }}
        >
          <InnerContainer>
            <div
              style={{
                ...styles.header,
              }}
            >
              <ScrollingArticles />
              <H1 style={{ color: "inherit", marginTop: 40 }}>For all your curiosities</H1>
              <H4 style={{ color: Colors.LIGHT_GRAY4, fontWeight: 300 }}>ittybrief keeps you up to date</H4>
              <Link style={{ marginTop: 20 }} to="/start">
                <Button large>Start with a section</Button>
              </Link>
            </div>
          </InnerContainer>
        </div>
        <InnerContainer>
          <div
            style={{
              ...styles.contentContainer,
              margin: sm ? "25px auto 50px" : "75px auto 125px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              <Icon size={24} icon="shapes" />
              <H2 style={{ margin: 0 }}>Features</H2>
            </div>
            <Text>
              ittybrief is a beautiful curated daily newsletter centered around your interests. You can customize your brief to have
              multiple sections that are populated each day with relevant news articles and podcasts related to a given topic.
            </Text>
            <Text>
              We currently are focused on <ArticleSourceIcon articleSource={ArticleSource.NEWS_API} /> text-based news and{" "}
              <ArticleSourceIcon articleSource={ArticleSource.APPLE_PODCASTS} /> audio podcasts but will soon add support for videos,
              social, market data, and more.
            </Text>
            <Text>
              Along with curated news, ittybrief adds an AI-generated image inspired by your interest. This is distinct to your brief and
              will help you quickly identify each section.
            </Text>
            <Text>
              You can configure your brief to arrive at any time of day. We will populate news and media from a 24-hour window prior to the
              delivery time. You'll always have fresh and interesting news to catch up on!
            </Text>
            <Text>
              And the best part is... ittybrief is completely free :). Note that we'll soon be adding ads that are related to your
              interests!
            </Text>
            <H2 style={{ marginTop: 30 }}>Coming soon...</H2>
            <Text style={{ marginTop: -10 }}>
              A sneak peek of what to expect soon! If you have any brilliant ideas, let us know by filling out
              <Link to="/my/feedback" style={{ color: Colors.BLUE3 }}>
                {" "}
                our feedback form
              </Link>
              !
            </Text>
            <UL>
              <li>Stock market ticker updates</li>
              <li>Multiple ittybrief email templates</li>
              <li>View and search past briefs</li>
            </UL>
          </div>
        </InnerContainer>
      </div>
      <Footer />
    </FullContainer>
  );
};

export default FeaturesPage;
