import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import {
  LoginPage,
  NotFound,
  FaqPage,
  PrivacyPolicy,
  TermsOfUse,
  AddInitialSectionPage,
  Homepage3,
  ResearchPage,
  AboutPage,
  LinkSentLoginPage,
  // CuratedHomepage,
  // ReadCuratedPage,
} from "@/scenes";

import MyRouter from "./my";
import AdminRouter from "./admin";

import { VerifyEmailPage } from "./redirects";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { tryToGetUser } from "./getUser";
import { useSelector } from "react-redux";
import { RootState } from "@/store/reducers";
import ListsRouter from "./lists";

const Router = () => {
  const [checkedForUser, setCheckedForUser] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const { pathname, search, hash } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      if (typeof window !== "undefined") {
        window.gtag("event", "page_view", {
          page_path: pathname,
          page_search: search,
          page_hash: hash,
          send_to: "G-NZFGMY4JP3",
        });
      }
    } catch (e) {
      console.log("Failed to log gtag.", e);
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  useEffect(() => {
    if (!user && !checkedForUser) {
      tryToGetUser(dispatch, () => {});
      setCheckedForUser(true);
    }
    //checks if you're in app mode
    if ("standalone" in window.navigator && window.navigator.standalone) {
      navigate("/my/brief");
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Homepage3 />} />
      <Route path="/research" element={<Navigate to="/instant" replace />} />
      <Route path="/instant" element={<ResearchPage />} />
      <Route path="/about" element={<AboutPage />} />
      {/* <Route path="/curated" element={<CuratedHomepage />} /> */}
      {/* <Route path="/curated/read" element={<ReadCuratedPage />} /> */}
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfUse />} />
      {/* <Route path="/features" element={<FeaturesPage />} /> */}
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/login-confirmed" element={<LinkSentLoginPage />} />
      {/* <Route path="/forgot-password" element={<ForgotPassword />} /> */}
      <Route path="/start" element={<AddInitialSectionPage />} />
      <Route path="/verify-email/:jwt" element={<VerifyEmailPage />} />
      <Route path="/my/*" element={<MyRouter />} />
      <Route path="/admin/*" element={<AdminRouter />} />
      <Route path="/lists/*" element={<ListsRouter />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
