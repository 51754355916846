import React, { useMemo } from "react";

import { Card, Colors, H5, Intent, Text } from "@blueprintjs/core";
import { Section } from "@/types";

import { Draggable, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { isColorTooLight, toaster } from "@/utils";
import SectionColorPicker from "./SectionColorPicker";
import { UilBars, UilMinusCircle } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { UseSections } from "@/hooks/data";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    gap: 20,
    padding: 20,
    width: "100%",
    position: "relative",
    paddingRight: "4rem",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "2rem",
  },
  row: {
    display: "flex",
    gap: 10,
  },
  headerImg: {
    width: "1.25rem",
    height: "1.25rem",
    borderRadius: "50%",
    flexShrink: 0,
    marginTop: 2,
    border: `1px solid ${Colors.LIGHT_GRAY3}`,
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    gap: 3,
    fontSize: 11,
    color: Colors.RED3,
    verticalAlign: "center",
    cursor: "pointer",
    width: "fit-content",
  },
  reorderBtn: {
    color: Colors.DARK_GRAY5,
    cursor: "grab",
  },
  dragBtn: {
    width: "4rem",
    height: "100%",
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "default",
  },
};

interface Props {
  section: Section;
  index: number;
  useSectionsInstance: UseSections;
}

const DraggableJSX: any = Draggable;

const SectionRow = ({ section, index, useSectionsInstance }: Props) => {
  const [tempColor, setTempColor] = React.useState<string>();

  const { deleteSection, updateSectionColor } = useSectionsInstance;

  const onDelete = async () => {
    try {
      await deleteSection(section.id);
    } catch (e) {
      console.log(e?.message || "Unknown error deleting section.");
      toaster.show({
        message: e?.message || "Error deleting section.",
        intent: Intent.DANGER,
      });
    }
  };

  const onChangeColor = async (color: string) => {
    try {
      await updateSectionColor({ sectionId: section.id, color });
      setTempColor(undefined);
    } catch (e) {
      console.log(e?.message || "Unknown error updating section.");
      toaster.show({
        message: e?.message || "Error updating section color.",
        intent: Intent.DANGER,
      });
    }
  };

  const displayColor = useMemo(() => "#" + (tempColor || section.hexColor), [tempColor, section.hexColor]);

  return (
    <>
      <DraggableJSX draggableId={section.id} index={index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <Card
              style={{
                ...styles.container,
                boxShadow: `0 0 0 1px ${isColorTooLight(displayColor, 0.8) ? Colors.GRAY1 : displayColor}20`,
              }}
            >
              <div style={styles.row}>
                <SectionColorPicker onTempColorChange={(color) => setTempColor(color)} onChangeColor={onChangeColor} color={displayColor}>
                  <div
                    style={{
                      ...styles.headerImg,
                      background: `${displayColor}`,
                    }}
                  />
                </SectionColorPicker>
                <div style={styles.column}>
                  <Link
                    to={{
                      pathname: "/my/news",
                      search: `?news-page=interests&section-news-tab=${section.id}`,
                    }}
                  >
                    <H5>{section.originalPrompt}</H5>
                  </Link>

                  <div onClick={onDelete} className="brightness-hover" style={styles.removeBtn}>
                    <UilMinusCircle size={12} />
                    <Text>Remove section</Text>
                  </div>
                </div>
              </div>
              <div style={styles.dragBtn} {...provided.dragHandleProps}>
                <UilBars size={22} style={styles.reorderBtn} />
              </div>
            </Card>
          </div>
        )}
      </DraggableJSX>
    </>
  );
};

export default SectionRow;
