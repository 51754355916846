import { useWidth } from "@/utils";
import { Colors, H5, Icon } from "@blueprintjs/core";
import { UilLightbulbAlt } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";

const containerStyle: React.CSSProperties = {
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: "0.75rem",
  background: Colors.RED3,
  gap: "0.5rem",
  color: "white",
};

const CuratedBanner = () => {
  const { xs } = useWidth();

  return (
    <div style={containerStyle}>
      <Icon icon="issue" size={18} />
      <H5 style={{ margin: 0, textAlign: "center", color: "white", fontSize: xs ? "inherit" : "" }}>
        Can't decide?{" "}
        <span style={{ fontWeight: 400 }}>
          Browse{" "}
          <Link style={{ textDecoration: "underline" }} to="/curated">
            our curated briefs
          </Link>
          !
        </span>
      </H5>
      <UilLightbulbAlt size={18} />
    </div>
  );
};

export default CuratedBanner;
