import { clientAxiosInstance } from "@/services";
import { InitialSection } from "@/types";

export interface useSaveInitialSection {
  saveInitialSection: (input: { originalPrompt: string; enhancedPrompt: string }) => Promise<InitialSection>;
}

export const useSaveInitialSection = (): useSaveInitialSection => {
  const saveInitialSection = async (input: { originalPrompt: string; enhancedPrompt: string }): Promise<InitialSection> => {
    const saveSectionResponse = await clientAxiosInstance.post<InitialSection>(`/initial-sections`, input);
    return saveSectionResponse.data;
  };
  return { saveInitialSection };
};
