import React from "react";

import { H1, H3, Text, UL } from "@blueprintjs/core";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};

const EmailLost = () => {
  return (
    <div style={styles.container}>
      <H1>I am not receiving my daily brief in my inbox? What do I do?</H1>
      <div style={{ marginBottom: 20 }} />
      <Text>
        Don't worry if you haven't received your first email after subscribing to ittybrief or if the emails have suddenly stopped. Here are
        some solutions to help you receive your brief again.
      </Text>
      <div style={{ marginBottom: 20 }} />
      <H3>Troubleshoot your email settings</H3>
      <UL>
        <li>If you use Gmail, check if the newsletter is in your Promotions tab and move it to the Primary inbox if necessary.</li>
        <li>Verify that your email address on file is accurate.</li>
        <li>
          Look in your spam folder to see if the emails have been mistakenly sent there. If so, mark them as "not spam" in your email
          client.
        </li>
        <li>Add our email address to your contacts list. The emails will be sent from this address: no-reply@ittybrief.com.</li>
        <li>
          Send an email to no-reply@ittybrief.com. This can help your intermediate email filters recognize and allow incoming messages.
        </li>
        <li>
          If you have a corporate or government email account, contact your IT department and request that they whitelist the publication's
          Substack email address. This is because these types of email accounts may have email filters that are beyond your control and the
          IT department can quickly resolve the issue.
        </li>
      </UL>
      <div style={{ marginBottom: 10 }} />
      <Text>If you're still not receiving any Substack emails, contact our Support team.</Text>
    </div>
  );
};

export default EmailLost;
