export enum SearchParamKey {
  NEWS_PAGE = "news-page",
  NEWS_PAGE_SORT = "news-page-sort",
  SEARCH_STOCK = "search-stock",
  MARKET_NEWS_TAB = "market-news-tab",
  SECTION_NEWS_TAB = "section-news-tab",
  INITIAL_PROMPT = "initial-prompt",
  INITIAL_USER_EMAIL = "initial-user-email",
  BRIEF_SEND_ID = "brief-send-id",
  LOGIN_REDIRECT = "login-redirect",
  NEWSLETTER_ID = "newsletter-id",
}
