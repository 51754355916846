import { toaster } from "@/utils";
import { H5, Intent } from "@blueprintjs/core";
import { UilPlusCircle } from "@iconscout/react-unicons";
import React, { useCallback } from "react";

import { Accept, FileRejection, useDropzone } from "react-dropzone";

const styles: Record<string, React.CSSProperties> = {
  container: {
    width: "100%",
    border: "2px dashed rgba(17, 20, 24, 0.15)",
    padding: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
};

interface Props {
  onUpload: (files: Array<File>) => void;
  style?: React.CSSProperties;
  accept: Accept;
  multiple?: boolean;
}

const FileInput = ({ onUpload, style, accept, multiple = false }: Props) => {
  const onDropAccepted = useCallback(
    async (acceptedFiles: Array<File>) => {
      onUpload(acceptedFiles);
    },
    [onUpload]
  );
  const onDropRejected = useCallback((fileRejections: Array<FileRejection>) => {
    fileRejections.forEach((fileRejection) =>
      toaster.show({
        message: fileRejection.errors.map((e) => e.message).join(""),
        intent: Intent.DANGER,
      })
    );
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    multiple,
    onDropAccepted,
    onDropRejected,
    accept,
  });

  return (
    <div style={{ ...styles.container, ...style }} {...getRootProps()}>
      <input {...getInputProps()} />
      <div style={{ display: "flex", justifyContent: "center", gap: 10, padding: "1rem 0" }}>
        <UilPlusCircle size={18} />
        <H5 style={{ margin: 0 }}>Drag / drop files</H5>
      </div>
    </div>
  );
};

export default FileInput;
