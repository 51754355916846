import { clientAxiosInstance } from "@/services";
import { Article, CachedData, LocalStorageKey } from "@/types";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../useLocalStorage";
import moment from "moment";

export interface UsePopularArticles {
  loading: boolean;
  error: string | undefined;
  articles: Array<Article>;
}

export const usePopularArticles = (): UsePopularArticles => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const { getValue: getCachedNews, setValue: setCachedNews } = useLocalStorage<CachedData<Array<Article>>>();
  const [articles, setArticles] = useState<Array<Article>>([]);

  const getPopularArticles = async () => {
    setLoading(true);
    try {
      const cachedNews = getCachedNews(LocalStorageKey.POPULAR_NEWS);
      if (cachedNews && moment().unix() < cachedNews.exp) {
        setArticles(cachedNews.data);
      } else {
        const response = await clientAxiosInstance.get<Array<Article>>("/articles/popular");
        setArticles(response.data);
        setCachedNews(LocalStorageKey.POPULAR_NEWS, {
          exp: moment().add({ minutes: 5 }).unix(),
          data: response.data,
        });
      }
    } catch (e) {
      setError(e?.response?.data?.message || "Error loading articles.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPopularArticles();
  }, []);

  return {
    loading,
    error,
    articles,
  };
};
