import { InnerContainer, Input, SuggestionChips, TextCycler } from "@/components";
import { SearchParamKey } from "@/types";
import { useWidth } from "@/utils";
import { Button, Colors, H1, Intent, Text } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: 8,
  },
};

const StencilHeadliner = () => {
  const [prompt, setPrompt] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [isPromptConfirmed, setIsPromptConfirmed] = useState<boolean>(false);

  useEffect(() => {
    setIsPromptConfirmed(false);
  }, [prompt]);

  const { xs, sm, lg } = useWidth();
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (!isPromptConfirmed) {
      setIsPromptConfirmed(true);
      setTimeout(() => document.getElementById("stencil-email")?.focus(), 10);
      return;
    }
    navigate(`/start/${prompt ? `?${SearchParamKey.INITIAL_PROMPT}=${prompt}&${SearchParamKey.INITIAL_USER_EMAIL}=${email}` : ""}`);
  };

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <div
      style={{
        ...styles.container,
        height: lg ? "75vh" : "auto",
        gap: "3rem",
      }}
    >
      <InnerContainer
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: lg ? "flex-start" : "center",
          height: "100%",
          gap: "3rem",
        }}
      >
        <div
          style={{
            height: "100%",
            flex: 2,
            padding: sm ? "3rem 0" : "6rem 0",
            width: xs ? "100%" : sm ? "90vw" : "40rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <form
            onSubmit={onSubmit}
            style={{
              ...styles.column,
              textAlign: "left",
              padding: sm ? "8rem 1rem 0" : "1.25rem 1rem 1rem",
            }}
            className="fade-in"
          >
            <H1
              style={{
                fontSize: xs ? "2.3rem" : sm ? "3.25rem" : "3.5rem",
                lineHeight: xs ? "3rem" : sm ? "4rem" : "4.25rem",
                width: "100%",
                whiteSpace: "nowrap",
              }}
            >
              Be the one
              <span style={{ color: Colors.BLUE3 }}>
                <TextCycler
                  texts={["ready at work", "up to date", "at the forefront", "people look to", "always learning"]}
                  interval={3000}
                />
              </span>
            </H1>
            <Text
              style={{
                fontSize: "1.15rem",
                marginBottom: "1.5rem",
                marginTop: "0.75rem",
                maxWidth: "30rem",
              }}
            >
              Every day, ittybrief delivers the latest news on the topics <span style={{ fontWeight: 600 }}>YOU</span> care about.
            </Text>
            <div
              style={{
                display: "flex",
                flexDirection: xs ? "column" : "row",
                width: "100%",
                maxWidth: "30rem",
                gap: "1rem",
                marginBottom: "1rem",
                alignItems: "flex-end",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: "0.5rem" }}>
                <Input
                  fill
                  style={{ textAlign: "center", borderRadius: 1000, width: "100%" }}
                  onChange={(e) => setPrompt(e.target.value)}
                  large
                  placeholder="Describe one of your interests..."
                  value={prompt}
                  type="text"
                  required
                />
                {isPromptConfirmed && (
                  <Input
                    id="stencil-email"
                    fill
                    style={{ textAlign: "center", borderRadius: 1000, width: "100%" }}
                    onChange={(e) => setEmail(e.target.value)}
                    large
                    placeholder="Enter your email"
                    value={email}
                    type="email"
                    required
                    className="fade-in"
                  />
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "center", whiteSpace: "nowrap" }}>
                <Button style={{ borderRadius: 1000 }} type="submit" large intent={Intent.PRIMARY}>
                  {isPromptConfirmed ? "Get Briefed!" : "Next"}
                </Button>
              </div>
            </div>
            {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "1rem", padding: "3rem 0 1rem" }}>
            <Divider style={{ width: "20%" }} />
            <H4 style={{ textAlign: "center", paddingTop: "2%" }}>Some popular interests</H4>
            <Divider style={{ width: "20%" }} />
          </div> */}
            {!isPromptConfirmed && (
              <SuggestionChips
                style={{ justifyContent: xs ? "center" : "flex-start" }}
                onClick={(topic: string) => {
                  setPrompt(topic);
                  setTimeout(() => handleSubmit(), 0);
                }}
                count={6}
              />
            )}
          </form>
          <div
            style={{
              marginTop: "8rem",
              backgroundColor: "white",
              padding: xs ? "1rem" : "1rem 2rem",
              display: "flex",
              alignItems: "center",
              gap: "1.25rem",
              flexWrap: "wrap",
              justifyContent: xs ? "center" : "flex-start",
            }}
            className="fade-in"
          >
            <Link to="/instant">
              <Text>Instant Brief</Text>
            </Link>
            <Link to="/about">
              <Text>About</Text>
            </Link>
            <Link to="/start">
              <Text>Get Started</Text>
            </Link>
            <Link to="/login">
              <Text>Login</Text>
            </Link>
          </div>
        </div>
        {lg && (
          <img
            className="fade-in"
            style={{ height: "100%", padding: "1rem 0", maxWidth: "80vw" }}
            src="https://ittybrief.blob.core.windows.net/static/collage2.png"
          />
        )}
      </InnerContainer>
      {!lg && (
        <img
          className="fade-in"
          style={{ padding: "1rem", width: "60vw", maxWidth: "20rem", position: "absolute", top: 0, right: 0 }}
          src="https://ittybrief.blob.core.windows.net/static/collage-xs.png"
        />
      )}
    </div>
  );
};

export default StencilHeadliner;
