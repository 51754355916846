import { Colors, Text } from "@blueprintjs/core";
import { useStock } from "@/hooks/data";
import { IDailyOpenClose, Ticker } from "@/types";
import { useEffect, useState } from "react";
import { convertToAbbreviatedNumber, convertToDecimalWithCommas, useWidth } from "@/utils";
import StockAggregateData from "./StockAggregateData";
import moment, { Moment } from "moment";
import Skeleton from "react-loading-skeleton";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    background: Colors.LIGHT_GRAY4,
    padding: "1.5rem",
    gap: 20,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 10,
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
};

interface Props {
  ticker: Ticker;
}

const DisplayRow = ({ header, value }: { header: string; value: string | number | JSX.Element }) => {
  return (
    <div style={styles.row}>
      <Text>{header}</Text>
      <Text style={{ fontWeight: 600 }}>{value}</Text>
    </div>
  );
};

const ValueLoadingSkeleton = () => (
  <Skeleton width={50} baseColor={Colors.GREEN4} highlightColor={Colors.GREEN5} style={{ opacity: 0.5 }} />
);

const StockOpenClose = ({ ticker }: Props) => {
  const { xs } = useWidth();
  const [loading, setLoading] = useState<boolean>(true);
  const [dailyData, setDailyData] = useState<IDailyOpenClose>();
  const [previousDayData, setPreviousDayData] = useState<IDailyOpenClose>();
  const [today, setToday] = useState<Moment>();
  const { getStockDailyOpenClose } = useStock();

  const loadStockDailyOpenClose = async () => {
    try {
      setLoading(true);
      const { current, previous } = await getStockDailyOpenClose(ticker.ticker);
      setDailyData(current.result);
      setToday(moment(current.date));
      setPreviousDayData(previous.result);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ticker) loadStockDailyOpenClose();
  }, [ticker]);

  if (!loading && !dailyData) return null;

  return (
    <>
      <StockAggregateData prevClose={previousDayData?.close} ticker={ticker} today={today} />
      <div style={{ ...styles.container, flexDirection: xs ? "column" : "row", marginTop: "1rem" }}>
        <div style={styles.column}>
          <DisplayRow header="High" value={dailyData?.high ? convertToDecimalWithCommas(dailyData?.high) : <ValueLoadingSkeleton />} />
          <DisplayRow header="Low" value={dailyData?.low ? convertToDecimalWithCommas(dailyData?.low) : <ValueLoadingSkeleton />} />
        </div>
        <div style={styles.column}>
          <DisplayRow header="Open" value={dailyData?.open ? convertToDecimalWithCommas(dailyData?.open) : <ValueLoadingSkeleton />} />
          <DisplayRow
            header="Prev Close"
            value={previousDayData?.close ? convertToDecimalWithCommas(previousDayData?.close, 2) : <ValueLoadingSkeleton />}
          />
        </div>
        <div style={styles.column}>
          <DisplayRow header="Vol" value={dailyData?.volume ? convertToDecimalWithCommas(dailyData?.volume) : <ValueLoadingSkeleton />} />
          <DisplayRow header="Market Cap" value={ticker?.marketCap ? convertToAbbreviatedNumber(ticker?.marketCap) : "N/A"} />
        </div>
      </div>
    </>
  );
};

export default StockOpenClose;
