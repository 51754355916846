import { Colors, Text } from "@blueprintjs/core";
import { useState } from "react";

interface Props {
  text: string;
  maxLength?: number;
  style?: React.CSSProperties;
}

const ShowMoreText = ({ text = "", maxLength = 250, style = {} }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const displayText = text.substring(0, isOpen ? undefined : maxLength);
  return (
    <Text style={style}>
      {displayText}
      {displayText.length < text.length ? "..." : ""}
      {text.length > maxLength && (
        <span onClick={() => setIsOpen(!isOpen)} style={{ fontWeight: 500, color: Colors.BLUE3, cursor: "pointer" }}>
          &nbsp;Show {isOpen ? "less" : "more"}
        </span>
      )}
    </Text>
  );
};
export default ShowMoreText;
