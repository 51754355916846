import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toaster } from "@/utils";

import { Button, Card, H4, Intent } from "@blueprintjs/core";
import { InnerContainer, FullContainer, Input, Navbar } from "@/components";

import { useSelector } from "react-redux";
import { RootState } from "@/store/reducers";

import { login } from "./login";
import { UilArrowRight, UilUserPlus } from "@iconscout/react-unicons";

const styles: Record<string, React.CSSProperties> = {
  centered: {
    width: "100%",
    maxWidth: "25rem",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
  },
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      if (!formData.email)
        return toaster.show({
          message: "All fields are required.",
          intent: Intent.DANGER,
        });
      setLoading(true);
      await login(formData);
      navigate("/login-confirmed");
    } catch (err) {}
    setLoading(false);
  };

  useEffect(() => {
    if (user) navigate("/my/home");
  }, []);

  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ paddingTop: "10rem" }}>
        <Link to="/start" style={{ ...styles.centered, marginBottom: 20 }}>
          <Button large intent={Intent.SUCCESS} icon={<UilUserPlus size={18} />} fill>
            New here?
          </Button>
        </Link>
        <form onSubmit={onSubmit}>
          <Card style={styles.centered}>
            <H4>Login</H4>
            <Input
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              placeholder="Email"
              type="email"
              required
              style={{ marginBottom: 5 }}
            />
            <div>
              <Button style={{ marginTop: 10 }} loading={loading} type="submit" rightIcon={<UilArrowRight size={18} />}>
                Get Login Link
              </Button>
            </div>
          </Card>
        </form>
      </InnerContainer>
    </FullContainer>
  );
};

export default Login;
