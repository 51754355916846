import { Article } from "./Article";

export enum TickerType {
  CS = "CS",
  PFD = "PFD",
  WARRANT = "WARRANT",
  RIGHT = "RIGHT",
  BOND = "BOND",
  ETF = "ETF",
  ETN = "ETN",
  ETV = "ETV",
  SP = "SP",
  ADRC = "ADRC",
  ADRP = "ADRP",
  ADRW = "ADRW",
  ADRR = "ADRR",
  FUND = "FUND",
  BASKET = "BASKET",
  UNIT = "UNIT",
  LT = "LT",
  OS = "OS",
  GDR = "GDR",
  OTHER = "OTHER",
  NYRS = "NYRS",
  AGEN = "AGEN",
  EQLK = "EQLK",
  ETS = "ETS",
}

export interface Ticker {
  ticker: string;
  name: string;
  locale: string;
  market: string;
  primaryExchange?: string;
  type?: TickerType;

  description?: string;
  homepageUrl?: string;
  percentageChange?: number;
  marketCap?: number;
  sicDescription?: string;
}

export interface TickerWithArticles extends Ticker {
  summary?: string;
  articles: Array<Article>;
}

export interface TickerTypeDetail {
  description: string;
  asset_class: string;
  locale: string;
}

export const TickerTypeMap: Record<TickerType, TickerTypeDetail> = {
  CS: {
    description: "Common Stock",
    asset_class: "stocks",
    locale: "us",
  },
  PFD: {
    description: "Preferred Stock",
    asset_class: "stocks",
    locale: "us",
  },
  WARRANT: {
    description: "Warrant",
    asset_class: "stocks",
    locale: "us",
  },
  RIGHT: {
    description: "Rights",
    asset_class: "stocks",
    locale: "us",
  },
  BOND: {
    description: "Corporate Bond",
    asset_class: "stocks",
    locale: "us",
  },
  ETF: {
    description: "Exchange Traded Fund",
    asset_class: "stocks",
    locale: "us",
  },
  ETN: {
    description: "Exchange Traded Note",
    asset_class: "stocks",
    locale: "us",
  },
  ETV: {
    description: "Exchange Traded Vehicle",
    asset_class: "stocks",
    locale: "us",
  },
  SP: {
    description: "Structured Product",
    asset_class: "stocks",
    locale: "us",
  },
  ADRC: {
    description: "American Depository Receipt Common",
    asset_class: "stocks",
    locale: "us",
  },
  ADRP: {
    description: "American Depository Receipt Preferred",
    asset_class: "stocks",
    locale: "us",
  },
  ADRW: {
    description: "American Depository Receipt Warrants",
    asset_class: "stocks",
    locale: "us",
  },
  ADRR: {
    description: "American Depository Receipt Rights",
    asset_class: "stocks",
    locale: "us",
  },
  FUND: {
    description: "Fund",
    asset_class: "stocks",
    locale: "us",
  },
  BASKET: {
    description: "Basket",
    asset_class: "stocks",
    locale: "us",
  },
  UNIT: {
    description: "Unit",
    asset_class: "stocks",
    locale: "us",
  },
  LT: {
    description: "Liquidating Trust",
    asset_class: "stocks",
    locale: "us",
  },
  OS: {
    description: "Ordinary Shares",
    asset_class: "stocks",
    locale: "us",
  },
  GDR: {
    description: "Global Depository Receipts",
    asset_class: "stocks",
    locale: "us",
  },
  OTHER: {
    description: "Other Security Type",
    asset_class: "stocks",
    locale: "us",
  },
  NYRS: {
    description: "New York Registry Shares",
    asset_class: "stocks",
    locale: "us",
  },
  AGEN: {
    description: "Agency Bond",
    asset_class: "stocks",
    locale: "us",
  },
  EQLK: {
    description: "Equity Linked Bond",
    asset_class: "stocks",
    locale: "us",
  },
  ETS: {
    description: "Single-security ETF",
    asset_class: "stocks",
    locale: "us",
  },
};
