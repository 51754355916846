export enum NewsletterItemType {
  ARTICLE = "article",
  HEADER = "header",
  AD = "ad",
}

export interface Newsletter {
  id: string;
  title: string;
  description: string;
  urlToImage: string | null;
  urlToTrimmedImage: string | null;
}

export enum NewsletterSendStatus {
  DRAFT = "draft",
  SENT = "sent",
}

export interface NewsletterSend {
  id: string;
  created: string;
  newsletterId: string;
  subject: string | null;
  status: NewsletterSendStatus;
  sentAt: string | null;

  newsletterTitle: string;
  newsletterUrlToImage: string | null;
}

export interface NewsletterItem {
  id: string;
  newsletterSendId: string;
  type: NewsletterItemType;
  topNewsletterItemId: string | null;
  itemId: string;
}

export interface HeaderInNewsletter {
  id: string;
  created: string;
  title: string;
  description: string | null;
}

export interface NewsletterSubscriber {
  id: string;
  created: string;
  newsletterId: string;
  email: string;
  isSubscribed: boolean;
}
