import { ActionProps, Text } from "@blueprintjs/core";
import { Breadcrumbs2 } from "@blueprintjs/popover2";
import { useNavigate } from "react-router-dom";

interface BreadcrumbItem extends ActionProps {
  href: string;
  isCurrent?: boolean;
}

interface Props {
  items: Array<BreadcrumbItem>;
  style?: React.CSSProperties;
}

const Breadcrumbs = ({ items, style }: Props) => {
  const navigate = useNavigate();
  return (
    <div style={style}>
      <Breadcrumbs2
        collapseFrom="end"
        items={items.map((i) => ({
          ...i,
          onClick: (e) => {
            e.preventDefault();
            if (!i.isCurrent) navigate(i.href);
          },
        }))}
        currentBreadcrumbRenderer={({ text }) => <Text style={{ fontSize: "1rem" }}>{text}</Text>}
      />
    </div>
  );
};

export default Breadcrumbs;
