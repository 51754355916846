import { HTMLTable } from "@blueprintjs/core";
import { UseList } from "./useList";
import { UilAngleRight } from "@iconscout/react-unicons";
import { ListUser } from "@/types";

interface Props {
  useListInstance: UseList;
  onSelectListUser: (listUser: ListUser) => void;
}

function ListUserTable({ useListInstance, onSelectListUser }: Props) {
  const { list } = useListInstance;

  return (
    <HTMLTable style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>#</th>
          <th>Email</th>
          <th>Name</th>
          <th>Recipient Attributes</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {list.users.map((listUser, i) => (
          <tr key={listUser.id} className="gray-hover" onClick={() => onSelectListUser(listUser)}>
            <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
            <td style={{ verticalAlign: "middle" }}>{listUser.email}</td>
            <td style={{ verticalAlign: "middle" }}>{listUser.name}</td>
            <td style={{ verticalAlign: "middle" }}>
              {listUser.userDescription.substring(0, 25) + (listUser.userDescription.length > 25 ? "..." : "")}
            </td>
            <td style={{ textAlign: "right", verticalAlign: "middle" }}>
              <UilAngleRight />
            </td>
          </tr>
        ))}
        {!Boolean(list.users.length) && (
          <tr>
            <td colSpan={5}>
              <i>No recipients found.</i>
            </td>
          </tr>
        )}
      </tbody>
    </HTMLTable>
  );
}

export default ListUserTable;
