import React from "react";

import { FullContainer, InnerContainer, Navbar } from "@/components";
import { Button, H3, Text, Intent, Divider, H5, Colors } from "@blueprintjs/core";
import { Link, useNavigate } from "react-router-dom";
import { SetDistributionTimeView, UserTickersView } from "@/views";
import { UilEnvelopeAlt, UilPlusCircle } from "@iconscout/react-unicons";
import SectionsView from "./SectionsView";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "35rem",
    padding: "3rem 0",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: 30,
  },
};

const SectionsPage = () => {
  const navigate = useNavigate();

  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ alignItems: "center", paddingBottom: "8rem" }}>
        <div className="fade-in" style={styles.container}>
          <H3>
            <UilEnvelopeAlt size={22} style={{ marginRight: 5, marginBottom: -3 }} />
            Brief
          </H3>
          <Text style={{ marginBottom: 40 }}>
            To ensure that your brief finds your inbox, check out{" "}
            <Link to="/faq?page=email-spam" style={{ color: Colors.BLUE3 }}>
              these tips.
            </Link>
          </Text>
          <SetDistributionTimeView />

          <div style={styles.headerContainer}>
            <H5 style={{ marginBottom: 2 }}>Market Data</H5>
            <Button onClick={() => navigate("/my/stocks")} intent={Intent.PRIMARY} small icon={<UilPlusCircle size={18} />}>
              Add
            </Button>
          </div>
          <Divider style={{ marginBottom: 10 }} />
          <Text style={{ marginBottom: 10 }}>
            For each ticker symbol, the price movement will be included in your daily brief. You can see ticker news in the{" "}
            <Link style={{ color: Colors.BLUE3 }} to="/my/news?news-page=market">
              news feed.
            </Link>
          </Text>
          <UserTickersView />
          <SectionsView />
        </div>
      </InnerContainer>
    </FullContainer>
  );
};

export default SectionsPage;
