import { GOOGLE_AD_ID, GoogleAdType, googleAdTypeToSlotMap } from "@/types";
import React, { useEffect } from "react";

interface Props {
  type: GoogleAdType;
  style?: React.CSSProperties;
  layout?: string;
  layoutKey?: string;
  format?: string;
  responsive?: string;
  pageLevelAds?: boolean;
  adTest?: boolean;
  children?: React.ReactNode;
}

export function GoogleAd({
  style = {},
  type,
  layout = "",
  layoutKey = "",
  format = "auto",
  responsive = "true",
  pageLevelAds = false,
  adTest = false,
  children,
  ...rest
}: Props) {
  useEffect(() => {
    const p: any = {};
    if (pageLevelAds) {
      p.google_ad_client = GOOGLE_AD_ID;
      p.enable_page_level_ads = true;
    }

    try {
      if (typeof window === "object") {
        ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push(p);
      }
    } catch {
      // Pass
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", ...style }}
      data-ad-client={GOOGLE_AD_ID}
      data-ad-slot={googleAdTypeToSlotMap[type]}
      data-ad-layout={layout}
      data-ad-layout-key={layoutKey}
      data-ad-format={format}
      data-full-width-responsive={responsive}
      data-adtest={adTest ? "on" : "off"}
      {...rest}
    >
      {children}
    </ins>
  );
}

export default GoogleAd;
