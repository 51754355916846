import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { setAuthToken, useWidth } from "@/utils";

import { Alignment, Navbar, Button, Divider, Colors } from "@blueprintjs/core";
import { InnerContainer } from "..";
import { useDispatch } from "react-redux";
import { RESET_USER } from "@/store/types";

import { UilSignOutAlt } from "@iconscout/react-unicons";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "sticky",
    top: 0,
    zIndex: 10000,
    background: "white",
  },
};

const LogoutNavbar = ({ title }: { title?: string }) => {
  const { xs, sm } = useWidth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch({ type: RESET_USER });
    setAuthToken();
    navigate("/login");
  };

  return (
    <div style={styles.container}>
      <InnerContainer>
        <Navbar
          style={{
            boxShadow: "none",
            padding: "0.5rem 0",
            height: xs || sm ? "auto" : 60,
          }}
        >
          <Navbar.Group align={Alignment.LEFT}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: Colors.DARK_GRAY1,
                height: "100%",
              }}
            >
              <Navbar.Heading
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.25rem",
                  height: "100%",
                }}
              >
                <img
                  alt="ittybrief icon"
                  style={{
                    height: "50%",
                    width: "auto",
                    objectFit: "contain",
                    marginRight: !xs ? 10 : 0,
                  }}
                  src={require("@/assets/icon-tight.png")}
                />
                {!xs && <span>ittybrief</span>}
              </Navbar.Heading>
            </Link>
            {title && (
              <>
                <Navbar.Divider />
                <Navbar.Heading style={{ marginLeft: 10 }}>{title}</Navbar.Heading>
              </>
            )}
          </Navbar.Group>
          <Navbar.Group style={{ gap: "0.25rem" }} align={Alignment.RIGHT}>
            <Button onClick={onLogout} className="bp4-minimal" icon={<UilSignOutAlt size={18} />} text="Logout" />
          </Navbar.Group>
        </Navbar>
      </InnerContainer>
      <Divider style={{ margin: 0, width: "100%" }} />
    </div>
  );
};

export default LogoutNavbar;
