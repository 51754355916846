import { Colors } from "@blueprintjs/core";
import React from "react";

interface Props {
  style?: React.CSSProperties;
  random: number;
}

const styles: Record<string, React.CSSProperties> = {
  article: {
    display: "flex",
    gap: 10,
    marginBottom: 10,
    background: "white",
  },
  articleImg: {
    width: "25%",
    height: "100%",
    background: Colors.LIGHT_GRAY1,
    borderRadius: 4,
  },
  fake: {
    background: Colors.LIGHT_GRAY1,
    width: "100%",
    borderRadius: 2,
  },
};

const SkeletonArticleRow = ({ style, random }: Props) => {
  return (
    <div style={{ ...styles.article, ...style }}>
      <div style={styles.articleImg} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 6,
        }}
      >
        <div style={{ ...styles.fake, height: random * 30 + 8 }} />
        <div style={{ ...styles.fake, width: "85%", height: random * 20 + 8 }} />
        <div
          style={{
            ...styles.fake,
            width: "80%",
            height: random * 10 + 8,
          }}
        />
      </div>
    </div>
  );
};

export default SkeletonArticleRow;
