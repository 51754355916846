import { clientAxiosInstance } from "@/services";
import { Brief } from "@/types";
import { useEffect, useState } from "react";

export interface UseBrief {
  brief: Brief | undefined;
  changeIsSubscribed: (subscribe: boolean) => Promise<void>;
}

export const useBrief = (): UseBrief => {
  const [brief, setBrief] = useState<Brief>();

  const loadBrief = async () => {
    const b = await clientAxiosInstance.get<Brief>("/brief");
    setBrief(b.data);
  };

  const changeIsSubscribed = async (subscribe: boolean): Promise<void> => {
    await clientAxiosInstance.put<void>(`/brief/${subscribe ? "subscribe" : "unsubscribe"}`);
    setBrief({ ...brief, isSubscribed: subscribe });
  };

  useEffect(() => {
    loadBrief();
  }, []);

  return {
    brief,
    changeIsSubscribed,
  };
};
