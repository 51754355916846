import { ArticleRow } from "@/components";
import { DisplaySectionWithArticles } from "@/types";
import { isColorTooLight } from "@/utils";
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";

interface Props {
  section: DisplaySectionWithArticles;
}

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

const Section = ({ section }: Props) => {
  return (
    <div style={styles.container} id={section.id}>
      <H5 style={{ textAlign: "center", maxWidth: "70%" }}>{section.originalPrompt}</H5>
      <div
        style={{
          height: 6,
          background: `#${section.hexColor}`,
          boxShadow: isColorTooLight(`#${section.hexColor}`) ? `0 0 0 1px ${Colors.GRAY1}` : "none",
          borderRadius: 10000,
          width: "70%",
          marginTop: "0.5rem",
          marginBottom: "2rem",
        }}
      />
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        {section.articles.map((article, i) => (
          <React.Fragment key={article.id}>
            <ArticleRow hideImage={i % 3 !== 0} orientation="horizontal" article={article} />
            {i !== section.articles.length - 1 && <Divider style={{ width: "100%", marginBottom: 5 }} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Section;
