import { FileInput } from "@/components";
import { CreateListUserPayload } from "@/types";
import { isEmail, parseCsv, toaster } from "@/utils";
import { Button, Colors, Divider, H3, HTMLTable, Intent, Text } from "@blueprintjs/core";
import { UilX } from "@iconscout/react-unicons";
import { useState } from "react";
import { UseList } from "./useList";

interface Props {
  useListInstance: UseList;
  handleClose: () => void;
}

function AddRecipientsForm({ useListInstance, handleClose }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string | undefined>();
  const [previewRows, setPreviewRows] = useState<CreateListUserPayload[]>([]);
  const [error, setError] = useState<string | undefined>();

  const { bulkAddUsersToList } = useListInstance;

  const reset = () => {
    setFileName(undefined);
    setPreviewRows([]);
    setError(undefined);
  };

  const onUpload = async (files: Array<File>) => {
    reset();
    try {
      if (files.length === 0) {
        return;
      }

      const file = files[0];

      const results = await parseCsv(file, { header: false });
      let rows = results.data;
      if (!rows.length) {
        setError("No data found.");
        return;
      }

      // If the first row is a header row, remove it
      if (!isEmail(rows?.[0]?.[1])) rows = rows.slice(1);
      const completeRows: CreateListUserPayload[] = rows.map((row) => ({
        name: row[0],
        email: row[1],
        userDescription: row[2],
      }));

      const validRows = completeRows.filter((row) => row.email && row.name && row.userDescription && isEmail(row.email));
      const invalidRows = completeRows.filter((row) => !row.email || !row.name || (!row.userDescription && !isEmail(row.email)));

      if (invalidRows.length) {
        setError(`${invalidRows.length} invalid rows. All rows must have a VALID email, name, and user description.`);
      }

      if (!validRows.length) {
        setError("No data found.");
        return;
      }

      setPreviewRows(validRows);

      if (validRows.length) setFileName(file.name);
    } catch (e) {
      setError("Error parsing CSV file. Ensure that the file has the correct format.");
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!previewRows.length) return;
    setLoading(true);
    try {
      await bulkAddUsersToList(previewRows);
      toaster.show({ message: "Recipients added to list.", intent: Intent.SUCCESS });
      reset();
      handleClose();
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={onSubmit} style={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
      <H3>Upload Recipients</H3>
      <Divider />
      <Text style={{ marginTop: "0.5rem" }}>
        Upload a CSV file with the following columns: email, name, user description.{" "}
        <a style={{ color: Colors.BLUE3, textDecoration: "underline" }} download="example.csv" href={require("@/assets/example.csv")}>
          example.csv
        </a>
      </Text>
      <Text style={{ margin: "0.5rem 0" }}>
        <i>NOTE: Recipients with the same email will be updated.</i>
      </Text>
      {!fileName && (
        <FileInput
          accept={{
            "text/csv": [".csv"],
          }}
          onUpload={onUpload}
        />
      )}
      {fileName && (
        <div>
          <Button onClick={reset} icon={<UilX size={18} />}>
            {fileName}
          </Button>
        </div>
      )}
      {error && <Text style={{ color: "red", marginTop: "0.5rem" }}>{error}</Text>}
      {Boolean(previewRows.length) && (
        <HTMLTable style={{ marginTop: "1rem" }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Recipient Attributes</th>
            </tr>
          </thead>
          <tbody>
            {previewRows.map((row, i) => (
              <tr key={i}>
                <td>{row.name}</td>
                <td>{row.email}</td>
                <td>{row.userDescription.substring(0, 10) + (row.userDescription.length > 10 ? "..." : "")}</td>
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      )}
      {Boolean(previewRows.length) && (
        <Button loading={loading} type="submit" intent={Intent.SUCCESS} style={{ marginTop: "1rem" }}>
          Add Recipients to List
        </Button>
      )}
    </form>
  );
}

export default AddRecipientsForm;
