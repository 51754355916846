import { SearchParamKey } from "@/types";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

type SearchParamsStateValue = string | undefined;
type SetSearchParamsStateValue = (nextValue: string) => void;
type UseSearchParamsState = [SearchParamsStateValue, SetSearchParamsStateValue];

export const useSearchParamsState = <T extends string>(key: SearchParamKey, value?: T): UseSearchParamsState => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onSetSearchParams: SetSearchParamsStateValue = (nextValue) => {
    searchParams.set(key, nextValue);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (value && !searchParams.get(key)) onSetSearchParams(value);
  }, [value]);

  return [searchParams.get(key), onSetSearchParams];
};
