import { clientAxiosInstance } from "@/services";
import { SendgridEmailMetric } from "@/types/Sendgrid";
import { toaster } from "@/utils";
import { Intent } from "@blueprintjs/core";
import moment from "moment";
import { useEffect, useState } from "react";

export const useEmailInteractionMetrics = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [emailInteractionMetrics, setEmailInteractionMetrics] = useState<Array<SendgridEmailMetric>>([]);

  const getEmailInteractionMetrics = async (): Promise<void> => {
    setLoading(true);
    try {
      const date = moment().subtract({ days: 6 }).format("YYYY-MM-DD");
      const response = await clientAxiosInstance.get<Array<SendgridEmailMetric>>(`/admin/metrics/email-interactions`, { params: { date } });
      setEmailInteractionMetrics(response.data);
    } catch (e) {
      toaster.show({
        message: e.message,
        intent: Intent.DANGER,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getEmailInteractionMetrics();
  }, []);

  return {
    emailInteractionMetrics: emailInteractionMetrics.sort((a, b) => moment(b.date).diff(moment(a.date))),
    loading,
  };
};
