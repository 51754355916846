import { Article, ArticleSource } from "@/types";
import { toLocalMoment, useWidth } from "@/utils";
import { H5, Text } from "@blueprintjs/core";
import React from "react";
import ArticleSourceIcon from "./ArticleSourceIcon";

interface Props {
  article: Article;
  orientation?: "horizontal" | "vertical";
  style?: React.CSSProperties;
  hideImage?: boolean;
}

const styles: Record<string, React.CSSProperties> = {
  article: {
    width: "100%",
    display: "flex",
    gap: 10,
    overflow: "hidden",
  },
  horizontalArticleImg: {
    width: "25%",
    objectFit: "cover",
  },
  verticalArticleImg: {
    width: "100%",
    objectFit: "cover",
    maxHeight: 200,
  },
};

const ArticleRow = ({ article, style, orientation = "horizontal", hideImage }: Props) => {
  const { xs } = useWidth();

  if (orientation === "vertical") {
    return (
      <div style={{ ...styles.article, flexDirection: "column", ...style }}>
        {article.urlToImage && !hideImage && <img style={styles.verticalArticleImg} alt={article.title} src={article.urlToImage} />}
        {!article.urlToImage && article.articleSource === ArticleSource.HACKER_NEWS && !hideImage && (
          <img
            style={styles.verticalArticleImg}
            alt={article.title}
            src="https://upload.wikimedia.org/wikipedia/en/b/bf/Hackernews_logo.png?20220801220016"
          />
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <ArticleSourceIcon articleSource={article.articleSource} />
            {article.sourceName && <Text style={{ fontSize: 12 }}>{article.sourceName}</Text>}
          </div>
          <a href={article.url} target="_none">
            <H5 style={{ margin: "3px 0" }}>{article.title}</H5>
          </a>
          <Text style={{ fontSize: "0.75rem" }}>
            <i>{toLocalMoment(article.publishedAt).format("h:mm a - MMMM Do")}</i>
          </Text>
          {(!article.isAiContent || !article.content) && (
            <Text style={{ textAlign: "justify", fontSize: "0.75rem", marginTop: 3 }}>
              {article.description?.substring(0, 250)}
              {article.description?.length > 250 ? "..." : ""}
            </Text>
          )}
          {article.isAiContent && article.content && (
            <Text style={{ textAlign: "justify", fontSize: "0.75rem", marginTop: 3 }}>
              {article.content?.substring(0, 500)}
              {article.content?.length > 500 ? "..." : ""}
            </Text>
          )}
        </div>
      </div>
    );
  }

  return (
    <div style={{ ...styles.article, ...style }}>
      {article.urlToImage && !xs && !hideImage && <img style={styles.horizontalArticleImg} alt={article.title} src={article.urlToImage} />}
      {!article.urlToImage && !xs && article.articleSource === ArticleSource.HACKER_NEWS && !hideImage && (
        <img
          style={styles.horizontalArticleImg}
          alt={article.title}
          src="https://upload.wikimedia.org/wikipedia/en/b/bf/Hackernews_logo.png?20220801220016"
        />
      )}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <ArticleSourceIcon articleSource={article.articleSource} />
          {article.sourceName && <Text style={{ fontSize: 12 }}>{article.sourceName}</Text>}
        </div>
        <a href={article.url} target="_none">
          <H5 style={{ margin: "3px 0" }}>{article.title}</H5>
        </a>
        <Text style={{ fontSize: "0.75rem" }}>
          <i>{toLocalMoment(article.publishedAt).format("h:mm a - MMMM Do")}</i>
        </Text>
        {(!article.isAiContent || !article.content) && (
          <Text style={{ textAlign: "justify", fontSize: "0.75rem", marginTop: 3 }}>
            {article.description?.substring(0, 250)}
            {article.description?.length > 250 ? "..." : ""}
          </Text>
        )}
        {article.isAiContent && article.content && (
          <Text style={{ textAlign: "justify", fontSize: "0.75rem", marginTop: 3 }}>
            {article.content?.substring(0, 500)}
            {article.content?.length > 500 ? "..." : ""}
          </Text>
        )}
      </div>
    </div>
  );
};

export default ArticleRow;
