import { FullContainer, Navbar } from "@/components";
import StencilHeadliner from "./StencilHeadliner";

const Homepage3 = () => {
  return (
    <FullContainer>
      <Navbar />
      <StencilHeadliner />
    </FullContainer>
  );
};

export default Homepage3;
