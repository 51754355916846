import React, { useState, useEffect } from "react";

type TextCycleComponentProps = {
  texts: string[];
  interval: number; // time in milliseconds
};

const TextCycleComponent: React.FC<TextCycleComponentProps> = ({ texts, interval }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [animateOut, setAnimateOut] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setAnimateOut(true);
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setAnimateOut(false);
      }, 500); // half of the animation duration
    }, interval);

    return () => clearInterval(timer);
  }, [texts.length, interval]);

  return (
    <div className="text-cycle-container">
      <div className={`text-cycle-item ${animateOut ? "animate-out" : ""}`}>{texts[currentTextIndex]}</div>
    </div>
  );
};

export default TextCycleComponent;
