import { UserTickerTag } from "@/components";
import { useUserTickers } from "@/hooks/data";
import { Text } from "@blueprintjs/core";
import { Link } from "react-router-dom";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
  },
};
const UserTickersView = () => {
  const { userTickers, loading } = useUserTickers();

  if (!loading && userTickers.length === 0)
    return (
      <Text>
        <i>No ticker symbols saved to profile.</i>
      </Text>
    );

  return (
    <div style={styles.container}>
      {userTickers.map((userTicker) => (
        <Link style={{ textDecoration: "none" }} key={userTicker.ticker} to={`/my/stocks/${userTicker.ticker}`}>
          <UserTickerTag ticker={userTicker} />
        </Link>
      ))}
    </div>
  );
};

export default UserTickersView;
