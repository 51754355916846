import { Footer, FullContainer, Navbar } from "@/components";
import { useBriefSend } from "@/hooks/data";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BriefDisplayView } from "@/views";

const ViewBriefPage = () => {
  const { briefSend, loading } = useBriefSend();
  const navigate = useNavigate();

  useEffect(() => {
    if (!briefSend && !loading) navigate("/my/home");
  }, [briefSend, loading]);

  return (
    <FullContainer>
      <Navbar />
      <div style={{ padding: "0 1rem" }}>
        <BriefDisplayView briefSend={briefSend} loading={loading} />
      </div>
      <Footer />
    </FullContainer>
  );
};

export default ViewBriefPage;
