import { clientAxiosInstance } from "@/services";
import { Ticker } from "@/types";

interface UseStocks {
  searchStocks: (prompt: string) => Promise<Array<Ticker>>;
}

export const useStocks = () => {
  const searchStocks: UseStocks["searchStocks"] = async (prompt) => {
    const tickers = await clientAxiosInstance.get<Array<Ticker>>("/stocks", {
      params: {
        prompt,
      },
    });
    return tickers.data;
  };

  return {
    searchStocks,
  };
};
