import { Input } from "@/components";
import { useJoin, useSaveInitialSection } from "@/hooks/data";
import { RootState } from "@/store/reducers";
import { SET_USER } from "@/store/types";
import { InitialSection } from "@/types";
import { toaster } from "@/utils";
import { Button, Callout, Intent, Text } from "@blueprintjs/core";
import { UilCheckCircle } from "@iconscout/react-unicons";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const columnStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "0.5rem",
  paddingTop: "0.25rem",
};

interface Props {
  originalPrompt: string;
  enhancedPrompt: string;
}

const CallToAction = ({ originalPrompt, enhancedPrompt }: Props) => {
  const [initialSection, setInitialSection] = useState<InitialSection | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const { saveInitialSection } = useSaveInitialSection();

  const { join } = useJoin();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);

  const onSave = async () => {
    try {
      setLoading(true);
      const initialSavedSection = await saveInitialSection({
        originalPrompt,
        enhancedPrompt,
      });
      setInitialSection(initialSavedSection);
    } catch (e) {
      window.location.reload();
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      if (!email)
        return toaster.show({
          message: "All fields are required.",
          intent: Intent.DANGER,
        });
      setLoading(true);
      const loggedInUser = await join({ email, initialSectionId: initialSection.id });
      dispatch({ type: SET_USER, payload: loggedInUser });
      navigate("/my/home#converted");
    } catch (err) {}
    setLoading(false);
  };

  if (user) return null;

  return (
    <Callout icon={null} intent={Intent.PRIMARY} title="Want to get briefed on this topic daily?">
      <div style={columnStyle}>
        <Text>Receive a daily update on this topic and other subjects of interest by signing up for a free account.</Text>
        {!initialSection && (
          <div>
            <Button loading={loading} onClick={onSave} intent={Intent.PRIMARY}>
              Sign Up!
            </Button>
          </div>
        )}
      </div>
      {initialSection && (
        <form style={{ marginTop: 20 }} onSubmit={onSubmit}>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            type="email"
            required
            style={{ marginBottom: 10, maxWidth: 400 }}
          />
          <Button
            intent={Intent.PRIMARY}
            style={{ marginTop: 10 }}
            loading={loading}
            type="submit"
            rightIcon={<UilCheckCircle size={18} />}
          >
            Create Account
          </Button>
        </form>
      )}
    </Callout>
  );
};

export default CallToAction;
