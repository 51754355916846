import { RootState } from "@/store/reducers";
import { Spinner } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { ListsProxy } from "./ListsProxy";
import { ListPage, ListsPage } from "@/scenes";

const ListsRouter = () => {
  const user = useSelector((state: RootState) => state.user);

  return (
    <>
      {!user && <Spinner size={20} style={{ position: "fixed", top: "50%", left: "50%" }} />}
      {user && (
        <ListsProxy user={user}>
          <Routes>
            <Route path="/" element={<ListsPage />} />
            <Route path="/:listId" element={<ListPage />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Routes>
        </ListsProxy>
      )}
    </>
  );
};

export default ListsRouter;
