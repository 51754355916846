import React, { useEffect, useState } from "react";

import { Select } from "@/components";
import { Button, Divider, Intent, H5, Text } from "@blueprintjs/core";
import { getBriefLocalDate, toaster } from "@/utils";
import moment from "moment";
import { setHour, setMinute } from "./updateTime";
import { getBrief } from "@/services";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
};

interface Time {
  hour: number;
  minute: number;
  isAm: boolean;
}

const SetDistributionTimeView = () => {
  const [formData, setFormData] = useState<Time>();

  useEffect(() => {
    (async () => {
      try {
        const brief = await getBrief();
        const local = getBriefLocalDate(brief);
        setFormData({
          hour: local.hour() === 0 ? 12 : local.hour() - (local.hour() > 12 ? 12 : 0),
          minute: local.minute(),
          isAm: local.hour() < 12,
        });
      } catch (e) {}
    })();
  }, []);

  const onUpdateTime = async (t: Time) => {
    const time = moment().toDate(); // This will return a copy of the Date that the moment uses

    time.setHours(t.hour === 12 ? (t.isAm ? 0 : 12) : t.hour + (t.isAm ? 0 : 12));
    time.setMinutes(t.minute);
    time.setSeconds(0);
    time.setMilliseconds(0);

    const utcHour = moment(time).utc().hour();
    const utcMinute = moment(time).utc().minute();

    try {
      if (t.hour !== formData.hour || t.isAm !== formData.isAm) await setHour(utcHour);
      if (t.minute !== formData.minute) await setMinute(utcMinute);

      setFormData(t);
    } catch (e) {
      toaster.show({
        message: e?.message || "Error updating send time.",
        intent: Intent.DANGER,
      });
    }
  };

  return (
    <div style={styles.container}>
      <H5 style={{ margin: 0 }}>Daily Delivery Time</H5>
      <Divider />
      <Text style={{ marginBottom: 15 }}>Choose what time of day your brief will hit your inbox!</Text>
      {formData && (
        <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
          <Select
            items={Array.from({ length: 12 }).map((o, i) => ({
              label: i + 1,
              value: i + 1,
            }))}
            onSelect={(item) => {
              onUpdateTime({ ...formData, hour: item.value });
            }}
          >
            <Button text={formData.hour} rightIcon="double-caret-vertical" />
          </Select>
          <H5 style={{ margin: "0 5px", alignSelf: "center" }}> : </H5>
          <Select
            items={Array.from({ length: 6 }).map((o, i) => ({
              label: i * 10,
              value: i * 10,
            }))}
            onSelect={(item) => {
              onUpdateTime({ ...formData, minute: item.value });
            }}
          >
            <Button text={("0" + formData.minute).slice(-2)} rightIcon="double-caret-vertical" />
          </Select>
          <div style={{ marginRight: 5 }} />
          <Select
            items={[
              { label: "AM", value: true },
              { label: "PM", value: false },
            ]}
            onSelect={(item) => {
              onUpdateTime({ ...formData, isAm: item.value });
            }}
          >
            <Button text={formData.isAm ? "AM" : "PM"} rightIcon="double-caret-vertical" />
          </Select>
          <Text style={{ marginLeft: 10, fontSize: 12 }}>{new Date().toString().match(/\(([A-Za-z\s].*)\)/)?.[1] || ""}</Text>
        </div>
      )}
    </div>
  );
};

export default SetDistributionTimeView;
