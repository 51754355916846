import { InnerContainer, FullScreenSpinner, Select } from "@/components";
import { useSearchParamsState } from "@/hooks";
import { useSections } from "@/hooks/data";
import { SearchParamKey } from "@/types";
import { useWidth } from "@/utils";
import { Button, Colors, Intent, Tab, Tabs, Text } from "@blueprintjs/core";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UilPlusCircle } from "@iconscout/react-unicons";
import SectionNewsSection from "./SectionNewsSection";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    gap: 20,
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    flex: 1,
    flexBasis: 0,
    flexGrow: 1,
    minWidth: 0,
  },
  borderLeftStyle: {
    borderLeft: `1px solid ${Colors.LIGHT_GRAY2}`,
    paddingLeft: 20,
  },
};

const SectionsNewsPage = () => {
  const [selectedTabId, setSelectedTabId] = useSearchParamsState<string>(SearchParamKey.SECTION_NEWS_TAB);
  const { xs } = useWidth();
  const navigate = useNavigate();

  const { sections, loading } = useSections();

  useEffect(() => {
    if (!selectedTabId || (!sections.map((t) => t.id).includes(selectedTabId) && sections.length)) {
      setSelectedTabId(sections[0]?.id);
    }
  }, [sections]);

  const selectedSection = sections.find((t) => t.id === selectedTabId);

  return (
    <InnerContainer style={{ paddingTop: "1rem", paddingBottom: "10rem" }}>
      {loading && <FullScreenSpinner />}
      <div style={styles.container}>
        {!Boolean(sections.length) && !loading && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ marginBottom: 5 }}>No interest sections.</Text>
            <Link to="/my/stocks">
              <Button intent={Intent.PRIMARY} icon={<UilPlusCircle size={18} />}>
                Add section
              </Button>
            </Link>
          </div>
        )}
        {Boolean(sections.length) && (
          <div style={{ display: "flex", flexDirection: xs ? "column" : "row", width: "100%", gap: 20 }}>
            <div className="fade-in" style={{ ...styles.column, flexDirection: xs ? "column-reverse" : "column" }}>
              <div style={{ width: "100%" }}>
                {xs && (
                  <Select
                    onSelect={(newTabId) => setSelectedTabId(newTabId.value)}
                    items={sections.map((t) => ({ label: t.originalPrompt, value: t.id }))}
                  >
                    <Button
                      text={sections.find((ut) => ut.id === selectedTabId)?.originalPrompt}
                      outlined
                      fill
                      large
                      rightIcon="double-caret-vertical"
                    />
                  </Select>
                )}
                {!xs && (
                  <Tabs
                    animate={false}
                    onChange={(newTabId) => setSelectedTabId(newTabId as string)}
                    id="market-news-tabs"
                    large
                    vertical
                    selectedTabId={selectedTabId}
                  >
                    {sections.map((t) => (
                      <Tab key={t.id} id={t.id}>
                        {t.originalPrompt}
                      </Tab>
                    ))}
                  </Tabs>
                )}
              </div>
              <Button large onClick={() => navigate("/my/add-section")} intent={Intent.PRIMARY} outlined icon={<UilPlusCircle size={18} />}>
                Add section
              </Button>
            </div>
            {selectedSection && <SectionNewsSection section={selectedSection} />}
          </div>
        )}
      </div>
    </InnerContainer>
  );
};

export default SectionsNewsPage;
