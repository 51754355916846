import React, { useState } from "react";
import { toaster } from "@/utils";

import { Button, Card, H4, Intent, Text } from "@blueprintjs/core";
import { InnerContainer, FullContainer, Navbar, MultilineInput, Footer } from "@/components";

import { submitFeedback } from "./submitFeedback";
import { UilCheckCircle } from "@iconscout/react-unicons";

const styles: Record<string, React.CSSProperties> = {
  centered: {
    width: "100%",
    maxWidth: "25rem",
    margin: "auto",
  },
};

const Feedback = () => {
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      await submitFeedback(text);
      setText("");
      toaster.show({
        message: "Thank you for providing feedback! We may reach out if we have questions.",
        intent: Intent.SUCCESS,
      });
    } catch (err) {
      toaster.show({
        message: err?.message || "Unable to save feedback.",
        intent: Intent.DANGER,
      });
    }
    setLoading(false);
  };

  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ paddingTop: "10rem", minHeight: "100vh" }}>
        <form onSubmit={onSubmit}>
          <Card style={styles.centered}>
            <H4>Share Feedback</H4>
            <Text style={{ marginBottom: 10 }}>
              Sharing feedback will help us improve the product! We appreciate both positive and negative comments. Thank you!
            </Text>
            <MultilineInput value={text} onChange={(e) => setText(e.target.value)} placeholder="I love... I hate..." required />
            <Button
              style={{ marginTop: 10 }}
              loading={loading}
              type="submit"
              rightIcon={<UilCheckCircle size={18} />}
              intent={Intent.SUCCESS}
            >
              Submit
            </Button>
          </Card>
        </form>
      </InnerContainer>
      <Footer />
    </FullContainer>
  );
};

export default Feedback;
