import { ListUser } from "@/types";
import { Button, Colors, Divider, H3, Intent, Text } from "@blueprintjs/core";
import { UilEnvelope, UilPen } from "@iconscout/react-unicons";
import { UseList } from "./useList";
import { useState } from "react";
import EditUserAttributesForm from "./EditUserAttributesForm";

interface Props {
  listUser: ListUser | undefined;
  useListInstance: UseList;
}

function ListUserDetail({ listUser, useListInstance }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [isEditingUserDescription, setIsEditingUserDescription] = useState<boolean>(false);
  const { getInstantBriefForListUser } = useListInstance;

  const onGetEmail = async () => {
    if (!listUser) return;
    setLoading(true);
    try {
      await getInstantBriefForListUser(listUser.id);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  if (!listUser) return null;

  return (
    <div style={{ padding: "1rem", display: "flex", flexDirection: "column" }}>
      <H3 style={{ margin: 0 }}>{listUser.name}</H3>
      <Text style={{ margin: "0.5rem 0 0.25rem" }}>{listUser.email}</Text>
      <Divider />
      {isEditingUserDescription && (
        <EditUserAttributesForm onClose={() => setIsEditingUserDescription(false)} listUser={listUser} useListInstance={useListInstance} />
      )}
      {!isEditingUserDescription && (
        <>
          <div style={{ display: "flex", justifyContent: "space-between", gap: "0.5rem", marginTop: "0.5rem" }}>
            <Text style={{ whiteSpace: "pre-line" }}>{listUser.userDescription}</Text>
            <UilPen style={{ cursor: "pointer" }} onClick={() => setIsEditingUserDescription(true)} size={18} />
          </div>
          {error && (
            <div style={{ marginTop: "1rem" }}>
              <Text style={{ color: Colors.RED3 }}>{error}</Text>
            </div>
          )}
          <div>
            <Button
              loading={loading}
              onClick={onGetEmail}
              icon={<UilEnvelope size={18} />}
              intent={Intent.PRIMARY}
              style={{ marginTop: "1rem" }}
            >
              Generate Email
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default ListUserDetail;
