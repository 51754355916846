export enum ArticleSource {
  HACKER_NEWS = "hackernews",
  NEWS_API = "newsapi",
  PODCHASER = "podchaser",
  APPLE_PODCASTS = "apple-podcast",
  WSJ = "wsj",
  NYT = "nyt",
  ATLANTIC = "atlantic",
  SUBSTACK = "substack",
  FINANCIAL_TIMES = "financial-times",
  ECONOMIST = "economist",
  AXIOS = "axios",
  REUTERS = "reuters",
}

export interface Article {
  id: string;
  url: string;
  title: string | null;
  description: string | null;
  urlToImage: string | null;
  publishedAt: string | null;
  content: string | null;
  sourceId: string | null;
  sourceName: string | null;
  articleSource: ArticleSource;
  score?: number;
  isAiContent: boolean;
}
