import { BackButton, Breadcrumbs, FullContainer, InnerContainer, Navbar, ShowMoreText } from "@/components";
import { useStock, useUserTickers } from "@/hooks/data";
import { Ticker, TickerTypeMap } from "@/types";
import { toaster, useWidth } from "@/utils";
import { Button, Colors, Divider, H3, H4, Intent, Tag, Text } from "@blueprintjs/core";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StockNews from "./StockNews";
import StockOpenClose from "./StockOpenClose";
import Skeleton from "react-loading-skeleton";
import { UilMinusCircle, UilPlusCircle, UilSearchAlt } from "@iconscout/react-unicons";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "35rem",
    padding: "1rem 0 3rem",
  },
};

const StockDetailPage = () => {
  const { xs } = useWidth();
  const { symbol } = useParams();
  const [insertLoading, setInsertLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [ticker, setTicker] = useState<Ticker>();
  const navigate = useNavigate();

  const { getStockDetail } = useStock();
  const { userTickers, insertUserTicker, removeUserTicker } = useUserTickers();

  const loadTickerDetail = async () => {
    try {
      setLoading(true);
      const tickerDetail = await getStockDetail(symbol);
      setTicker(tickerDetail);
    } catch (e) {
      toaster.show({ message: "Symbol not found.", intent: Intent.DANGER });
      navigate("/my/stocks");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTickerDetail();
  }, [symbol]);

  const addTickerToBrief = async () => {
    try {
      setInsertLoading(true);
      await insertUserTicker(ticker.ticker);
    } catch (e) {
      toaster.show({ message: "Error adding to brief.", intent: Intent.DANGER });
    } finally {
      setInsertLoading(false);
    }
  };

  const removeTickerFromBrief = async () => {
    try {
      setInsertLoading(true);
      await removeUserTicker(ticker.ticker);
    } catch (e) {
      toaster.show({ message: "Error removing from brief.", intent: Intent.DANGER });
    } finally {
      setInsertLoading(false);
    }
  };

  const isInBrief = useMemo(() => userTickers.map((ut) => ut.ticker).includes(symbol), [userTickers]);

  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ alignItems: "center", paddingBottom: "5rem" }}>
        <div style={styles.container}>
          <Breadcrumbs
            style={{ marginBottom: "2rem" }}
            items={[
              { text: "My Brief", href: "/my/home" },
              { text: "Search stocks", href: "/my/stocks" },
              { text: symbol, href: `/my/stocks/${symbol}`, isCurrent: true },
            ]}
          />
          <BackButton icon={<UilSearchAlt size={18} />} to="/my/stocks" style={{ marginBottom: 20 }}>
            Search
          </BackButton>
          <div style={{ display: "flex", justifyContent: "space-between", gap: 10 }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ marginBottom: 5 }}>
                <Tag intent={Intent.PRIMARY} minimal>
                  {symbol}
                </Tag>
              </div>
              <H3 style={{ marginBottom: 5 }}>{ticker?.name || <Skeleton width={200} />}</H3>
              <Text style={{ marginBottom: 20, fontSize: "0.8rem" }}>
                {TickerTypeMap[ticker?.type]?.description || (loading && <Skeleton width={100} />)}
              </Text>
            </div>
            {ticker && ticker.description && (
              <div style={{ whiteSpace: "nowrap" }}>
                <Button
                  style={{ display: !isInBrief ? "inline-flex" : "none", marginLeft: "2rem" }}
                  loading={insertLoading}
                  onClick={addTickerToBrief}
                  intent={Intent.PRIMARY}
                  icon={<UilPlusCircle size={18} />}
                >
                  Add to brief
                </Button>
                <Button
                  style={{ display: isInBrief ? "inline-flex" : "none" }}
                  loading={insertLoading}
                  onClick={removeTickerFromBrief}
                  intent={Intent.NONE}
                  icon={<UilMinusCircle size={18} />}
                >
                  Remove from brief
                </Button>
              </div>
            )}
          </div>
          {ticker && <StockOpenClose ticker={ticker} />}
          <div style={{ marginBottom: "1rem" }} />
          {ticker?.homepageUrl && (
            <a target="_none" href={ticker?.homepageUrl} style={{ marginBottom: 3 }}>
              {" "}
              <Text style={{ fontSize: "0.75rem", color: Colors.BLUE3 }}>{ticker.homepageUrl}</Text>
            </a>
          )}
          {ticker?.sicDescription && (
            <Text style={{ marginBottom: 5 }}>
              <i>{ticker.sicDescription}</i>
            </Text>
          )}
          {ticker?.description ? (
            <ShowMoreText style={{ textAlign: "justify" }} text={ticker?.description} />
          ) : loading ? (
            <Skeleton count={3} />
          ) : null}
          <H4 style={{ marginTop: "2rem", marginBottom: 0 }}>Related news</H4>
          <Divider style={{ marginBottom: "1rem" }} />
          {ticker && <StockNews orientation={xs ? "vertical" : "horizontal"} ticker={ticker} />}
        </div>
      </InnerContainer>
    </FullContainer>
  );
};

export default StockDetailPage;
