import { ArticleRow, UserTickerTag } from "@/components";
import { TickerWithArticles } from "@/types";
import { Divider, H5, Text } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  ticker: TickerWithArticles;
}

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "0.75rem",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "1rem",
  },
};

const TickerSection = ({ ticker }: Props) => {
  return (
    <div style={styles.container}>
      <div style={styles.headerContainer}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <H5 style={{ margin: 0 }}>{ticker.ticker}</H5>
          <Text>{ticker.name}</Text>
        </div>
        <Link style={{ textDecoration: "none" }} to={`/my/stocks/${ticker.ticker}`}>
          <UserTickerTag hideText ticker={ticker} />
        </Link>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        {ticker.articles.map((article, i) => (
          <React.Fragment key={article.id}>
            <ArticleRow hideImage key={article.id} article={article} />
            {i !== ticker.articles.length - 1 && <Divider style={{ width: "100%", marginBottom: 5 }} />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default TickerSection;
