import { InnerContainer, SuggestionChips } from "@/components";
import { SearchParamKey } from "@/types";
import { useWidth } from "@/utils";
import { Button, Text, Intent, H2, Colors } from "@blueprintjs/core";
import React from "react";
import { useNavigate } from "react-router-dom";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "2rem",
    position: "relative",
  },
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  input: {
    maxWidth: "30rem",
    marginBottom: 10,
    width: "100%",
  },
  article: {
    display: "flex",
    gap: 10,
    marginBottom: 10,
  },
  articleImg: {
    width: "25%",
    objectFit: "cover",
    borderRadius: 4,
  },
  articleContainer: {
    maxWidth: 500,
    textAlign: "left",
    marginTop: 40,
  },
};

const JoinThousands = () => {
  const { xs, sm } = useWidth();
  const navigate = useNavigate();

  return (
    <div
      style={{
        ...styles.container,
        padding: xs ? "6rem 1rem" : sm ? "9rem 0" : "10rem 0",
      }}
    >
      <InnerContainer>
        <div style={{ ...styles.formContainer }}>
          <H2 style={{ marginBottom: "0.75rem", maxWidth: "35rem", fontSize: xs ? "2.25rem" : sm ? "2.5rem" : "2.25rem" }}>
            Join 1,000s of others...
          </H2>
          <Text style={{ marginBottom: "1.75rem", maxWidth: "35rem", fontSize: xs ? "1.5rem" : sm ? "1.35rem" : "1.5rem" }}>
            who stopped asking, <br /> <span style={{ color: Colors.BLUE3, fontWeight: 600 }}>what should I read today?</span>
          </Text>
          <SuggestionChips
            onClick={(topic: string) => navigate(`/start/?${SearchParamKey.INITIAL_PROMPT}=${topic}`)}
            multicolor
            style={{ maxWidth: "48rem" }}
            count={xs ? 20 : sm ? 30 : 40}
          />
          <Button
            onClick={() => navigate("/start")}
            style={{ marginTop: "2rem", width: "10rem", borderRadius: 1000 }}
            type="submit"
            large
            intent={Intent.PRIMARY}
          >
            Get briefed!
          </Button>
        </div>
      </InnerContainer>
    </div>
  );
};

export default JoinThousands;
