import { useEmailInteractionMetrics } from "@/hooks/data";
import { Divider, H4, HTMLTable } from "@blueprintjs/core";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const AdminEmailInteractionMetrics = () => {
  const { emailInteractionMetrics, loading } = useEmailInteractionMetrics();

  return (
    <>
      <H4>Email Interactions</H4>
      <Divider style={{ margin: 0 }} />
      <HTMLTable style={{ width: "100%" }} bordered>
        <thead>
          <tr>
            <th>Date</th>
            <th>Unique Opens</th>
            <th>Delivered</th>
            <th>Open %</th>
            <th>Blocked</th>
            <th>Dropped (spam)</th>
          </tr>
        </thead>
        {!!emailInteractionMetrics.length && (
          <tbody>
            {emailInteractionMetrics.map((emailInteractionMetric) => (
              <tr key={emailInteractionMetric.date}>
                <td>{moment(emailInteractionMetric.date).format("MMM Do")}</td>
                <td>{emailInteractionMetric.unique_opens}</td>
                <td>{emailInteractionMetric.delivered}</td>
                <td>{((emailInteractionMetric.unique_opens / emailInteractionMetric.delivered) * 100 || 0).toFixed(1)}%</td>
                <td>{emailInteractionMetric.blocks}</td>
                <td>{emailInteractionMetric.spam_report_drops}</td>
              </tr>
            ))}
          </tbody>
        )}
        {!!!emailInteractionMetrics.length && !loading && (
          <tbody>
            <tr>
              <td>No data</td>
            </tr>
          </tbody>
        )}
      </HTMLTable>
      {!!!emailInteractionMetrics.length && loading && <Skeleton count={10} height={10} />}
    </>
  );
};

export default AdminEmailInteractionMetrics;
