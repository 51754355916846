import { DisplaySectionWithArticles } from "@/types";
import { Colors, H3, OL } from "@blueprintjs/core";
import { Link } from "react-router-dom";

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  border: `4px solid ${Colors.BLUE4}`,
  borderRadius: 8,
  padding: "1rem",
};

interface Props {
  sections: Array<DisplaySectionWithArticles> | undefined;
}

function NavigateToSection({ sections }: Props) {
  if (!sections?.length) return null;

  return (
    <div style={containerStyle}>
      <H3>Sections</H3>
      <OL style={{ margin: 0, paddingLeft: 24 }}>
        {sections.map((section) => (
          <li key={section.id}>
            <Link to={`#${section.id}`}>{section.originalPrompt}</Link>
          </li>
        ))}
      </OL>
    </div>
  );
}

export default NavigateToSection;
