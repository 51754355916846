export function isColorTooLight(color: string, boundary: number = 0.98): boolean {
  // Convert the color to its RGB components
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);

  // Calculate the relative luminance using the WCAG formula
  const relativeLuminance = 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

  // If the relative luminance is greater than or equal to 0.98,
  // the color is considered too light on a white background
  return relativeLuminance >= boundary;
}
