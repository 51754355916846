import { clientAxiosInstance } from "@/services";
import { User } from "@/types";
import { setAuthToken, toaster } from "@/utils";
import { Intent } from "@blueprintjs/core";

interface JoinResponse {
  token: string;
  user: User;
}

export interface UseJoin {
  join: (input: { email: string; initialSectionId: string }) => Promise<User>;
}

export const useJoin = () => {
  const join = async (input: { email: string; initialSectionId: string }): Promise<User> => {
    try {
      const joinResponse = await clientAxiosInstance.post<JoinResponse>("/join", input);

      window.gtag("event", "conversion", { send_to: "AW-337002105/njRuCIf6oc4YEPn82KAB" });

      setAuthToken(joinResponse.data.token);
      return joinResponse.data.user;
    } catch (e) {
      toaster.show({
        message: e?.message || "Unknown error joining.",
        intent: Intent.DANGER,
      });
      throw e;
    }
  };
  return { join };
};
