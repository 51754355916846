import { clientAxiosInstance } from "@/services";
import { CreateListUserPayload, List, ListUser } from "@/types";
import { toaster } from "@/utils";
import { useEffect, useState } from "react";

export interface UseList {
  list: List | undefined;
  loading: boolean;
  bulkAddUsersToList: (payload: CreateListUserPayload[]) => Promise<void>;
  getInstantBriefForListUser: (listUserId: string) => Promise<void>;
  updateListUserDescription: (listUserId: string, userDescription: string) => Promise<void>;
}

export const useList = (listId: string | undefined): UseList => {
  const [loading, setLoading] = useState<boolean>(true);
  const [list, setList] = useState<List | undefined>();

  const getList = async () => {
    setLoading(true);
    try {
      const response = await clientAxiosInstance.get<{ list: List }>(`/lists/${listId}`);
      setList(response.data.list);
    } catch (e) {
      toaster.show({ message: e.message, intent: "danger" });
    } finally {
      setLoading(false);
    }
  };

  const bulkAddUsersToList = async (payload: CreateListUserPayload[]) => {
    if (!list) return;
    try {
      const response = await clientAxiosInstance.post<{ users: ListUser[] }>(`/lists/${listId}/users/bulk`, payload);
      setList((prevList) => {
        return {
          ...prevList,
          users: response.data.users,
        };
      });
    } catch (e) {
      throw e;
    }
  };

  const getInstantBriefForListUser = async (listUserId: string): Promise<void> => {
    if (!list) return;
    const response = await clientAxiosInstance.get<{ instantBrief: string }>(`/lists/${list.id}/users/${listUserId}/instant-brief`);
    // Open a new blank window
    const newWindow = window.open("", "_blank");

    // Write the HTML content to the new window
    if (newWindow && newWindow.document) {
      newWindow.document.write(response.data.instantBrief);

      // Optional: This is to ensure that the new window will render HTML correctly
      newWindow.document.close();
    }
  };

  const updateListUserDescription = async (listUserId: string, userDescription: string) => {
    if (!list) return;
    try {
      const response = await clientAxiosInstance.put<{ listUser: ListUser }>(`/lists/${list.id}/users/${listUserId}/description`, {
        userDescription,
      });
      setList((prevList) => {
        return {
          ...prevList,
          users: prevList.users.map((listUser) => {
            if (listUser.id === listUserId) return response.data.listUser;
            return listUser;
          }),
        };
      });
    } catch (e) {
      toaster.show({ message: e.message, intent: "danger" });
      throw e;
    }
  };

  useEffect(() => {
    if (listId) getList();
  }, [listId]);

  return {
    list,
    loading,
    bulkAddUsersToList,
    getInstantBriefForListUser,
    updateListUserDescription,
  };
};
