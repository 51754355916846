import { Ticker } from "@/types";
import { Colors, Intent, Tag, Text } from "@blueprintjs/core";
import { UilAngleRight } from "@iconscout/react-unicons";
import { useNavigate } from "react-router-dom";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid " + Colors.LIGHT_GRAY3,
    padding: "0.5rem 1rem 0.5rem 0",
    position: "relative",
    alignItems: "center",
  },
  seeMoreIcon: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
  },
  name: {
    maxWidth: "50%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "0.8rem",
  },
};

interface Props {
  ticker: Ticker;
}

const StockRow = ({ ticker }: Props) => {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(`/my/stocks/${ticker.ticker}`)} className="gray-hover" style={styles.container}>
      <UilAngleRight size={18} style={styles.seeMoreIcon} />
      <Tag minimal intent={Intent.PRIMARY}>
        {ticker.ticker}
      </Tag>
      <Text style={styles.name}>{ticker.name}</Text>
    </div>
  );
};

export default StockRow;
