import { ReactNode } from "react";
import ResubscribePage from "./ResubscribePage";
import { useBrief } from "@/hooks/data";

interface Props {
  children: ReactNode;
  allowedPaths?: Array<string>;
}

const IsSubscribedProxy = ({ children }: Props) => {
  const { brief, changeIsSubscribed } = useBrief();

  if (!brief || brief?.isSubscribed) return <>{children}</>;

  return <ResubscribePage brief={brief} changeIsSubscribed={changeIsSubscribed} />;
};

export default IsSubscribedProxy;
