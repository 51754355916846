import { clientAxiosInstance } from "@/services";
import { InstantSectionWithArticles, PromptType } from "@/types";
import { useState } from "react";

interface GetInstantBriefResponse {
  sections: Array<InstantSectionWithArticles>;
  subject: string;
}

export interface UseInstantBrief {
  usePromptTypeStateInstance: [PromptType, React.Dispatch<React.SetStateAction<PromptType>>];
  loadInstantBrief: (prompt: string) => Promise<void>;
  sections: Array<InstantSectionWithArticles>;
  loading: boolean;
  error: string | null;
  subject: string;
}

export const useInstantBrief = (): UseInstantBrief => {
  const usePromptTypeStateInstance = useState<PromptType>(PromptType.BROAD);
  const [promptType] = usePromptTypeStateInstance;
  const [sections, setSections] = useState<Array<InstantSectionWithArticles>>([]);
  const [subject, setSubject] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const loadInstantBrief = async (prompt: string) => {
    setLoading(true);
    try {
      const response = await clientAxiosInstance.post<GetInstantBriefResponse>("/articles/research", { prompt, promptType });
      window.gtag("event", "conversion", { send_to: "AW-337002105/O_T1CIr6oc4YEPn82KAB" });
      setSections(response.data.sections);
      setSubject(response.data.subject);
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    usePromptTypeStateInstance,
    loadInstantBrief,
    sections,
    loading,
    error,
    subject,
  };
};
