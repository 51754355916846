import React from "react";

import { FullContainer, InnerContainer, Navbar } from "@/components";
import { AccountSection } from "./Account";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "35rem",
    padding: "3rem 0",
  },
};

const SettingsPage = () => {
  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ alignItems: "center" }}>
        <div style={styles.container}>
          <AccountSection />
          <div style={{ marginBottom: 60 }} />
        </div>
      </InnerContainer>
    </FullContainer>
  );
};

export default SettingsPage;
