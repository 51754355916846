import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Card, H4, Intent, Text } from "@blueprintjs/core";
import { InnerContainer, FullContainer, Navbar } from "@/components";

import { useSelector } from "react-redux";
import { RootState } from "@/store/reducers";

import { UilArrowLeft } from "@iconscout/react-unicons";

const styles: Record<string, React.CSSProperties> = {
  centered: {
    width: "100%",
    maxWidth: "25rem",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
  },
};

const LinkSentLoginPage = () => {
  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user) navigate("/my/home");
  }, []);

  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ paddingTop: "10rem" }}>
        <Link to="/login" style={{ ...styles.centered, marginBottom: 20 }}>
          <Button large intent={Intent.NONE} icon={<UilArrowLeft size={18} />} fill>
            Back to login
          </Button>
        </Link>
        <Card style={styles.centered}>
          <H4>Login Link Sent!</H4>
          <Text>Check your email for your login link! It will expire in 10 minutes!</Text>
        </Card>
      </InnerContainer>
    </FullContainer>
  );
};

export default LinkSentLoginPage;
