import { useUserSignupMetrics } from "@/hooks/data";
import { Divider, H4, HTMLTable } from "@blueprintjs/core";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const AdminUserSignupMetrics = () => {
  const { userSignups, loading } = useUserSignupMetrics();

  return (
    <>
      <H4>User Signups</H4>
      <Divider style={{ margin: 0 }} />
      <HTMLTable style={{ width: "100%" }} bordered>
        <thead>
          <tr>
            <th>Date</th>
            <th>Count</th>
          </tr>
        </thead>
        {userSignups && (
          <tbody>
            {Object.entries(userSignups).map(([date, count]) => (
              <tr key={date}>
                <td>{moment(date).format("MMM Do")}</td>
                <td>{count}</td>
              </tr>
            ))}
          </tbody>
        )}
        {!userSignups && !loading && (
          <tbody>
            <tr>
              <td>No data</td>
              <td>No data</td>
            </tr>
          </tbody>
        )}
      </HTMLTable>
      {!userSignups && loading && <Skeleton count={10} height={10} />}
    </>
  );
};

export default AdminUserSignupMetrics;
