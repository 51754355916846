import { Footer, FullContainer, Navbar } from "@/components";
import { GenerateBrief } from "./GenerateBrief";

const ResearchPage = () => {
  return (
    <FullContainer>
      <Navbar />
      <div style={{ minHeight: "100vh" }}>
        <GenerateBrief />
        <Footer />
      </div>
    </FullContainer>
  );
};

export default ResearchPage;
