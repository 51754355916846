import { IAggsResults } from "@/types";
import { Colors, Text } from "@blueprintjs/core";
import { LineSeries, Tooltip, XYChart, Axis } from "@visx/xychart";
import { ParentSize } from "@visx/responsive";
import moment from "moment";
import { convertToDecimalWithCommas } from "@/utils";

const accessors = {
  xAccessor: (d: IAggsResults) => moment(d.t).toDate(),
  yAccessor: (d: IAggsResults) => d.c,
};

interface Props {
  aggregates: Array<IAggsResults>;
  prevClose: number;
}

const StockChart = ({ aggregates, prevClose }: Props) => {
  const height = 300;
  const min = Math.min(...aggregates.map((a) => a.c), prevClose);
  const max = Math.max(...aggregates.map((a) => a.c), prevClose);
  const current = aggregates?.[aggregates.length - 1]?.c;
  const isPositive = current > prevClose;

  return (
    <div style={{ width: "100%", height, position: "relative" }}>
      <ParentSize>
        {(parent) => (
          <XYChart
            margin={{ top: 5, right: 15, left: 15, bottom: 25 }}
            height={parent.height}
            width={parent.width}
            xScale={{ type: "time" }}
            yScale={{
              zero: false,
              type: "linear",
              domain: [min - (max - min) / 5, max + (max - min) / 5],
            }}
          >
            <Axis
              tickComponent={() => null}
              strokeDasharray="3,5"
              strokeWidth={1}
              stroke={Colors.GRAY3}
              hideTicks
              // Makes sure it doesn't hug the bottom or top too much
              top={Math.max(Math.min(height - 20, ((max - prevClose) / (max - min)) * height), 10)}
              orientation="bottom"
            />
            <Axis
              tickFormat={function tickFormat(d) {
                return moment(d).format("ha");
              }}
              hideAxisLine
              numTicks={4}
              stroke={Colors.GRAY3}
              hideTicks
              orientation="bottom"
              top={height - 20}
            />
            <LineSeries stroke={!isPositive ? Colors.RED3 : Colors.GREEN3} dataKey="primary_line" data={aggregates} {...accessors} />
            <Tooltip
              snapTooltipToDatumX
              snapTooltipToDatumY
              showSeriesGlyphs
              showVerticalCrosshair
              glyphStyle={{
                fill: !isPositive ? Colors.RED3 : Colors.GREEN3,
                strokeWidth: 0,
              }}
              renderTooltip={({ tooltipData }) => {
                return (
                  <>
                    {Object.entries(tooltipData.datumByKey).map((lineDataArray) => {
                      const [key, value] = lineDataArray;

                      return (
                        <Text style={{ fontSize: "0.75rem", fontWeight: 300 }} key={key}>
                          {convertToDecimalWithCommas(accessors.yAccessor(value.datum), 2)}
                        </Text>
                      );
                    })}
                  </>
                );
              }}
            />
          </XYChart>
        )}
      </ParentSize>
    </div>
  );
};

export default StockChart;
