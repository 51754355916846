import { ArticleSource } from "@/types";

const articleSourceEmojiMap: Record<ArticleSource, string> = {
  // Newspaper
  [ArticleSource.HACKER_NEWS]: "&#128240;",
  [ArticleSource.NEWS_API]: "&#128240;",
  [ArticleSource.WSJ]: "&#128240;",
  [ArticleSource.NYT]: "&#128240;",
  [ArticleSource.ATLANTIC]: "&#128240;",
  [ArticleSource.SUBSTACK]: "&#128240;",
  [ArticleSource.FINANCIAL_TIMES]: "&#128240;",
  [ArticleSource.ECONOMIST]: "&#128240;",
  [ArticleSource.AXIOS]: "&#128240;",
  [ArticleSource.REUTERS]: "&#128240;",
  // Microphone
  [ArticleSource.PODCHASER]: "&#127897;",
  [ArticleSource.APPLE_PODCASTS]: "&#127897;",
};

const articleSourceLabelMap: Record<ArticleSource, string> = {
  // Newspaper
  [ArticleSource.HACKER_NEWS]: "News Story",
  [ArticleSource.NEWS_API]: "News Story",
  [ArticleSource.WSJ]: "News Story",
  [ArticleSource.NYT]: "News Story",
  [ArticleSource.ATLANTIC]: "News Story",
  [ArticleSource.SUBSTACK]: "News Story",
  [ArticleSource.FINANCIAL_TIMES]: "News Story",
  [ArticleSource.ECONOMIST]: "News Story",
  [ArticleSource.AXIOS]: "News Story",
  [ArticleSource.REUTERS]: "News Story",
  // Microphone
  [ArticleSource.PODCHASER]: "Podcast",
  [ArticleSource.APPLE_PODCASTS]: "Podcast",
};

export const mapArticleSourceToEmoji = (src: ArticleSource): string => articleSourceEmojiMap[src];

export const mapArticleSourceToLabel = (src: ArticleSource): string => articleSourceLabelMap[src];

export const manTechnologistEmoji = "&#128104;&#8205;&#128187;";
export const womanTechnologistEmoji = "&#128105;&#8205;&#128187;";

export const chartIncreasingEmoji = "&#128200;";
export const chartDecreasingEmoji = "&#128201;";
