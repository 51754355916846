import React from "react";
import { InnerContainer, FullScreenSpinner, ArticleRow } from "@/components";
import { usePopularArticles } from "@/hooks/data";
import { useWidth } from "@/utils";
import { Colors, Divider, Text } from "@blueprintjs/core";
import Skeleton from "react-loading-skeleton";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    gap: 20,
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    flex: 1,
    flexBasis: 0,
    flexGrow: 1,
    minWidth: 0,
  },
  borderLeftStyle: {
    borderLeft: `1px solid ${Colors.LIGHT_GRAY2}`,
    paddingLeft: 20,
  },
};

const PopularNewsPage = () => {
  const { sm, md } = useWidth();

  const { articles, loading } = usePopularArticles();

  const divide = sm ? 1 : md ? 2 : 3;

  return (
    <InnerContainer style={{ paddingTop: "1rem", paddingBottom: "10rem" }}>
      {loading && <FullScreenSpinner />}
      <div style={{ ...styles.container, flex: 1 }}>
        <div style={{ display: "flex", gap: 20 }}>
          {!Boolean(articles.length) && (
            <Text>
              <i>No related news found.</i>
            </Text>
          )}
          {Array.from({ length: divide }).map((_outer, i) => (
            <div
              key={i}
              className="fade-in"
              style={{
                ...styles.column,
                ...(i !== 0 && styles.borderLeftStyle),
              }}
            >
              {loading && (
                <Skeleton
                  wrapper={({ children }) => <div style={{ display: "flex", flexDirection: "column" }}>{children}</div>}
                  height={100}
                  width="100%"
                  count={5}
                />
              )}
              {articles
                .filter((_inner, i2) => (i2 + 1) % divide === i)
                .map((article, i2) => (
                  <React.Fragment key={article.id}>
                    <ArticleRow orientation="vertical" article={article} />
                    <Divider style={{ width: "100%", marginBottom: 10 }} />
                  </React.Fragment>
                ))}
            </div>
          ))}
        </div>
      </div>
    </InnerContainer>
  );
};

export default PopularNewsPage;
