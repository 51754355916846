import { Intent, Tag } from "@blueprintjs/core";
import { useMemo } from "react";

interface Props {
  count: number;
  style?: React.CSSProperties;
  multicolor?: boolean;
  onClick: (topic: string) => void;
  intent?: Intent;
}

const containerStyle: React.CSSProperties = {
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
  flexWrap: "wrap",
};

const suggestions = [
  "AI Ethics",
  "Climate Policies",
  "Space Exploration",
  "Economic Trends",
  "Public Health",
  "Modern Art",
  "Education Reform",
  "Global Travel",
  "Professional Sports",
  "Scientific Discoveries",
  "Startup Businesses",
  "Hollywood Gossip",
  "Healthy Living",
  "Gourmet Cuisine",
  "Fashion Trends",
  "Electric Cars",
  "Religious Freedom",
  "Law Enforcement",
  "Military Technology",
  "Severe Weather",
  "Historical Analysis",
  "Astronomy",
  "Literary Reviews",
  "Cybersecurity Threats",
  "Mental Health Awareness",
  "Blockchain Uses",
  "Wildlife Conservation",
  "Social Media Influence",
  "Virtual Reality Applications",
  "Quantum Computing Progress",
  "Music Industry Insights",
  "Endangered Languages",
  "Robotics Innovations",
  "Climate Refugees",
  "Space Tourism",
  "Smart Cities",
  "Water Security",
  "Nanotechnology Developments",
  "Sustainable Agriculture",
  "Ethical Fashion",
  "Urban Planning",
  "Solar Power Advances",
  "Vaccine Development",
  "Meditation Benefits",
  "Adventure Tourism",
  "Photography Trends",
  "Silicon Valley",
];

const intents = [Intent.PRIMARY, Intent.SUCCESS, Intent.DANGER, Intent.WARNING, Intent.NONE];

function getRandomTopics(n: number): string[] {
  const shuffled = suggestions.slice().sort(() => Math.random() - 0.5);
  return shuffled.slice(0, n);
}

const SuggestionChips = ({ count, style = {}, onClick, multicolor = false, intent = Intent.PRIMARY }: Props) => {
  const randomSuggestions = useMemo(() => getRandomTopics(count), [count]);

  if (count > suggestions.length) return null;

  return (
    <div style={{ ...containerStyle, ...style }}>
      {randomSuggestions.map((topic, i) => (
        <Tag
          interactive
          intent={!multicolor ? intent : Object.values(intents)[(i + 1) % Object.keys(intents).length]}
          minimal
          round
          key={i}
          large
          onClick={(e) => onClick(topic)}
        >
          {topic}
        </Tag>
      ))}
    </div>
  );
};

export default SuggestionChips;
