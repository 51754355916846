import { Select } from "@/components";
import { UseInstantBrief } from "@/hooks/data";
import { PromptType, promptTypeLabelMap } from "@/types";
import { Button, Intent } from "@blueprintjs/core";

interface Props {
  useInstantBriefInstance: UseInstantBrief;
}

const PromptTypeDropdown = ({ useInstantBriefInstance }: Props) => {
  const { usePromptTypeStateInstance } = useInstantBriefInstance;
  const [promptType, setPromptType] = usePromptTypeStateInstance;

  return (
    <Select
      intent={Intent.NONE}
      onSelect={(item) => setPromptType(item.value)}
      items={[
        {
          label: promptTypeLabelMap[PromptType.BROAD],
          value: PromptType.BROAD,
        },
        {
          label: promptTypeLabelMap[PromptType.NARROW],
          value: PromptType.NARROW,
        },
      ]}
    >
      <Button
        style={{ borderRadius: 10000 }}
        intent={Intent.NONE}
        text={promptTypeLabelMap[promptType]}
        outlined
        rightIcon="double-caret-vertical"
      />
    </Select>
  );
};

export default PromptTypeDropdown;
