import { Footer, FullContainer, InnerContainer, Navbar } from "@/components";

const TermsOfUse = () => {
  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ maxWidth: "40rem", padding: "2rem 0 5rem" }}>
        <h1>
          <strong>Terms of Use</strong>
        </h1>
        <p>Effective date: February 27, 2023</p>
        <p>
          <strong>Welcome to ittybrief!&nbsp;</strong>
        </p>
        <p>
          These Terms of Use are a binding contract between you and JUKEBLOX LLC (“we”, “us”, “our”).&nbsp;It contains the rules and
          restrictions that govern your use of ittybrief's products and services (referred to below simply as ittybrief).
        </p>
        <p>
          Using ittybrief in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use
          ittybrief. These Terms include everything in this document, as well as those in the
          <a href="https://www.ittybrief.com/privacy">Privacy Policy</a>. If you don’t agree to all of the following, you may not use or
          access ittybrief in any manner.&nbsp;
        </p>
        <p>
          You represent and warrant that you are of legal age to form a binding contract (or if not, that you’ve received your parent’s or
          guardian’s permission to use Substack and that your parent or guardian agrees to these Terms on your behalf).&nbsp;
          <br />
          <br />
          If you’re agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to
          agree to these Terms on that organization or entity’s behalf and bind them to these Terms (in which case, the references to “you”
          and “your” throughout this document refer to that organization or entity).
        </p>
        <p>
          <strong>Creating an Account on ittybrief</strong>
        </p>
        <p>
          You may be required to sign up for an account and select a password.&nbsp;You promise to provide us with accurate, complete, and
          up-to-date registration information about yourself.&nbsp;
        </p>
        <p>
          You agree that you will only use ittybrief for your own personal or organizational use, and not on behalf of or for the benefit of
          any third party. You may not transfer your account to anyone else without our prior written permission.
        </p>
        <p>
          You may not select as your ittybrief account name a name that you don’t have the right to use, or another person’s name with the
          intent to impersonate that person. ittybrief reserves the right to refuse registration of or cancel a ittybrief account name at
          its discretion.&nbsp;
        </p>

        <p>
          <strong>Third-Parties</strong>
        </p>
        <ul>
          <li>
            <p>
              Third-Party Content: ittybrief may contain links or connections to third party websites or services that are not owned or
              controlled by us. ittybrief has no control over, and assumes no responsibility for, the content, accuracy, privacy policies,
              or practices of or opinions expressed in any third-party websites. You release and hold us harmless from any and all liability
              arising from your use of any third-party website or service.
            </p>
          </li>
        </ul>
        <p>
          In the event that you have a dispute with one or more other users of ittybrief or with a third party, you release us, our
          officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected
          or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or ittybrief.&nbsp;
        </p>
        <p>
          <strong>Limitation of Liability</strong>
        </p>
        <p>
          <strong>
            To the fullest extent allowed by applicable law, under no circumstances and under no legal theory shall ittybrief, its
            licensors, or its suppliers be liable to you or to any other person for:
          </strong>
        </p>
        <ul>
          <li>
            <p>
              <strong>Any indirect, special, incidental, or consequential damages of any kind, or</strong>
            </p>
          </li>
        </ul>
        <p>
          <strong>No Warranties</strong>
        </p>
        <p>
          <strong>
            ittybrief is provided to you on an “as-is” basis. This means we provide it to you without any express or implied warranties of
            any kind. That includes any implied warranties of merchantability, warranties of fitness for a particular purpose,
            non-infringement, or any warranty that the use of ittybrief will be uninterrupted or error-free. Accordingly, we do not:
          </strong>
        </p>
        <ul>
          <li>
            <p>
              <strong>
                Make any representations or warranties about any content contained in or accessed through ittybrief, and we will not be
                responsible for the accuracy, copyright compliance, legality, or decency of material contained on our products and
                services.&nbsp;
              </strong>
            </p>
          </li>
        </ul>

        <p>
          <strong>Terminating Your Account</strong>
        </p>
        <p>
          ittybrief is free to terminate (or suspend access to) your use of ittybrief, or your account, for any reason at our discretion. We
          will try to provide advance notice to you prior to our terminating your account so that you are able to retrieve any important
          Posts you may have uploaded to your account, but we may not do so if we determine it would be impractical, illegal, not in the
          interest of someone’s safety or security, or otherwise harmful to the rights or property of ittybrief.
        </p>
        <p>
          Substack also allows you to delete your account at any time.
          <strong>If you'd like to delete your account, you can do so by contacting our support email.</strong>
        </p>

        <p>
          You agree that some of the obligations in these Terms will be in force even after you terminate your account.&nbsp;All of the
          following terms will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any
          terms regarding ownership or intellectual property rights, terms regarding disputes between us, and any other terms that, by their
          nature, should survive termination of these Terms.
        </p>
        <p>
          <strong>Privacy on ittybrief</strong>
        </p>
        <p>
          ittybrief takes your privacy very seriously. For the current ittybrief Privacy Policy, please click
          <a href="https://www.ittybrief.com/privacy">here</a>.
        </p>
        <p>
          The Children’s Online Privacy Protection Act (“COPPA”) requires that online service providers obtain parental consent before they
          knowingly collect personally identifiable information online from children.&nbsp; We do not knowingly collect or solicit
          personally identifiable information from children under 16; if you are a child under 16, please do not attempt to register for
          ittybrief or send any personal information about yourself to us.&nbsp; If we learn we have collected personal information from a
          child under 16, we will delete that information as quickly as possible.&nbsp; If you believe that a child under 16 may have
          provided us personal information, please contact us.
        </p>
        <p>
          <strong>Changes to ittybrief</strong>
        </p>
        <p>
          We’re always trying to improve ittybrief, so our products and services may change over time.&nbsp;We may suspend or discontinue
          any part of ittybrief, or we may introduce new features or impose limits on certain features or restrict access to parts or all of
          ittybrief. We’ll try to give you notice when we make a material change to ittybrief that would adversely affect you, but this
          isn’t always possible or practical.
        </p>
        <p>
          <strong>Changes to the Terms</strong>
        </p>
        <p>
          We are constantly trying to improve our products and services, so these Terms may need to change along with ittybrief.&nbsp; We
          reserve the right to change the Terms at any time, but if we do, we will bring it to your attention by placing a notice on the
          website, by sending you an email, and/or by some other means.&nbsp;
        </p>
        <p>
          If you don’t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use
          ittybrief.&nbsp; If you use ittybrief in any way after a change to the Terms is effective and notice has been provided, that means
          you agree to all of the changes.&nbsp;
        </p>
        <p>
          Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing
          and signed by both you and us.&nbsp;
        </p>
        <p>
          <strong>Violations of the Terms</strong>
        </p>
        <p>
          Failure to follow any of these Terms shall constitute a breach of these Terms, which may result in immediate termination of your
          account.&nbsp;Substack has the sole right to decide whether you are in violation of any of the restrictions set forth in these
          Terms.&nbsp;
        </p>
        <p>
          <strong>Miscellaneous Terms</strong>
        </p>
        <p>
          The above covers most of the questions that we typically receive about ittybrief. We have grouped provisions that come up less
          frequently below:
        </p>
        <ul>
          <li>
            <p>
              Indemnification:&nbsp;To the fullest extent allowed by applicable law, you agree to indemnify and hold ittybrief, its
              affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages
              (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any third
              party claims relating to (a) your use of ittybrief (including any actions taken by a third party using your account), and (b)
              your violation of these Terms.&nbsp; In the event of such a claim, suit, or action, we will attempt to provide notice to the
              contact information we have for your account (provided that failure to deliver such notice shall not eliminate or reduce your
              indemnification obligations hereunder).
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Assignment: You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your ittybrief
              account, in any way (by operation of law or otherwise) without our prior written consent.&nbsp; We may transfer, assign, or
              delegate these Terms and our rights and obligations without consent.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Choice of Law: These Terms are governed by and will be construed under applicable federal law and the laws of the State of
              California, without regard to the conflicts of laws provisions thereof.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Arbitration and Class Action Waiver: Any dispute arising from or relating to the subject matter of these Terms shall be
              finally settled by arbitration in San Francisco County, California, in accordance with the Streamlined Arbitration Rules and
              Procedures of Judicial Arbitration and Mediation Services, Inc. (“JAMS”) then in effect, by one commercial arbitrator with
              substantial experience in resolving intellectual property and commercial contract disputes, who shall be selected from the
              appropriate list of JAMS arbitrators in accordance with the Streamlined Arbitration Rules and Procedures of JAMS. Judgment
              upon the award so rendered may be entered in a court having jurisdiction, or application may be made to such court for
              judicial acceptance of any award and an order of enforcement, as the case may be. Notwithstanding the foregoing, you and
              ittybrief shall have the right to institute an action in a court of proper jurisdiction for injunctive or other equitable
              relief pending a final decision by the arbitrator.&nbsp; For all purposes of these Terms, you consent to exclusive
              jurisdiction and venue in the state or federal courts located in, respectively, San Francisco County, California, or the
              Northern District of California. The prevailing party in any action or proceeding arising out of these Terms will be entitled
              to an award of costs and attorneys’ fees. To the fullest extent permitted by law, you and JUKEBLOX LLC agree that all claims
              against the other can only be brought in an individual capacity, and not as a plaintiff or class member in any purported
              class, consolidated, or other representative proceeding. We agree that arbitrators may not conduct any class, consolidated, or
              representative proceeding, and are limited to providing relief warranted by an individual party's claim.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>No Third-Party Beneficiaries: We agree there are no third-party beneficiaries intended under these Terms.</p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              No Joint Venture: You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of ittybrief
              Inc., and you do not have any authority of any kind to bind us in any respect whatsoever.&nbsp;
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Waiver: The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further
              rights hereunder.&nbsp;&nbsp;
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Severability: If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or
              eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and
              enforceable.&nbsp;&nbsp;
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Entire Agreement: You agree that these Terms are the complete and exclusive statement of the mutual understanding between you
              and us, and that it supersedes and cancels all previous written and oral agreements, communications and other understandings
              relating to the subject matter of these Terms.&nbsp;&nbsp;
            </p>
          </li>
        </ul>
      </InnerContainer>
      <Footer />
    </FullContainer>
  );
};

export default TermsOfUse;
