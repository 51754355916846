import { ReactNode } from "react";
import { EmailLost, EmailSpam } from "./pages";

export interface FaqLink {
  id: string;
  page: ReactNode;
  title: string;
}

export const links: Array<FaqLink> = [
  {
    id: "email-spam",
    title: "How can I prevent my emails from landing in the spam folder?",
    page: <EmailSpam />,
  },
  {
    id: "email-lost",
    title: "I am not receiving my daily brief in my inbox? What do I do?",
    page: <EmailLost />,
  },
];
