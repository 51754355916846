import { FullContainer, Navbar, Select } from "@/components";
import { AdminPage, AdminPageMapping } from "@/types";
import { useWidth } from "@/utils";
import { Button, Colors, Tab, Tabs } from "@blueprintjs/core";
import { useLocation, useNavigate } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

const sidebarStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  padding: "1rem",
};

const contentStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  height: "100%",
  minHeight: "100vh",
  padding: "1rem",
  borderLeft: "1px solid " + Colors.LIGHT_GRAY2,
};

const AdminWrapper = ({ children }: Props) => {
  const { sm } = useWidth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const sidebarWrapperStyles: React.CSSProperties = {
    display: "flex",
    height: "100%",
    flexDirection: sm ? "column" : "row",
  };

  return (
    <FullContainer>
      <Navbar />
      <div style={sidebarWrapperStyles}>
        <div style={{ ...sidebarStyles, flex: sm ? "0 0 0%" : 1 }}>
          {!sm && (
            <Tabs
              animate={false}
              vertical
              large
              id="news-page-tabs"
              onChange={(newTabId) => navigate("/admin/" + newTabId)}
              selectedTabId={pathname.substring(pathname.indexOf("/admin/") + "/admin/".length)}
            >
              {Object.values(AdminPage).map((id) => (
                <Tab key={id} id={id} title={AdminPageMapping[id].tabLabel} />
              ))}
            </Tabs>
          )}
          {sm && (
            <Select
              onSelect={(newTabId) => navigate("/admin/" + newTabId.value)}
              items={Object.values(AdminPage).map((t) => ({ label: AdminPageMapping[t].tabLabel, value: t }))}
            >
              <Button
                text={AdminPageMapping[pathname.substring(pathname.indexOf("/admin/") + "/admin/".length) as AdminPage].tabLabel}
                outlined
                fill
                large
                rightIcon="double-caret-vertical"
              />
            </Select>
          )}
        </div>
        <div style={{ ...contentStyles, flex: sm ? "0 0 0%" : 4 }}>{children}</div>
      </div>
    </FullContainer>
  );
};

export default AdminWrapper;
