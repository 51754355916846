import { Breadcrumbs, FullContainer, InnerContainer, Navbar } from "@/components";
import { useNavigate } from "react-router-dom";
import { AddSectionView } from "@/views";
import { H3, Intent } from "@blueprintjs/core";
import { toaster } from "@/utils";
import { UilPlusCircle } from "@iconscout/react-unicons";
import { useSections } from "@/hooks/data";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "35rem",
    padding: "1rem 0 3rem",
  },
};

const AddSectionPage = () => {
  const navigate = useNavigate();

  const { addSection } = useSections();

  const onSave = async ({ originalPrompt, enhancedPrompt }) => {
    try {
      await addSection({
        originalPrompt,
        enhancedPrompt,
      });
      navigate("/my/home");
    } catch (e) {
      toaster.show({ message: e.message, intent: Intent.DANGER });
    }
  };

  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ alignItems: "center" }}>
        <div style={styles.container}>
          <Breadcrumbs
            items={[
              { text: "My Brief", href: "/my/home" },
              { text: "Add section", href: "/my/add-section", isCurrent: true },
            ]}
            style={{ marginBottom: "2rem" }}
          />
          <H3 style={{ marginBottom: 20 }}>
            <UilPlusCircle size={24} style={{ marginRight: 5, marginBottom: -4 }} />
            Add section
          </H3>
          <AddSectionView addSectionText="Add section" onAddSection={onSave} />
        </div>
      </InnerContainer>
    </FullContainer>
  );
};

export default AddSectionPage;
