import React from "react";

import { Divider, H5, Text, Colors } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { UnsubscribeToggle } from "@/components";
import { useBrief } from "@/hooks/data";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};

const UnsubscribeSection = () => {
  const { brief, changeIsSubscribed } = useBrief();

  if (!brief) return null;

  return (
    <div style={styles.container}>
      <H5 style={{ margin: 0 }}>{brief.isSubscribed ? "Unsubscribe" : "Subscribe"}</H5>
      <Divider />
      {brief.isSubscribed && (
        <Text>
          We hate to see you go :(. We encourage you to{" "}
          <Link to="/my/feedback" style={{ color: Colors.BLUE3 }}>
            share feedback here
          </Link>{" "}
          so that we may make the product more desirable in the future!
        </Text>
      )}
      {!brief.isSubscribed && (
        <Text>
          We'd love to have you back! We're constantly working to make the product amazing and keep you informed in new and interesting
          ways!
        </Text>
      )}
      <UnsubscribeToggle isSubscribed={brief.isSubscribed} changeIsSubscribed={changeIsSubscribed} />
    </div>
  );
};

export default UnsubscribeSection;
