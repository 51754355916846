import React from "react";

import { Input } from "@/components";
import { Divider, H3, H5 } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import { RootState } from "@/store/reducers";

import { UnsubscribeSection } from "./Unsubscribe";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};

const AccountSection = () => {
  const user = useSelector((state: RootState) => state.user);

  return (
    <div style={styles.container}>
      <H3 style={{ marginBottom: 30 }}>Account Settings</H3>
      <H5 style={{ margin: 0 }}>Email</H5>
      <Divider />
      <Input style={{ color: "black" }} readOnly value={user.email} />
      <div style={{ marginBottom: 40 }} />
      <UnsubscribeSection />
    </div>
  );
};

export default AccountSection;
