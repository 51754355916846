import { Input, LoadingBar, SuggestionChips } from "@/components";
import { UseInstantBrief } from "@/hooks/data";
import { useWidth } from "@/utils";
import { Button, Colors, H2, Intent } from "@blueprintjs/core";
import PromptTypeDropdown from "./PromptTypeDropdown";
import { PromptType } from "@/types";

const columnStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

const rowStyles: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  width: "100%",
};

interface Props {
  handleGetBriefedClick: (topic: string) => void;
  setPromptStateInstance: [string, React.Dispatch<React.SetStateAction<string>>];
  useInstantBriefInstance: UseInstantBrief;
}

const SkinnyForm = ({ handleGetBriefedClick, setPromptStateInstance, useInstantBriefInstance }: Props) => {
  const { loading, usePromptTypeStateInstance } = useInstantBriefInstance;
  const [prompt, setPrompt] = setPromptStateInstance;
  const [promptType] = usePromptTypeStateInstance;
  const { xs } = useWidth();

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    handleGetBriefedClick(prompt);
  };

  return (
    <form onSubmit={handleSubmit} style={{ ...columnStyles, paddingBottom: "1rem" }}>
      <div style={columnStyles}>
        <div
          style={{
            display: "flex",
            height: "100%",
            paddingBottom: "1rem",
            width: "100%",
            alignItems: "center",
            justifyContent: xs ? "center" : "flex-start",
          }}
        >
          <img
            alt="ittybrief icon"
            style={{
              height: "1.5rem",
              width: "auto",
              objectFit: "contain",
              marginRight: 10,
            }}
            src={require("@/assets/icon-tight.png")}
          />
          <H2 style={{ color: Colors.RED3, margin: 0 }}>Instant Brief</H2>
        </div>
        <div style={xs ? { ...columnStyles, gap: "0.5rem" } : { ...rowStyles, gap: loading ? "0" : "1rem" }}>
          <div style={{ width: "100%" }}>
            <Input
              rightElement={<PromptTypeDropdown useInstantBriefInstance={useInstantBriefInstance} />}
              style={{ borderRadius: 1000 }}
              maxLength={50}
              onChange={(e) => setPrompt(e.target.value)}
              large
              leftIcon="search"
              value={prompt}
              autoFocus
            />
          </div>
          <div>
            <Button
              loading={loading}
              style={{ borderRadius: 1000, width: xs ? "5rem" : "auto", display: loading ? "none" : "block", textAlign: "center" }}
              type="submit"
              large
              intent={Intent.DANGER}
            >
              Go!
            </Button>
          </div>
        </div>
        {loading && (
          <div style={{ width: "100%", marginTop: 10 }}>
            <LoadingBar seconds={promptType === PromptType.BROAD ? 8 : 4} intent={Intent.DANGER} />
          </div>
        )}
      </div>
      {!prompt && !xs && (
        <div style={{ paddingTop: "0.5rem", width: "100%" }}>
          <SuggestionChips style={{ justifyContent: "flex-start" }} intent={Intent.DANGER} onClick={handleGetBriefedClick} count={5} />
        </div>
      )}
    </form>
  );
};

export default SkinnyForm;
