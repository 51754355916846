import { clientAxiosInstance } from "@/services";
import { List } from "@/types";
import { toaster } from "@/utils";
import { useEffect, useState } from "react";

export interface UseLists {
  lists: List[];
  loading: boolean;
  createList: (name: string) => Promise<List>;
}

export const useLists = (): UseLists => {
  const [lists, setLists] = useState<List[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getLists = async () => {
    setLoading(true);
    try {
      const response = await clientAxiosInstance.get<{
        lists: List[];
      }>(`/lists`);
      setLists(response.data.lists);
    } catch (e) {
      toaster.show({ message: e.message, intent: "danger" });
    } finally {
      setLoading(false);
    }
  };

  const createList = async (name: string): Promise<List> => {
    try {
      const response = await clientAxiosInstance.post<{
        list: List;
      }>(`/lists`, { name });
      setLists([...lists, response.data.list]);
      return response.data.list;
    } catch (e) {
      toaster.show({ message: e.message, intent: "danger" });
      throw e;
    }
  };

  useEffect(() => {
    getLists();
  }, []);

  return {
    lists,
    loading,
    createList,
  };
};
