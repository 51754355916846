import React from "react";

import { Colors, Spinner } from "@blueprintjs/core";

import { Draggable, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { UilBars, UilTrashAlt } from "@iconscout/react-unicons";
import ArticleRow from "./ArticleRow";
import { UseNewsletterItems } from "@/hooks/data";
import { NewsletterItem, NewsletterItemType } from "@/types";
import HeaderRow from "./HeaderRow";

const styles: Record<string, React.CSSProperties> = {
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
  reorderBtn: {
    color: Colors.DARK_GRAY5,
    cursor: "grab",
  },
  trashBtn: {
    color: Colors.RED3,
    cursor: "pointer",
  },
};

interface Props {
  useNewsletterItemsInstance: UseNewsletterItems;
  newsletterItem: NewsletterItem;
  index: number;
}

const DraggableJSX: any = Draggable;

const NewsletterItemRow = ({ newsletterItem, index, useNewsletterItemsInstance }: Props) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { deleteItem } = useNewsletterItemsInstance;

  const onDeleteItem = async () => {
    setLoading(true);
    await deleteItem(newsletterItem.id);
    setLoading(false);
  };

  return (
    <>
      <DraggableJSX draggableId={newsletterItem.id} index={index}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <div style={{ display: "flex", alignItems: "center", gap: "1rem", background: "white" }}>
              {newsletterItem.type === NewsletterItemType.ARTICLE && <ArticleRow newsletterItem={newsletterItem} />}
              {newsletterItem.type === NewsletterItemType.HEADER && <HeaderRow newsletterItem={newsletterItem} />}
              <div style={styles.btnContainer}>
                {!loading && (
                  <>
                    <div {...provided.dragHandleProps}>
                      <UilBars size={22} style={styles.reorderBtn} />
                    </div>
                    <UilTrashAlt onClick={onDeleteItem} size={22} style={styles.trashBtn} />
                  </>
                )}
                {loading && <Spinner size={20} />}
              </div>
            </div>
          </div>
        )}
      </DraggableJSX>
    </>
  );
};

export default NewsletterItemRow;
