import { clientAxiosInstance } from "@/services";
import { Ticker } from "@/types";
import { useEffect, useState } from "react";

interface UseUserTickers {
  loading: boolean;
  userTickers: Array<Ticker>;
  insertUserTicker: (ticker: string) => Promise<Ticker>;
  removeUserTicker: (ticker: string) => Promise<void>;
}

export const useUserTickers = (): UseUserTickers => {
  const [userTickersLoading, setUserTickersLoading] = useState<boolean>(false);
  const [userTickers, setUserTickers] = useState<Array<Ticker>>([]);

  const loadUserTickers = async () => {
    try {
      setUserTickersLoading(true);
      const response = await clientAxiosInstance.get<Array<Ticker>>(`/user-ticker`);
      setUserTickers(response.data);
      return response.data;
    } finally {
      setUserTickersLoading(false);
    }
  };

  useEffect(() => {
    loadUserTickers();
  }, []);

  const insertUserTicker = async (ticker: string) => {
    const response = await clientAxiosInstance.post<Ticker>(`/user-ticker`, {
      ticker,
    });
    setUserTickers((prevUserTickers) => [...prevUserTickers, response.data]);
    return response.data;
  };

  const removeUserTicker = async (ticker: string) => {
    await clientAxiosInstance.delete<void>(`/user-ticker/${ticker}`);
    setUserTickers((prevUserTickers) => prevUserTickers.filter((userTicker) => userTicker.ticker !== ticker));
  };

  return {
    loading: userTickersLoading,
    userTickers,
    insertUserTicker,
    removeUserTicker,
  };
};
