import { Text } from "@blueprintjs/core";
import { UilArrowCircleLeft } from "@iconscout/react-unicons";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  children: ReactNode;
  style?: React.CSSProperties;
  to: string;
  icon?: ReactNode;
}

const BackButton = ({ children, to, icon = <UilArrowCircleLeft size={18} />, style = {} }: Props) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 5,
        alignItems: "center",
        width: "fit-content",
        ...style,
      }}
      onClick={() => navigate(to)}
      className="underline-hover"
    >
      {icon}
      <Text style={{ width: "fit-content" }}>{children}</Text>
    </div>
  );
};

export default BackButton;
