import { useArticlesCount } from "@/hooks/data";
import { Divider, H4, HTMLTable } from "@blueprintjs/core";
import Skeleton from "react-loading-skeleton";

const AdminArticleMetrics = () => {
  const { articlesCount, loading } = useArticlesCount();

  return (
    <>
      <H4>Count by Article Source</H4>
      <Divider style={{ margin: 0 }} />
      <HTMLTable style={{ width: "100%" }} bordered>
        <thead>
          <tr>
            <th>Source</th>
            <th>Count</th>
          </tr>
        </thead>
        {articlesCount && (
          <tbody>
            {Object.entries(articlesCount)
              .sort(([, countA], [, countB]) => countB - countA)
              .map(([source, count]) => (
                <tr key={source}>
                  <td>{source}</td>
                  <td>{count}</td>
                </tr>
              ))}
          </tbody>
        )}
        {!articlesCount && !loading && (
          <tbody>
            <tr>
              <td>No data</td>
              <td>No data</td>
            </tr>
          </tbody>
        )}
      </HTMLTable>
      {!articlesCount && loading && <Skeleton count={10} height={10} />}
    </>
  );
};

export default AdminArticleMetrics;
