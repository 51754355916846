import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState } from "@/store/reducers";

import { useWidth } from "@/utils";

import { Alignment, Navbar, Button, Divider, Drawer, DrawerSize, H4, Colors, Icon } from "@blueprintjs/core";
import InnerContainer from "../InnerContainer";
import UserDropdown from "./UserDropdown";
import { UilBars, UilEnvelopeAlt, UilEnvelopeOpen, UilNewspaper, UilUserCircle } from "@iconscout/react-unicons";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "sticky",
    top: 0,
    zIndex: 10000,
    background: "white",
  },
};

interface Props {
  style?: React.CSSProperties;
}

const StaticNavbar = ({ style = {} }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { xs, sm } = useWidth();

  const user = useSelector((state: RootState) => state.user);

  return (
    <>
      <div style={{ ...styles.container, ...style }}>
        <InnerContainer>
          <Navbar
            style={{
              boxShadow: "none",
              padding: "0.5rem 0",
              height: xs || sm ? "auto" : 60,
              background: "inherit",
              flexWrap: "nowrap",
            }}
          >
            <Navbar.Group align={Alignment.LEFT}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  height: "100%",
                }}
              >
                <Navbar.Heading
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.25rem",
                    height: "100%",
                  }}
                >
                  <img
                    alt="ittybrief icon"
                    style={{
                      height: "50%",
                      width: "auto",
                      objectFit: "contain",
                      marginRight: 10,
                    }}
                    src={require("@/assets/icon-tight.png")}
                  />
                  <span>ittybrief</span>
                </Navbar.Heading>
              </Link>
              {!sm && <Navbar.Divider />}
              {!sm && (
                <Link to="/instant">
                  <Button
                    style={{ marginLeft: 10, marginRight: 10, background: Colors.RED3, color: "white" }}
                    icon={<Icon icon="clean" color="white" />}
                    text="Instant Brief"
                  />
                </Link>
              )}
              {!sm && user && (
                <>
                  <Link to="/my/home">
                    <Button minimal icon={<UilEnvelopeAlt size={18} />} text="My Brief" />
                  </Link>
                  <Link to="/my/brief">
                    <Button minimal icon={<UilEnvelopeOpen size={18} />} text="Today" />
                  </Link>
                  <Link to="/my/news">
                    <Button minimal icon={<UilNewspaper size={18} />} text="Feed" />
                  </Link>
                </>
              )}
            </Navbar.Group>
            <Navbar.Group style={{ gap: "0.25rem" }} align={Alignment.RIGHT}>
              {!user && (
                <>
                  <Link to="/login">
                    <Button className="bp4-minimal" icon={<UilUserCircle size={18} />} text="Login" />
                  </Link>
                </>
              )}
              {user && <UserDropdown />}
              {sm && (
                <>
                  <Button minimal icon={<UilBars size={18} />} onClick={() => setIsOpen(!isOpen)} />
                </>
              )}
            </Navbar.Group>
          </Navbar>
        </InnerContainer>
        <Divider style={{ margin: 0, width: "100%" }} />
      </div>
      <Drawer
        size={xs ? DrawerSize.LARGE : DrawerSize.STANDARD}
        onClose={() => setIsOpen(false)}
        style={{ zIndex: 1000000 }}
        position="right"
        isOpen={isOpen && (xs || sm)}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", paddingBottom: 5 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.25rem",
              height: "100%",
              padding: "1rem 0",
            }}
          >
            <img
              alt="ittybrief icon"
              style={{
                height: "1.5rem",
                width: "auto",
                objectFit: "contain",
                marginRight: 10,
              }}
              src={require("@/assets/icon-tight.png")}
            />
            <H4 style={{ margin: 0 }}>ittybrief</H4>
          </div>
          <Divider style={{ marginTop: 0, width: "100%" }} />
          {user && (
            <>
              <Link to="/my/home">
                <Button minimal icon={<UilEnvelopeAlt size={18} />} text="My Brief" />
              </Link>
              <Divider style={{ width: "100%" }} />
              <Link to="/my/brief">
                <Button fill minimal icon={<UilEnvelopeOpen size={18} />} text="Today" />
              </Link>
              <Divider style={{ width: "100%" }} />
              <Link to="/my/news">
                <Button fill minimal icon={<UilNewspaper size={18} />} text="Feed" />
              </Link>
              <Divider style={{ width: "100%" }} />
            </>
          )}
          <Link to="/instant" style={{ width: "100%", padding: "0.75rem 1rem", textDecoration: "none" }}>
            <Button
              large
              style={{ background: Colors.RED3, color: "white" }}
              fill
              minimal
              icon={<Icon icon="clean" color="white" />}
              text="Instant Brief"
            />
          </Link>
        </div>
      </Drawer>
    </>
  );
};

export default StaticNavbar;
