import { Footer, FullContainer, InnerContainer, LogoutNavbar, UnsubscribeToggle } from "@/components";
import { UseBrief } from "@/hooks/data";
import { Brief } from "@/types";
import { Divider, H3, Text } from "@blueprintjs/core";

const styles: Record<string, React.CSSProperties> = {
  container: {
    maxWidth: "35rem",
    paddingTop: "2rem",
    minHeight: "100vh",
  },
};

interface Props {
  brief: Brief;
  changeIsSubscribed: UseBrief["changeIsSubscribed"];
}

const ResubscribePage = ({ brief, changeIsSubscribed }: Props) => {
  return (
    <FullContainer>
      <LogoutNavbar />
      <InnerContainer style={styles.container}>
        <H3>Resubscribe Required</H3>
        <Divider style={{ marginTop: 0, marginBottom: 10 }} />
        <Text>In order to access the web platform, you must be a subscriber. We hope you consider coming back! &#128513;</Text>
        <UnsubscribeToggle isSubscribed={brief?.isSubscribed} changeIsSubscribed={changeIsSubscribed} />
      </InnerContainer>
      <Footer />
    </FullContainer>
  );
};

export default ResubscribePage;
