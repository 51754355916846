import { InnerContainer, FullScreenSpinner, Select } from "@/components";
import { useSearchParamsState } from "@/hooks";
import { useUserTickers } from "@/hooks/data";
import { SearchParamKey } from "@/types";
import { useWidth } from "@/utils";
import { Button, Colors, Intent, Tab, Tabs, Tag, Text } from "@blueprintjs/core";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MarketNewsSection from "./MarketNewsSection";
import { UilPlusCircle } from "@iconscout/react-unicons";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    gap: 20,
    width: "100%",
  },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    flex: 1,
    flexBasis: 0,
    flexGrow: 1,
    minWidth: 0,
  },
  borderLeftStyle: {
    borderLeft: `1px solid ${Colors.LIGHT_GRAY2}`,
    paddingLeft: 20,
  },
};

const MarketNewsPage = () => {
  const [selectedTabId, setSelectedTabId] = useSearchParamsState<string>(SearchParamKey.MARKET_NEWS_TAB);
  const { xs } = useWidth();
  const navigate = useNavigate();

  const { userTickers, loading } = useUserTickers();

  useEffect(() => {
    if (!selectedTabId || !userTickers.map((t) => t.ticker).includes(selectedTabId)) {
      setSelectedTabId(userTickers[0]?.ticker);
    }
  }, [userTickers]);

  const selectedTicker = userTickers.find((t) => t.ticker === selectedTabId);

  return (
    <InnerContainer style={{ paddingTop: "1rem", paddingBottom: "10rem" }}>
      {loading && <FullScreenSpinner />}
      <div style={styles.container}>
        {!Boolean(userTickers.length) && !loading && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text style={{ marginBottom: 5 }}>No market data sections.</Text>
            <Link to="/my/stocks">
              <Button intent={Intent.PRIMARY} icon={<UilPlusCircle size={18} />}>
                Add market data
              </Button>
            </Link>
          </div>
        )}
        {Boolean(userTickers.length) && (
          <div style={{ display: "flex", flexDirection: xs ? "column" : "row", width: "100%", gap: 20 }}>
            <div className="fade-in" style={{ ...styles.column, flexDirection: xs ? "column-reverse" : "column" }}>
              <div style={{ width: "100%" }}>
                {xs && (
                  <Select
                    onSelect={(newTabId) => setSelectedTabId(newTabId.value)}
                    items={userTickers.map((t) => ({ label: `(${t.ticker}) ${t.name}`, value: t.ticker }))}
                  >
                    <Button
                      text={`(${selectedTabId}) ${userTickers.find((ut) => ut.ticker === selectedTabId)?.name}`}
                      outlined
                      fill
                      large
                      rightIcon="double-caret-vertical"
                    />
                  </Select>
                )}
                {!xs && (
                  <Tabs
                    animate={false}
                    onChange={(newTabId) => setSelectedTabId(newTabId as string)}
                    id="market-news-tabs"
                    large
                    vertical
                    selectedTabId={selectedTabId}
                  >
                    {userTickers.map((t) => (
                      <Tab key={t.ticker} id={t.ticker}>
                        <Tag minimal style={{ marginRight: 10, background: t.ticker === selectedTabId ? Colors.LIGHT_GRAY5 : "" }}>
                          {t.ticker}
                        </Tag>
                        {t.name}
                      </Tab>
                    ))}
                  </Tabs>
                )}
              </div>
              <Button large onClick={() => navigate("/my/stocks")} intent={Intent.PRIMARY} outlined icon={<UilPlusCircle size={18} />}>
                Add market data
              </Button>
            </div>
            {selectedTicker && <MarketNewsSection ticker={selectedTicker} />}
          </div>
        )}
      </div>
    </InnerContainer>
  );
};

export default MarketNewsPage;
