import { DisplaySectionWithArticles } from "@/types";
import Section from "./Section";

interface Props {
  sections: Array<DisplaySectionWithArticles>;
}

const Sections = ({ sections }: Props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "4rem" }}>
      {sections.map((section) => (
        <Section key={section.id} section={section} />
      ))}
    </div>
  );
};

export default Sections;
