import { DisplaySectionWithArticles, SectionWithArticles } from "./Section";
import { TickerWithArticles } from "./Ticker";

export enum BriefSendStatus {
  PENDING = "PENDING",
  SENT = "SENT",
  FAILED = "FAILED",
}

export interface BriefSend {
  id: string;
  created: string;
  userId: string;
  subject: string | null;
  status: BriefSendStatus;
}

export interface BriefSendWithContent extends BriefSend {
  sections: Array<SectionWithArticles>;
  tickers: Array<TickerWithArticles>;
}

export type BriefSendFailureMetric = {
  day: string;
  numSent: number;
  numFailed: number;
  failurePercentage: number;
};

export interface InstantBriefSend {
  subject: string | null;
  sections: Array<DisplaySectionWithArticles>;
  tickers: Array<TickerWithArticles>;
}
