import { FullContainer, InnerContainer } from "@/components";
import { RootState } from "@/store/reducers";
import { SET_USER } from "@/store/types";
import { toaster, useWidth } from "@/utils";
import { Button, Card, H3, Text, Intent } from "@blueprintjs/core";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getUser } from "@/router/getUser";
import { resendEmailConfirmation } from "./resendEmailConfirmation";
import { UilSync } from "@iconscout/react-unicons";

interface Props {
  user: RootState["user"];
}

const styles: Record<string, React.CSSProperties> = {
  card: {
    padding: 20,
    maxWidth: "25rem",
    width: "min-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
  },
  img: {
    height: 150,
    width: 100,
    objectFit: "contain",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    minWidth: 200,
  },
};

const tryToGetUser = async (onSuccess: Dispatch<AnyAction>, onFail: () => void) => {
  try {
    const fetchedUser = await getUser();
    onSuccess({ type: SET_USER, payload: fetchedUser });
    return fetchedUser;
  } catch (e) {
    onFail();
  }
};

const ConfirmEmail = ({ user }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const { xs } = useWidth();
  const dispatch = useDispatch();

  const onRefresh = async () => {
    setLoadingUser(true);
    const fetchedUser = await tryToGetUser(dispatch, () => {});
    if (!fetchedUser.emailConfirmed)
      toaster.show({
        message: "Email not confirmed.",
        intent: Intent.WARNING,
      });
    setLoadingUser(false);
  };

  const onResend = async () => {
    try {
      setLoading(true);
      await resendEmailConfirmation();
      setSent(true);
      toaster.show({ message: "Email sent.", intent: Intent.SUCCESS });
    } catch (e) {
      toaster.show({
        message: e?.message || "Error resending verification email. Contact jack@ittybrief.com.",
        intent: Intent.DANGER,
      });
    }
    setLoading(false);
  };

  return (
    <FullContainer>
      <InnerContainer
        style={{
          alignItems: "center",
          justifyContent: "center",
          height: "90vh",
        }}
      >
        <Card style={{ ...styles.card, flexDirection: xs ? "column" : "row" }}>
          <img alt="Ittybrief logo" style={styles.img} src={require("@/assets/icon-tight.png")} />
          <form style={styles.form}>
            <H3>Verify your email</H3>
            <Text>
              You should have received a confirmation email at <b>{user.email}</b>.
            </Text>
            <div
              style={{
                display: "flex",
                gap: 10,
                marginTop: 10,
                justifyContent: "center",
              }}
            >
              <Button
                loading={loading}
                disabled={sent}
                outlined={sent}
                intent={sent ? Intent.SUCCESS : Intent.NONE}
                onClick={onResend}
                rightIcon={sent ? "tick" : "send-message"}
              >
                {sent ? "Sent" : "Resend"}
              </Button>
              <Button
                style={{ whiteSpace: "nowrap" }}
                onClick={onRefresh}
                rightIcon={<UilSync size={18} />}
                intent={Intent.PRIMARY}
                loading={loadingUser}
              >
                I did
              </Button>
            </div>
          </form>
        </Card>
      </InnerContainer>
    </FullContainer>
  );
};

export default ConfirmEmail;
