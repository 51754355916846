import { FullContainer, InnerContainer, Navbar } from "@/components";
import { Button, Divider, Drawer, DrawerSize, H2 } from "@blueprintjs/core";
import { useLists } from "./useLists";
import { useState } from "react";
import AddListForm from "./AddListForm";
import ListTable from "./ListTable";
import { useWidth } from "@/utils";

function ListsPage() {
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);
  const { xs } = useWidth();

  const useListsInstance = useLists();

  return (
    <>
      <FullContainer>
        <Navbar />
        <InnerContainer style={{ paddingTop: "3rem" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
            <H2 style={{ margin: 0 }}>Email Lists</H2>
            <div>
              <Button onClick={() => setIsAddFormOpen(true)} icon="add">
                Add
              </Button>
            </div>
          </div>
          <Divider />
          <ListTable useListsInstance={useListsInstance} />
        </InnerContainer>
      </FullContainer>
      <Drawer
        size={xs ? DrawerSize.LARGE : DrawerSize.STANDARD}
        position="right"
        isOpen={isAddFormOpen}
        onClose={() => setIsAddFormOpen(false)}
        style={{ zIndex: 1000000 }}
      >
        <AddListForm handleClose={() => setIsAddFormOpen(false)} useListsInstance={useListsInstance} />
      </Drawer>
    </>
  );
}

export default ListsPage;
