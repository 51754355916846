import React, { useEffect, useState } from "react";

import { FullContainer, Navbar, Footer, InnerContainer } from "@/components";
import { useWidth } from "@/utils";
import { FaqLink, links } from "./links";
import { Link, useSearchParams } from "react-router-dom";
import { Colors, H2, Text } from "@blueprintjs/core";
// import { QuestionMarkContainer } from "./QuestionMarkContainer";

const styles: Record<string, React.CSSProperties> = {
  outer: {
    minHeight: "100vh",
    paddingBottom: "10rem",
  },
  splitContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "3%",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "2rem 0",
  },
};

const FaqPage = () => {
  const [selectedLink, setSelectedLink] = useState<FaqLink>();
  const [query, setSearchParams] = useSearchParams();
  const { sm } = useWidth();

  useEffect(() => {
    const link = links.find((l) => l.id === query.get("page"));
    if (!link) {
      query.delete("page");
      setSearchParams(query);
    }
    setSelectedLink(link);
  }, [query]);

  return (
    <FullContainer>
      <Navbar />
      <div style={styles.outer}>
        {/* <QuestionMarkContainer /> */}

        <InnerContainer style={styles.splitContainer}>
          {selectedLink && <div style={{ ...styles.container, width: sm ? "100%" : "57%" }}>{selectedLink.page}</div>}
          <div style={{ ...styles.container, width: sm ? "100%" : "40%", gap: 10 }}>
            <H2>FAQs</H2>
            {links.map((link) => (
              <Link to={`/faq?page=${link.id}`} style={{ color: Colors.BLUE3 }}>
                <Text style={{ fontSize: 16 }}>• {link.title}</Text>
              </Link>
            ))}
          </div>
        </InnerContainer>
      </div>
      <Footer />
    </FullContainer>
  );
};

export default FaqPage;
