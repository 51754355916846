import { useState, useEffect } from "react";

export function useLocalStorageState<ValueType>(key: string, defaultValue: ValueType = null) {
  const [value, setValue] = useState(() => {
    try {
      const storedValue = typeof window !== "undefined" ? localStorage.getItem(key) : null;
      return storedValue === null ? defaultValue : JSON.parse(storedValue) || undefined;
    } catch (e) {
      localStorage.removeItem(key);
      return undefined;
    }
  });

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      if (typeof window !== "undefined" && e.storageArea === localStorage && e.key === key) {
        setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
      }
    };
    window.addEventListener("storage", listener);

    return () => {
      window.removeEventListener("storage", listener);
    };
  }, [key, defaultValue]);

  const setValueInLocalStorage = (newValue: ValueType) => {
    setValue((currentValue: any) => {
      const result = typeof newValue === "function" ? newValue(currentValue) : newValue;
      if (typeof window !== "undefined") localStorage.setItem(key, JSON.stringify(result));
      return result;
    });
  };

  return [value, setValueInLocalStorage];
}

export function useLocalStorage<ValueType>() {
  const getValue = (key: string): ValueType => {
    const storedValue = typeof window !== "undefined" ? localStorage.getItem(key) : null;
    return storedValue === null ? undefined : JSON.parse(storedValue);
  };

  const setValue = (key: string, newValue: ValueType): void => {
    if (newValue) {
      localStorage.setItem(key, JSON.stringify(newValue));
    } else {
      localStorage.removeItem(key);
    }
  };

  return { getValue, setValue };
}
