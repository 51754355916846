import { useWidth } from "@/utils";
import { Colors, Text } from "@blueprintjs/core";
import { AdminCuratedBriefSidebar } from "./AdminCuratedBriefSidebar";
import { AdminCuratedBriefContent } from "./AdminCuratedBriefContent";
import { FullContainer, Navbar } from "@/components";
import { useNewsletterItems, useNewsletterSend } from "@/hooks/data";
import { useParams } from "react-router-dom";

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexWrap: "nowrap",
  gap: "1rem",
  width: "100%",
  padding: "1rem",
};

const contentContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "66%",
  height: "calc(100vh - 5rem)",
  overflowY: "scroll",
  paddingBottom: "10rem",
};

const sidebarContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  height: "calc(100vh - 5rem)",
  overflowY: "scroll",
  width: "33%",
  borderLeft: "1px solid " + Colors.LIGHT_GRAY2,
};

const AdminCuratedBriefPage = () => {
  const { sm } = useWidth();
  const { newsletterId, newsletterSendId } = useParams();

  const useNewsletterSendInstance = useNewsletterSend(newsletterId, newsletterSendId);
  const useNewsletterItemsInstance = useNewsletterItems(newsletterId, newsletterSendId);

  if (sm)
    return (
      <Text>
        <i>Mobile not supported</i>
      </Text>
    );

  return (
    <FullContainer>
      <Navbar />
      <div style={containerStyle}>
        <div style={contentContainerStyle}>
          <AdminCuratedBriefContent
            useNewsletterSendInstance={useNewsletterSendInstance}
            useNewsletterItemsInstance={useNewsletterItemsInstance}
          />
        </div>
        <div style={sidebarContainerStyle}>
          <AdminCuratedBriefSidebar
            useNewsletterSendInstance={useNewsletterSendInstance}
            useNewsletterItemsInstance={useNewsletterItemsInstance}
          />
        </div>
      </div>
    </FullContainer>
  );
};

export default AdminCuratedBriefPage;
