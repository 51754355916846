import { ArticleRow, UserTickerTag } from "@/components";
import { useStock } from "@/hooks/data";
import { Article, Ticker } from "@/types";
import { useWidth } from "@/utils";
import { Colors, Divider, H2, Text } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const styles: Record<string, React.CSSProperties> = {
  container: { display: "flex", flexDirection: "column" },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    flex: 1,
    flexBasis: 0,
    flexGrow: 1,
    minWidth: 0,
  },
  borderLeftStyle: {
    borderLeft: `1px solid ${Colors.LIGHT_GRAY2}`,
    paddingLeft: 20,
  },
  headerContainer: {
    display: "flex",
    gap: 10,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },
};

interface Props {
  ticker: Ticker;
}

const MarketNewsSection = ({ ticker }: Props) => {
  const { xs, sm } = useWidth();
  const [loading, setLoading] = useState<boolean>(false);
  const [news, setNews] = useState<Array<Article>>([]);
  const [hasFetched, setHasFetched] = useState<boolean>(false);

  const { getStockNews } = useStock();

  const loadNews = async () => {
    try {
      setLoading(true);
      setNews([]);
      setHasFetched(false);
      const n = await getStockNews(ticker.ticker);
      setHasFetched(true);
      setNews(n.slice(0, 10));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ticker && ticker?.description) loadNews();
  }, [ticker?.ticker]);

  const Header = () => (
    <div style={styles.headerContainer}>
      <H2 style={{ margin: 0 }}>{ticker.name}</H2>
      <Link style={{ textDecoration: "none" }} to={`/my/stocks/${ticker.ticker}`}>
        <UserTickerTag ticker={ticker} />
      </Link>
    </div>
  );

  if (!ticker?.description)
    return (
      <div style={{ ...styles.container, ...(!xs && styles.borderLeftStyle), flex: 2 }}>
        <Text>
          <i>No related news found.</i>
        </Text>
      </div>
    );

  const divide = sm ? 1 : 2;

  return (
    <div style={{ ...styles.container, ...(!xs && styles.borderLeftStyle), flex: sm ? 1 : 2 }}>
      <Header />
      <Divider style={{ marginBottom: 20 }} />
      <div style={{ display: "flex", gap: 20 }}>
        {hasFetched && !Boolean(news.length) && (
          <Text>
            <i>No related news found.</i>
          </Text>
        )}
        {Array.from({ length: divide }).map((_outer, i) => (
          <div
            key={i}
            className="fade-in"
            style={{
              ...styles.column,
              ...(i !== 0 && styles.borderLeftStyle),
            }}
          >
            {loading && !hasFetched && (
              <Skeleton
                wrapper={({ children }) => <div style={{ display: "flex", flexDirection: "column" }}>{children}</div>}
                height={100}
                width="100%"
                count={5}
              />
            )}
            {news
              .filter((_inner, i2) => (i2 + 1) % divide === i)
              .map((article, i2) => (
                <React.Fragment key={article.id}>
                  <ArticleRow
                    hideImage={(i2 % 3) - i !== 0}
                    orientation={(i2 % 3) - i === 0 ? "vertical" : "horizontal"}
                    article={article}
                  />
                  <Divider style={{ width: "100%", marginBottom: 10 }} />
                </React.Fragment>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MarketNewsSection;
