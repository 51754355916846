import { SkeletonArticleRow } from "@/components";
import { useWidth } from "@/utils";
import { Colors } from "@blueprintjs/core";
import React from "react";

const styles: Record<string, React.CSSProperties> = {
  container: {
    width: "100%",
    position: "relative",
    height: "30rem",
    overflow: "hidden",
  },
  headerImg: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "4rem",
  },
  headerImgBackground: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 10,
    height: 10,
    boxShadow: `0 0 50px 40px ${Colors.BLUE1}`,
  },
  articleContainer: {
    width: "120%",
    position: "absolute",
    left: "-10%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    gap: 10,
    opacity: 0.87,
    pointerEvents: "none",
  },
  articleColumn: {
    width: "calc(40% - 6.67px)",
    textAlign: "left",
    color: "black",
  },
  articleRow: {
    padding: 10,
    borderRadius: 4,
  },
};

const ScrollingArticles = () => {
  const { xs, md } = useWidth();

  const articleColumnWidth = xs ? "80%" : md ? "calc(50% - 15px)" : "calc(40% - 6.67px)";

  return (
    <div style={styles.container}>
      <div style={styles.articleContainer}>
        {Array.from({ length: xs ? 1 : md ? 2 : 3 }).map((s, i) => {
          const randomArray = Array.from({
            length: xs ? 10 : i % 2 ? 10 : 20 + i * 3,
          }).map((val) => Math.random() * 1.75);
          return (
            <div key={`scroll-${i}`} className="scroll" style={{ ...styles.articleColumn, width: articleColumnWidth }}>
              <div key={`scroll-${i}-1`} className="scroll-article">
                {randomArray
                  .map((val) => val * (i % 2 ? 1 : 1))
                  .map((i2) => (
                    <SkeletonArticleRow random={i2} style={styles.articleRow} key={`${i}-${i2}`} />
                  ))}
              </div>
              <div key={`scroll-${i}-2`} className="scroll-article">
                {randomArray
                  .map((val) => val * (i % 2 ? 1 : 1))
                  .map((i2) => (
                    <SkeletonArticleRow random={i2} style={styles.articleRow} key={`${i}-${i2}-2`} />
                  ))}
              </div>
            </div>
          );
        })}
      </div>
      <div style={styles.headerImgBackground} />
      <img alt="ittybrief example" style={styles.headerImg} src={require("@/assets/icon-tight.png")} />
      <div className="scroll-gradient-fade-bottom" />
      <div className="scroll-gradient-fade-left" />
      <div className="scroll-gradient-fade-right" />
    </div>
  );
};

export default ScrollingArticles;
