import {
  AdminArticleMetrics,
  AdminBriefSendFailureMetrics,
  AdminEmailInteractionMetrics,
  AdminLinkClicksMetrics,
  AdminUserSignupMetrics,
} from "@/views";
import { Card } from "@blueprintjs/core";

const containerStyle: React.CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
  alignItems: "stretch",
};

const CardContainer = ({ children, isDouble = false }: { children: React.ReactNode; isDouble?: boolean }) => {
  return <Card style={{ width: isDouble ? "61rem" : "30rem", maxWidth: "100%", height: "30rem", overflow: "auto" }}>{children}</Card>;
};

const AdminMetricsPage = () => {
  return (
    <div style={{ paddingBottom: "10rem" }}>
      <div style={containerStyle}>
        <CardContainer>
          <AdminArticleMetrics />
        </CardContainer>
        <CardContainer>
          <AdminLinkClicksMetrics />
        </CardContainer>
        <CardContainer>
          <AdminUserSignupMetrics />
        </CardContainer>
        <CardContainer>
          <AdminBriefSendFailureMetrics />
        </CardContainer>
        <CardContainer isDouble>
          <AdminEmailInteractionMetrics />
        </CardContainer>
      </div>
    </div>
  );
};

export default AdminMetricsPage;
