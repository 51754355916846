import { clientAxiosInstance } from "@/services";
import { InstantBriefSend } from "@/types";
import { toaster } from "@/utils";
import { useState } from "react";

interface UseAdminManualTest {
  briefSend: InstantBriefSend | undefined;
  loading: boolean;
  fetchBriefSend: (prompt: string) => Promise<void>;
}

export const useAdminManualTest = (): UseAdminManualTest => {
  const [briefSend, setBriefSend] = useState<InstantBriefSend | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchBriefSend = async (prompt: string) => {
    setLoading(true);
    try {
      const response = await clientAxiosInstance.post<InstantBriefSend>(`/admin/manual-test`, { prompt });
      setBriefSend(response.data);
    } catch (e) {
      toaster.show({ message: e.message, intent: "danger" });
    } finally {
      setLoading(false);
    }
  };

  return {
    briefSend,
    loading,
    fetchBriefSend,
  };
};
