import { useEffect, useState } from "react";

import { MultilineInput, SuggestionChips } from "@/components";
import { Button, Intent, H4, UL } from "@blueprintjs/core";
import { toaster } from "@/utils";
import { getSectionArticles } from "./getSectionArticles";
import { SearchParamKey } from "@/types";
import { useSearchParams } from "react-router-dom";
import { UilArrowCircleRight, UilCheckCircle, UilX } from "@iconscout/react-unicons";
import { LoadingBar } from "@/components/base";

interface AddSectionInput {
  originalPrompt: string;
  enhancedPrompt: string;
  displayTopic: string;
}

interface Props {
  onAddSection: (input: AddSectionInput) => Promise<void>;
  addSectionText: string;
}

const AddSectionView = ({ onAddSection, addSectionText }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("");
  const [topic, setTopic] = useState<string>();
  const [displayTopic, setDisplayTopic] = useState<string>();

  const resetForm = () => {
    setTopic(undefined);
    setDisplayTopic(undefined);
  };

  const scrollIntoView = (id: string) => {
    let elem = document.getElementById(id);
    if (elem) elem.scrollIntoView({ behavior: "smooth" });
  };

  const handleGetTopic = async (p: string) => {
    try {
      setLoading(true);
      const { topic: initialTopic, displayTopic: initialDisplayTopic } = await getSectionArticles(p);
      setTopic(initialTopic);
      setDisplayTopic(initialDisplayTopic);
      setTimeout(() => {
        scrollIntoView("topic-interested-section");
      }, 100);
    } catch (e) {
      console.log(e);
      toaster.show({
        message: e?.message || "Try another prompt.",
        intent: Intent.DANGER,
      });
      resetForm();
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    handleGetTopic(prompt);
  };

  const handleAddSection = async () => {
    setLoading(true);
    await onAddSection({ originalPrompt: prompt, enhancedPrompt: topic, displayTopic });
    setLoading(false);
  };

  useEffect(() => {
    if (topic) resetForm();
  }, [prompt]);

  useEffect(() => {
    if (searchParams.has(SearchParamKey.INITIAL_PROMPT)) {
      const initialPrompt = searchParams.get(SearchParamKey.INITIAL_PROMPT);
      handleGetTopic(initialPrompt);
      setPrompt(initialPrompt);

      window.gtag("event", "conversion", { send_to: "AW-337002105/O_T1CIr6oc4YEPn82KAB" });

      searchParams.delete(SearchParamKey.INITIAL_PROMPT);
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
      <form onSubmit={onSubmit}>
        <MultilineInput
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          style={{ width: "100%" }}
          label="Describe your area of interest"
          maxLength={50}
          placeholder="ex: The global coffee trade..."
        />
        {!!!prompt.length && (
          <SuggestionChips
            style={{ marginTop: 10, justifyContent: "flex-start" }}
            count={9}
            onClick={(t: string) => {
              setPrompt(t);
              handleGetTopic(t);
            }}
          />
        )}

        {!topic && !!prompt.length && (
          <>
            {loading && <LoadingBar style={{ marginTop: 10 }} seconds={11} />}
            <div style={{ marginTop: 10 }}>
              <Button
                disabled={!prompt}
                type="submit"
                rightIcon={<UilArrowCircleRight size={18} />}
                intent={Intent.SUCCESS}
                loading={loading}
                large
              >
                Next
              </Button>
            </div>
          </>
        )}
      </form>
      {topic && (
        <div>
          <H4 id="topic-interested-section" style={{ marginTop: 20 }}>
            <span style={{ fontWeight: 400 }}>Are you interested in the following topics surrounding</span> {prompt}?
          </H4>
          <UL>
            {displayTopic
              .split("\n")
              .slice(0, 5)
              .map((t) => {
                const parts = t.split(":", 2);
                if (parts.length === 2) {
                  return (
                    <li key={t}>
                      <u>{parts[0]}:</u> {parts[1]}
                    </li>
                  );
                }
                return <li key={t}>{t}</li>;
              })}
          </UL>
        </div>
      )}
      {topic && (
        <div style={{ display: "flex", gap: 10 }}>
          <Button
            onClick={() => {
              resetForm();
              setPrompt("");
            }}
            rightIcon={<UilX size={18} />}
            intent={Intent.NONE}
            large
            style={{ whiteSpace: "nowrap", flexShrink: 0 }}
          >
            Cancel
          </Button>
          <Button loading={loading} onClick={handleAddSection} rightIcon={<UilCheckCircle size={18} />} intent={Intent.SUCCESS} large fill>
            {addSectionText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AddSectionView;
