import { clientAxiosInstance } from "@/services";
import { BriefSendWithContent, SearchParamKey } from "@/types";
import { toaster } from "@/utils";
import { Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { useSearchParamsState } from "../useSearchParamsState";

interface UseBriefSend {
  loading: boolean;
  briefSend: BriefSendWithContent | undefined;
}

export const useBriefSend = (): UseBriefSend => {
  const [loading, setLoading] = useState<boolean>(true);
  const [briefSendId, setBriefSendId] = useSearchParamsState<string>(SearchParamKey.BRIEF_SEND_ID);
  const [briefSend, setBriefSend] = useState<BriefSendWithContent>();

  const getBriefSendById = async () => {
    setLoading(true);
    try {
      const b = await clientAxiosInstance.get<BriefSendWithContent>(`/sent-briefs/${briefSendId}`);
      setBriefSend(b.data);
      setBriefSendId(b.data.id);
    } catch (e) {
      toaster.show({ message: "Brief not found.", intent: Intent.DANGER });
    } finally {
      setLoading(false);
    }
  };

  const getRecentBriefSend = async () => {
    setLoading(true);
    try {
      const b = await clientAxiosInstance.get<BriefSendWithContent>("/sent-briefs/recent");
      setBriefSend(b.data);
      setBriefSendId(b.data.id);
    } catch (e) {
      toaster.show({ message: "Brief not found.", intent: Intent.DANGER });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!briefSendId) {
      getRecentBriefSend();
    } else {
      getBriefSendById();
    }
  }, []);

  return {
    loading,
    briefSend,
  };
};
