import { InnerContainer } from "@/components";
import { useInstantBrief } from "@/hooks/data";
import { toaster, useWidth } from "@/utils";
import { Intent } from "@blueprintjs/core";
import { v4 as uuid } from "uuid";
import React, { useEffect, useMemo, useState } from "react";

import LandingForm from "./LandingForm";
import SkinnyForm from "./SkinnyForm";
import CallToAction from "./CallToAction";
import { PromptType } from "@/types";
import { BriefDisplayView } from "@/views";

const containerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  gap: "2rem",
  position: "relative",
};

const innerContainerStyle: React.CSSProperties = {
  flexDirection: "column",
  gap: "2rem",
  justifyContent: "center",
  alignItems: "center",
};

const GenerateBrief = () => {
  const setPromptStateInstance = useState<string>("");
  const [prompt, setPrompt] = setPromptStateInstance;
  const [renderedPrompt, setRenderedPrompt] = useState<string>("");
  const [renderedPromptType, setRenderedPromptType] = useState<PromptType>();
  const { xs, sm } = useWidth();

  const useInstantBriefInstance = useInstantBrief();
  const { loadInstantBrief, loading, sections, error, usePromptTypeStateInstance, subject } = useInstantBriefInstance;
  const [promptType] = usePromptTypeStateInstance;

  const resetForm = () => {
    setPrompt("");
  };

  const handleGetBriefedClick = (topic: string) => {
    if (loading) return;
    if (!topic) return toaster.show({ message: "Please enter a topic.", intent: Intent.DANGER });
    setPrompt(topic);
    loadInstantBrief(topic);
  };

  useEffect(() => {
    if (error) {
      toaster.show({
        message: error,
        intent: Intent.DANGER,
      });
      resetForm();
    }
  }, [error]);

  useEffect(() => {
    setRenderedPrompt(prompt);
    setRenderedPromptType(promptType);
  }, [sections]);

  const sectionsWithTitle = useMemo(
    () =>
      sections.map((s) => ({
        ...s,
        id: uuid(),
        originalPrompt: renderedPromptType === PromptType.NARROW ? renderedPrompt : s.originalPrompt,
      })),
    [sections, renderedPromptType]
  );

  return (
    <div style={{ ...containerStyle }}>
      {!!!sections.length && (
        <div style={{ width: "100%", padding: xs ? "3rem 0" : sm ? "5rem 0" : "8rem 1rem" }}>
          <InnerContainer style={{ ...innerContainerStyle, maxWidth: xs ? "90vw" : "35rem" }}>
            <LandingForm
              handleGetBriefedClick={handleGetBriefedClick}
              useInstantBriefInstance={useInstantBriefInstance}
              setPromptStateInstance={setPromptStateInstance}
            />
          </InnerContainer>
        </div>
      )}
      {!!sections.length && (
        <div style={{ width: "100%", padding: xs ? "2rem 0" : sm ? "3rem 0" : "4rem 1rem" }}>
          <InnerContainer style={{ ...innerContainerStyle, maxWidth: xs ? "90vw" : "35rem" }}>
            <SkinnyForm
              handleGetBriefedClick={handleGetBriefedClick}
              useInstantBriefInstance={useInstantBriefInstance}
              setPromptStateInstance={setPromptStateInstance}
            />
            {renderedPrompt === prompt && (
              <CallToAction
                originalPrompt={prompt}
                enhancedPrompt={sections.reduce((acc, section) => acc + "\n" + section.enhancedPrompt, "")}
              />
            )}
            <BriefDisplayView
              briefSend={{
                subject,
                tickers: [],
                sections: sectionsWithTitle,
              }}
              loading={false}
              style={{ paddingTop: xs ? "1rem" : "2rem" }}
            />
          </InnerContainer>
        </div>
      )}
    </div>
  );
};

export default GenerateBrief;
