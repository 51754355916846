import { HotkeysProvider } from "@blueprintjs/core";
import { Provider } from "react-redux";

import "./index.css";
import "./animate.css";

import Router from "./router";

import store from "@/store";
import { BrowserRouter } from "react-router-dom";

const App = () => {
  return (
    <Provider store={store}>
      <HotkeysProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </HotkeysProvider>
    </Provider>
  );
};

export default App;
