import React from "react";

import { InputGroup, InputGroupProps2 } from "@blueprintjs/core";

const normalBoxShadow =
  "0 0 0 0 rgb(45 114 210 / 0%), 0 0 0 0 rgb(45 114 210 / 0%), inset 0 0 0 1px rgb(17 20 24 / 20%), inset 0 1px 1px rgb(17 20 24 / 50%)";

interface Props extends InputGroupProps2 {
  value: any;
  style?: React.CSSProperties;
}

const Input = ({ value, style = {}, ...props }: Props) => {
  return <InputGroup style={{ boxShadow: props?.readOnly ? normalBoxShadow : "", ...style }} value={value} {...props} />;
};

export default Input;
