import { ReactNode } from "react";

import { ItemRendererProps, Select2 } from "@blueprintjs/select";
import { Intent, MenuItem } from "@blueprintjs/core";

interface Item {
  label: any;
  value: any;
}

interface Props {
  items: Array<Item>;
  children: ReactNode;
  onSelect: (item: Item) => void;
  matchTargetWidth?: boolean;
  intent?: Intent;
}

const Select = ({ items, onSelect, children, matchTargetWidth = true, intent = Intent.PRIMARY, ...props }: Props) => {
  const renderItem = (option: Item, { handleClick, handleFocus, modifiers }: ItemRendererProps) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        intent={intent}
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={option.value}
        label={undefined}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={option.label}
      />
    );
  };

  return (
    <Select2
      filterable={false}
      items={items}
      itemRenderer={renderItem}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
      onItemSelect={onSelect}
      popoverContentProps={{
        style: {
          maxHeight: "20rem",
          overflowY: "scroll",
          padding: "3px !important",
          overflowX: "hidden",
        },
      }}
      popoverProps={{
        minimal: true,
        matchTargetWidth,
      }}
      {...props}
    >
      {children}
    </Select2>
  );
};

export default Select;
