import { Popover2 } from "@blueprintjs/popover2";
import { useState } from "react";
import { SketchPicker } from "react-color";

const styles: Record<string, React.CSSProperties> = {
  container: {
    cursor: "pointer",
    height: "fit-content",
  },
};

interface Props {
  children: React.ReactNode;
  onChangeColor: (color: string) => void;
  onTempColorChange: (color: string) => void;
  color: string;
}

const SectionColorPicker = ({ children, onChangeColor, color, onTempColorChange }: Props) => {
  const [isPickerShown, setPickerShown] = useState(false);

  const handleSaveColor = () => {
    onChangeColor(color.substring(1));
  };

  return (
    <Popover2
      interactionKind="click"
      isOpen={isPickerShown}
      placement="auto"
      onInteraction={(nextState) => setPickerShown(nextState)}
      onClosed={handleSaveColor}
      content={
        <div>
          {/* @ts-ignore */}
          <SketchPicker color={color} onChangeComplete={(c) => onTempColorChange(c.hex.substring(1))} />
        </div>
      }
    >
      <div style={styles.container}>{children}</div>
    </Popover2>
  );
};

export default SectionColorPicker;
