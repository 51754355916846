import { ArticleRow } from "@/components";
import { useStock } from "@/hooks/data";
import { Article, Ticker } from "@/types";
import { Text } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};

interface Props {
  ticker: Ticker;
  articleLimit?: number;
  orientation?: "horizontal" | "vertical";
}

const StockNews = ({ ticker, orientation = "horizontal", articleLimit = 5 }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [news, setNews] = useState<Array<Article>>([]);
  const [hasFetched, setHasFetched] = useState<boolean>(false);

  const { getStockNews } = useStock();

  const loadNews = async () => {
    try {
      setLoading(true);
      const n = await getStockNews(ticker.ticker);
      setHasFetched(true);
      setNews(n.slice(0, articleLimit));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ticker && ticker?.description) loadNews();
  }, [ticker]);

  if (!ticker?.description)
    return (
      <Text>
        <i>No related news found.</i>
      </Text>
    );

  return (
    <div style={styles.container}>
      {loading && !hasFetched && <Skeleton height={100} width="100%" count={3} />}
      {hasFetched && !Boolean(news.length) && (
        <Text>
          <i>No related news found.</i>
        </Text>
      )}
      <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
        {news.map((article) => (
          <ArticleRow orientation={orientation} article={article} key={article.url} />
        ))}
      </div>
    </div>
  );
};

export default StockNews;
