import { useWidth } from "@/utils";
import { Colors, H5, Text } from "@blueprintjs/core";
import React from "react";
import { Link } from "react-router-dom";
import InnerContainer from "./InnerContainer";

const styles: Record<string, React.CSSProperties> = {
  outerContainer: {
    width: "100%",
    background: Colors.LIGHT_GRAY3,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5rem 2rem",
    gap: 40,
  },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
};

const Footer = () => {
  const { sm } = useWidth();

  return (
    <div style={styles.outerContainer}>
      <InnerContainer>
        <div
          style={{
            ...styles.container,
            flexDirection: sm ? "column-reverse" : "row",
            textAlign: sm ? "center" : "start",
          }}
        >
          <div
            style={{
              ...styles.column,
              alignItems: sm ? "center" : "flex-start",
            }}
          >
            <Link to="/">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 10,
                }}
              >
                <img alt="ittybrief icon" style={{ width: "1rem" }} src={require("@/assets/icon-tight.png")} />
                <H5 style={{ margin: 0 }}>ittybrief</H5>
              </div>
            </Link>
            <Text>&copy; 2023 JUKEBLOX LLC</Text>
            <Text>All Rights Reserved.</Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: sm ? "center" : "flex-end",
              flexDirection: sm ? "column" : "row",
              gap: 40,
            }}
          >
            <div style={styles.column}>
              <H5 style={{ marginBottom: 10 }}>The Brief</H5>
              {/* <Link to="/features">
                <Text>Features</Text>
              </Link> */}
              <Link to="/faq">
                <Text>FAQ</Text>
              </Link>
            </div>
            <div style={styles.column}>
              <H5 style={{ marginBottom: 10 }}>Legal</H5>
              <Link to="/privacy">
                <Text>Privacy Policy</Text>
              </Link>
              <Link to="/terms">
                <Text>Terms of Service</Text>
              </Link>
            </div>
            <div style={styles.column}>
              <H5 style={{ marginBottom: 10 }}>Contact</H5>
              <Link to="/my/feedback">
                <Text>Share Feedback</Text>
              </Link>
              <a style={{ color: "inherit" }} href="mailto:jack@ittybrief.com">
                <Text>jack@ittybrief.com</Text>
              </a>
            </div>
          </div>
        </div>
      </InnerContainer>
    </div>
  );
};

export default Footer;
