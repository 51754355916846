import React from "react";

import { Colors, FormGroup, TextArea, TextAreaProps } from "@blueprintjs/core";

interface Props extends TextAreaProps {
  value: string;
  onChange: any;
  label?: string;
  style?: React.CSSProperties;
  allowEnter?: boolean;
}

const MultilineInput = ({ value, style = {}, label, allowEnter = false, ...props }: Props) => {
  const onKeyDown = (e) => {
    if (e.keyCode === 13 && !allowEnter) {
      e.preventDefault();
      e.target.form.requestSubmit();
    }
  };

  return (
    <FormGroup label={label} style={{ width: "100%", marginBottom: 0 }}>
      <TextArea
        onKeyDown={onKeyDown}
        growVertically={true}
        large={true}
        style={{
          ...style,
          color: Colors.BLACK,
          resize: "none",
          minHeight: "5rem",
          width: "100%",
        }}
        value={value}
        {...props}
      />
    </FormGroup>
  );
};

export default MultilineInput;
