import { HTMLTable } from "@blueprintjs/core";
import { UilAngleRight } from "@iconscout/react-unicons";
import Skeleton from "react-loading-skeleton";
import { UseLists } from "./useLists";
import { useNavigate } from "react-router-dom";

interface Props {
  useListsInstance: UseLists;
}

function ListTable({ useListsInstance }: Props) {
  const { lists, loading } = useListsInstance;
  const navigate = useNavigate();

  return (
    <HTMLTable style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Number of Recipients</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {loading && (
          <tr>
            <td colSpan={3}>
              <Skeleton height={10} />
              <Skeleton height={10} />
              <Skeleton height={10} />
            </td>
          </tr>
        )}
        {lists.map((list) => (
          <tr key={list.id} className="gray-hover" onClick={() => navigate(list.id)}>
            <td style={{ verticalAlign: "middle" }}>{list.name}</td>
            <td style={{ verticalAlign: "middle" }}>{list.users.length}</td>
            <td style={{ textAlign: "right", verticalAlign: "middle" }}>
              <UilAngleRight />
            </td>
          </tr>
        ))}
        {!Boolean(lists.length) && !loading && (
          <tr>
            <td colSpan={3}>
              <i>No lists found.</i>
            </td>
          </tr>
        )}
      </tbody>
    </HTMLTable>
  );
}

export default ListTable;
