import { Footer, FullContainer, InnerContainer, Navbar } from "@/components";

const PrivacyPolicy = () => {
  return (
    <FullContainer>
      <Navbar />
      <InnerContainer style={{ maxWidth: "40rem", padding: "2rem 0 5rem" }}>
        <h1>
          <strong>Privacy Policy</strong>
        </h1>
        <p>Last Updated: February 27, 2023</p>
        <p>ittybrief operates under the JUKEBLOX LLC entity hereby referred to as "JLLC".</p>
        <p>
          JLLC knows you care about how your personal information is used and shared, and we take your privacy seriously. This Privacy
          Policy outlines how we collect, use, and share your personally identifiable information ("Personal Information") through our
          website (<a href="https://www.ittybrief.com/">www.ittybrief.com</a>) and our services. Please read it carefully.&nbsp;
        </p>
        <p>
          Remember that your use of JLLC services is at all times subject to the <a href="https://www.ittybrief.com/terms">Terms of Use</a>,
          which incorporates this Privacy Policy. Any terms we use in this Privacy Policy without defining them have the definitions given
          to them in the <a href="https://www.ittybrief.com/terms">Terms of Use</a>.&nbsp;
        </p>
        <p>
          <strong>What does this Privacy Policy cover?</strong>
        </p>
        <p>
          This Privacy Policy details how we collect, receive, use, store, share, transfer and process your Personal Information. It also
          describes the choices you have regarding the use of your Personal Information, as well as your rights and how you execute these
          rights.
        </p>
        <p>
          This Privacy Policy only applies to the processing of your Personal Information by JLLC as a data controller, meaning where we
          process your Personal Information for our purposes. This Privacy Policy does not apply to any processing of your Personal
          Information by JLLC as a data processor on behalf of a Publisher. Publishers will have their own privacy practices governing their
          use of Personal Information as outlined in their own terms of use and/or privacy policies.
        </p>
        <p>
          <strong>Will JLLC ever change this Privacy Policy?</strong>
        </p>
        <p>
          We’re constantly trying to improve our services, so we may need to change this Privacy Policy from time to time as well, but we
          will alert you to changes by placing a notice on our site, by sending you an email, and/or by some other means.&nbsp;
        </p>
        <p>
          <strong>What Information does JLLC collect?</strong>
        </p>
        <p>
          We collect and process Personal Information about you when you interact with us and our services, as well as when you subscribe to
          any of our paid or unpaid services. This may include:
        </p>
        <ul>
          <li>
            <p>your first and last name;</p>
          </li>
          <li>
            <p>your email address;</p>
          </li>
          <li>
            <p>your phone number;</p>
          </li>
          <li>
            <p>your marketing preferences, including any consents you have given us;</p>
          </li>
          <li>
            <p>information related to the browser or device you use to access our website (including your IP address);</p>
          </li>
          <li>
            <p>
              any information we collect online from you and maintain in association with your account, such as your username and password;
            </p>
          </li>
          <li>
            <p>your subscription status with ittybrief newsletters;</p>
          </li>
          <li>
            <p>any other information you provide us when communicating with us.</p>
          </li>
        </ul>
        <p>
          <strong>How does JLLC use your Personal Information?</strong>
        </p>
        <p>We process this Personal Information for the following purposes:</p>
        <ul>
          <li>
            <p>
              To establish and fulfill a contract with you, for example when you subscribe to a subscription Service. This may include
              verifying your identity, taking payments, communicating with you, providing customer service;
            </p>
          </li>
          <li>
            <p>
              As required by JLLC to enable our business and pursue our legitimate interests. In particular we use your Personal Information
              for the following purposes:
            </p>
            <ul>
              <li>
                <p>to provide services you have requested, and respond to any communications, comments or complaints you send us;</p>
              </li>
              <li>
                <p>to monitor the use of our services and to help us monitor, improve and protect our services, content and website;</p>
              </li>
              <li>
                <p>allow you to create, maintain, customize and secure your account with us;&nbsp;</p>
              </li>
              <li>
                <p>to personalize our services for you;</p>
              </li>
              <li>
                <p>
                  to monitor any user accounts to prevent, investigate and/or report fraud, misrepresentation, terrorism, security incidents
                  or crime in accordance with applicable law;
                </p>
              </li>
              <li>
                <p>to invite you to take part in surveys or market research.</p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              Compliance with applicable laws and protection of JLLC's legitimate business interests and legal rights, including but not
              limited to use in connection with legal claims, compliance, regulatory, investigative purposes (including disclosure of such
              information in connection with legal process or litigation).&nbsp;
            </p>
          </li>
          <li>
            <p>
              In addition, we will send you, based on your consent (if required), direct marketing communication in relation to our relevant
              services, or other services provided by us, our affiliates and carefully selected partners. You can withdraw your consent at
              any time ("opt out"); see the section "What are your rights?" below. In case of electronic direct marketing you can opt out by
              following the instructions in the communication.
            </p>
          </li>
          <li>
            <p>
              In certain cases, we may also share some Personal Information with third parties, but only as described in this Privacy
              Policy.
            </p>
          </li>
        </ul>
        <p>
          <strong>How will JLLC share the Personal Information it receives?</strong>
        </p>
        <p>We may share your Personal Information with third parties as described below:</p>
        <ul>
          <li>
            <p>
              <strong>Affiliates: </strong>We may disclose your Personal Information to our subsidiaries and/or corporate affiliates for the
              purposes as described above.
            </p>
          </li>
          <li>
            <p>
              <strong>Our Service Providers:</strong> We share your Personal Information with third party service providers that provide
              services on our behalf. Such third parties further include, but are not limited to, providers of: website hosting; maintenance
              services; email services; security services; content delivery networks; customer support operations and software services;
              traffic and usage analytics services; and cloud storage and computing services.
            </p>
          </li>
          <li>
            <p>
              <strong>Prospective sellers or buyers: </strong>We may share and/or transfer customer information in connection with the sale
              or merger of our business or assets (subject to local laws). Also, if we go out of business, enter bankruptcy, or go through
              some other change of control.&nbsp;
            </p>
          </li>
          <li>
            <p>
              <strong>Government authorities and/or law enforcement officials: </strong>If required for the purposes as described in this
              Privacy Policy, if mandated by law or if required for the legal protection of our legitimate interests in compliance with
              applicable laws we may share Personal Information with competent regulatory, prosecuting, tax or governmental authorities,
              courts or other tribunals in any jurisdiction or markets, domestic or foreign.&nbsp;
            </p>
          </li>
        </ul>
        <p>
          In certain cases, we may anonymize your Personal Information in such a way that you can no longer be identified as an individual,
          and we reserve the right to use and share such anonymized information to trusted partners not specified here. However, we never
          disclose aggregated or de-identified information in a manner that could identify you as an individual.
        </p>
        <p>
          <strong>Where will we send your Personal Information?&nbsp;</strong>
        </p>
        <p>
          JLLC is established in the US and uses service providers established both in the US and in other countries to process Personal
          Information as described in this Privacy Policy. As such, your Personal Information may be shared internationally.
        </p>
        <p>
          <strong>Is Personal Information about you secure?</strong>
        </p>
        <p>
          Your account is protected by a password for your privacy and security. You must prevent unauthorized access to your account and
          Personal Information by selecting and protecting your password appropriately and limiting access to your computer or device and
          browser by signing off after you have finished accessing your account.
        </p>
        <p>
          We endeavor to protect the privacy of your account and other Personal Information we hold in our records, but unfortunately, we
          cannot guarantee complete security. Unauthorized entry or use, failure of the services, or other factors may compromise the
          security of user information at any time.
        </p>
        <p>
          <strong>What are your rights?</strong>
        </p>
        <p>
          Depending on applicable local laws, you may be entitled to ask JLLC for a copy of your Personal Information, to correct it, erase
          or restrict its processing, or to ask us to transfer some of this information to other organizations. You may also have rights to
          object to some processing activities or to request restriction of some processing activities. Where we have asked for your consent
          to process your Personal Information, you may also have the right to withdraw this consent. These rights may be limited in some
          situations or in accordance with applicable law – for example, we cannot delete your Personal Information when we can demonstrate
          that we have a legal obligation to retain it. In some instances, this may mean that we are able to retain data even if you
          withdraw your consent or you delete your account.&nbsp;
        </p>
        <p>
          Where we require Personal Information to comply with legal or contractual obligations, then provision of such information is
          mandatory: if such information is not provided, then we will not be able to manage our contractual relationship, or to meet
          obligations placed on us. In all other cases, provision of requested personal data is optional. Please note we will always inform
          you where the provision of your Personal Information is mandatory or optional.
        </p>

        <p>
          <em>You can access, edit, or delete some personal information by yourself</em>
        </p>
        <p>
          Through your account settings, you may access, and, in some cases, edit or delete the following information you’ve provided to us:
        </p>
        <ul>
          <li>
            <p>password</p>
          </li>
          <li>
            <p>email address</p>
          </li>
        </ul>
        <p>
          The information you can view, update, and delete may change as the services change. If you'd like to delete your account, you can
          do so by contacting our customer service email.
        </p>
        <p>
          <em>You can unsubscribe from our marketing communications</em>
        </p>
        <p>
          We remind you that this Privacy Policy does not apply to any processing of your Personal Information by JLLC as a data processor
          on behalf of a Publisher. A Publisher’s own terms and policies govern its use of Personal Information it collects on the
          Publisher’s subdomain on the services, including their own marketing emails and other communications.&nbsp;
        </p>
        <p>
          <em>You have the right to complain to your local data protection authority</em>
        </p>
        <p>
          In the event you have unresolved concerns, please note that you have the right to complain to a data protection authority. Contact
          details for data protection authorities in the EEA, Switzerland and certain non-European countries are available{" "}
          <a href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm">here</a>.
        </p>
        <p>
          <strong>How long will JLLC retain your data?</strong>
        </p>
        <p>
          We retain information about you only for as long as reasonably necessary to fulfill the purposes for which it was collected. We
          may retain your Personal Information for a longer period in the event of a complaint or if we reasonably believe there is a
          prospect of litigation in respect to our relationship with you.
        </p>
        <p>
          <strong>Automated individual decision-making, including profiling</strong>
        </p>
        <p>We do not process your Personal Information for automated individual decision-making, including profiling.&nbsp;</p>
      </InnerContainer>
      <Footer />
    </FullContainer>
  );
};

export default PrivacyPolicy;
