import { SET_USER, RESET_USER, UPDATE_USER } from "../types";

import { User } from "@/types";

const initial = null;

const user = (state: User = initial, action) => {
  switch (action.type) {
    case RESET_USER:
      return initial;
    case SET_USER:
      return action.payload;
    case UPDATE_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default user;
