import { clientAxiosInstance } from "@/services";

interface OnboardingDetails {
  numberOfSections: number;
}

export const getOnboardingDetails = async (): Promise<OnboardingDetails> => {
  const response = await clientAxiosInstance.get<OnboardingDetails>("/account/onboarding-details");
  return response.data;
};
