import { clientAxiosInstance } from "@/services";
import { ArticleSource } from "@/types";
import { toaster } from "@/utils";
import { Intent } from "@blueprintjs/core";
import { useEffect, useState } from "react";

interface UseArticlesCount {
  loading: boolean;
  articlesCount: Record<ArticleSource, number> | undefined;
  reload: () => Promise<void>;
}

export const useArticlesCount = (): UseArticlesCount => {
  const [loading, setLoading] = useState<boolean>(true);
  const [articlesCount, setArticlesCount] = useState<Record<ArticleSource, number>>();

  const getArticlesCount = async () => {
    setLoading(true);
    try {
      const response = await clientAxiosInstance.get<Record<ArticleSource, number>>("/admin/metrics/articles/count");
      setArticlesCount(response.data);
    } catch (e) {
      toaster.show({ intent: Intent.DANGER, message: e.message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getArticlesCount();
  }, []);

  return {
    loading,
    articlesCount,
    reload: getArticlesCount,
  };
};
