import { Input } from "@/components";
import { Button, H4, Intent } from "@blueprintjs/core";
import { UilCheckCircle } from "@iconscout/react-unicons";
import { useState } from "react";
import { UseLists } from "./useLists";
import { useNavigate } from "react-router-dom";

interface Props {
  useListsInstance: UseLists;
  handleClose: () => void;
}

function AddListForm({ useListsInstance, handleClose }: Props) {
  const [name, setName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { createList } = useListsInstance;

  const navigate = useNavigate();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const createdList = await createList(name);
      handleClose();
      navigate(createdList.id);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={onSubmit} style={{ padding: "1rem", display: "flex", flexDirection: "column" }}>
      <H4>Add Email List</H4>
      <Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
      <div style={{ marginTop: "0.5rem" }}>
        <Button loading={loading} intent={Intent.SUCCESS} type="submit" icon={<UilCheckCircle size={18} />}>
          Submit
        </Button>
      </div>
    </form>
  );
}

export default AddListForm;
