import { clientAxiosInstance } from "@/services";
import { SET_USER } from "@/store/types";
import { User } from "@/types";
import { AnyAction } from "@reduxjs/toolkit";
import { Dispatch } from "react";

interface GetUserResponse {
  user: User;
}

export const getUser = async (): Promise<User> => {
  const getUserResponse = await clientAxiosInstance.get<GetUserResponse>("/login");
  return getUserResponse.data.user;
};

export const tryToGetUser = async (onSuccess: Dispatch<AnyAction>, onFail: () => void) => {
  try {
    const user = await getUser();
    onSuccess({ type: SET_USER, payload: user });
  } catch (e) {
    onFail();
  }
};
