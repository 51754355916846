import { RootState } from "@/store/reducers";
import { Spinner } from "@blueprintjs/core";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { AdminProxy } from "./AdminProxy";
import { AdminWrapper } from "@/views";
import { AdminMetricsPage } from "@/scenes";
import { AdminManualTest } from "@/scenes/admin-manual-test";

const AdminRouter = () => {
  const user = useSelector((state: RootState) => state.user);

  return (
    <>
      {!user && <Spinner size={20} style={{ position: "fixed", top: "50%", left: "50%" }} />}
      {user && (
        <AdminProxy user={user}>
          <Routes>
            <Route path="/" element={<Navigate to="metrics" replace />} />
            <Route
              path="/metrics"
              element={
                <AdminWrapper>
                  <AdminMetricsPage />
                </AdminWrapper>
              }
            />
            {/* <Route
              path="/curated-briefs"
              element={
                <AdminWrapper>
                  <AdminCuratedBriefsPage />
                </AdminWrapper>
              }
            />
            <Route path="/curated-briefs/:newsletterId/:newsletterSendId" element={<AdminCuratedBriefPage />} /> */}
            <Route
              path="/manual-test"
              element={
                <AdminWrapper>
                  <AdminManualTest />
                </AdminWrapper>
              }
            />
            <Route path="*" element={<Navigate to="metrics" replace />} />
          </Routes>
        </AdminProxy>
      )}
    </>
  );
};

export default AdminRouter;
