import { useLinkClicksMetrics } from "@/hooks/data";
import { Divider, H4, HTMLTable } from "@blueprintjs/core";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const AdminLinkClicksMetrics = () => {
  const { linkClicks, loading } = useLinkClicksMetrics();

  return (
    <>
      <H4>Link Clicks</H4>
      <Divider style={{ margin: 0 }} />
      <HTMLTable style={{ width: "100%" }} bordered>
        <thead>
          <tr>
            <th>Date</th>
            <th>One Click</th>
            <th>Two Clicks</th>
          </tr>
        </thead>
        <tbody>
          {linkClicks.map(({ date, oneClick, twoClicks }) => (
            <tr key={date}>
              <td>{moment(date).format("MMM Do")}</td>
              <td>{oneClick}</td>
              <td>{twoClicks}</td>
            </tr>
          ))}
          {!Boolean(linkClicks.length) && !loading && (
            <tr>
              <td>No data</td>
              <td>No data</td>
            </tr>
          )}
        </tbody>
      </HTMLTable>
      {loading && <Skeleton count={10} height={10} />}
    </>
  );
};

export default AdminLinkClicksMetrics;
