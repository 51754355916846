import { MultilineInput } from "@/components";
import { UseList } from "./useList";
import { ListUser } from "@/types";
import { useState } from "react";
import { Button, Colors, Intent } from "@blueprintjs/core";
import { UilCheckCircle } from "@iconscout/react-unicons";

interface Props {
  listUser: ListUser;
  useListInstance: UseList;
  onClose: () => void;
}

function EditUserAttributesForm({ useListInstance, listUser, onClose }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [userDescription, setUserDescription] = useState<string>(listUser.userDescription);

  const { updateListUserDescription } = useListInstance;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      await updateListUserDescription(listUser.id, userDescription);
      onClose();
    } catch (err) {
      setError(err.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={onSubmit} style={{ display: "flex", flexDirection: "column" }}>
      <MultilineInput
        allowEnter
        value={userDescription}
        onChange={(e) => setUserDescription(e.target.value)}
        style={{ marginBottom: "0.5rem" }}
      />
      {error && <div style={{ marginBottom: "0.5rem", color: Colors.RED3 }}>{error}</div>}
      <div>
        <Button type="submit" loading={loading} intent={Intent.SUCCESS} icon={<UilCheckCircle size={18} />}>
          Save
        </Button>
      </div>
    </form>
  );
}

export default EditUserAttributesForm;
