import { InnerContainer } from "@/components";
import { useWidth } from "@/utils";
import { H3 } from "@blueprintjs/core";
import Skeleton from "react-loading-skeleton";
import { Sections } from "./Sections";
import { TickerSections } from "./TickerSections";
import { InstantBriefSend } from "@/types";
import NavigateToSection from "./NavigateToSection";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const styles: Record<string, React.CSSProperties> = {
  innerContainer: {
    maxWidth: "35rem",
    width: "100%",
    padding: 0,
    paddingBottom: "8rem",
    alignItems: "center",
  },
  titleSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    gap: "1rem",
  },
  icon: {
    width: "1.75rem",
    objectFit: "contain",
  },
};

const LoadingState = () => (
  <Skeleton
    wrapper={({ children }) => <div style={{ display: "flex", flexDirection: "column" }}>{children}</div>}
    count={5}
    height={100}
    width="100%"
  />
);

interface Props {
  briefSend: InstantBriefSend | undefined;
  loading: boolean;
  style?: React.CSSProperties;
}

const BriefDisplayView = ({ briefSend, loading, style = {} }: Props) => {
  const { xs } = useWidth();

  const sectionPadding = xs ? "2rem" : "3rem";

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.substring(1));
      if (elem) elem.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  if (!loading && !briefSend) return null;

  return (
    <InnerContainer style={{ ...styles.innerContainer, paddingTop: xs ? "1rem" : "3rem", ...style }}>
      <div style={styles.titleSection}>
        {briefSend?.subject ? <H3 style={{ margin: 0 }}>{briefSend.subject}</H3> : <Skeleton height={10} width={500} count={2} />}
        <img src="https://ittybrief.blob.core.windows.net/static/robot-icon.png" style={styles.icon} />
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "100%", paddingTop: sectionPadding }}>
        {loading && <LoadingState />}
        {briefSend?.sections?.length > 2 && (
          <>
            <NavigateToSection sections={briefSend?.sections || []} />
            <div style={{ height: Boolean(briefSend?.tickers?.length) ? "1rem" : sectionPadding }} />
          </>
        )}

        {Boolean(briefSend?.tickers?.length) && (
          <>
            <TickerSections tickers={briefSend.tickers} />
            <div style={{ height: sectionPadding }} />
          </>
        )}
        <Sections sections={briefSend?.sections || []} />
      </div>
    </InnerContainer>
  );
};

export default BriefDisplayView;
