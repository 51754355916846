import { UseNewsletters, useNewsletters } from "@/hooks/data";
import NewsletterCard from "./NewsletterCard";
import { Colors, Divider, H2 } from "@blueprintjs/core";
import { useWidth } from "@/utils";
import NewsletterChip from "./NewsletterChip";
import Skeleton from "react-loading-skeleton";
import React, { useEffect } from "react";
import { useSearchParamsState } from "@/hooks";
import { SearchParamKey } from "@/types";

const sidebarContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  borderRight: "1px solid " + Colors.LIGHT_GRAY2,
  padding: "2rem 1rem 3rem 0",
  maxWidth: "30rem",
};

interface Props {
  useNewslettersInstance: UseNewsletters;
}

const NewslettersSidebar = ({ useNewslettersInstance }: Props) => {
  const [newsletterId, setNewsletterId] = useSearchParamsState<string>(SearchParamKey.NEWSLETTER_ID);
  const { newsletters, loading } = useNewslettersInstance;

  useEffect(() => {
    if (!newsletterId && newsletters.length > 0) {
      setNewsletterId(newsletters[0].id);
    }
  }, [newsletterId, newsletters]);

  return (
    <div style={sidebarContainerStyle}>
      <H2 style={{ marginBottom: "1.5rem" }}>Curated briefs</H2>
      {loading && <Skeleton count={3} height={15} />}
      {!loading &&
        newsletters.map((newsletter, i) => (
          <React.Fragment key={newsletter.id}>
            <NewsletterCard key={newsletter.id} newsletter={newsletter} />
            <Divider style={{ marginTop: "0.5rem", marginBottom: "1rem" }} />
          </React.Fragment>
        ))}
    </div>
  );
};

const NewslettersChips = ({ useNewslettersInstance }: Props) => {
  const { newsletters, loading } = useNewslettersInstance;

  return (
    <div style={{ display: "flex", padding: "2rem 0 1rem", flexDirection: "column" }}>
      <H2>Curated briefs</H2>
      {loading && <Skeleton count={1} height={25} />}
      {!loading && (
        <div className="no-scrollbar" style={{ display: "flex", gap: "0.5rem", overflow: "auto", width: "100%" }}>
          {newsletters.map((newsletter, i) => (
            <NewsletterChip key={newsletter.id} newsletter={newsletter} />
          ))}
        </div>
      )}
    </div>
  );
};

const NewsletterNavigationProxy = () => {
  const { md } = useWidth();
  const useNewslettersInstance = useNewsletters();

  if (md) return <NewslettersChips useNewslettersInstance={useNewslettersInstance} />;

  return <NewslettersSidebar useNewslettersInstance={useNewslettersInstance} />;
};

export default NewsletterNavigationProxy;
