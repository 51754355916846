import { ReactNode, useEffect } from "react";
import { OnboardingAddSection, OnboardingChooseTime, OnboardingGreeting } from "@/scenes";
import { RootState } from "@/store/reducers";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { getOnboardingDetails } from "./getOnboardingDetails";

interface Props {
  user: RootState["user"];
  children: ReactNode;
}

const OnboardingRouter = () => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const { numberOfSections } = await getOnboardingDetails();
        navigate(numberOfSections > 0 ? "/my/onboarding/choose-time" : "/my/onboarding");
      } catch (e) {}
    })();
  }, []);

  return (
    <Routes>
      <Route path="onboarding" element={<OnboardingGreeting />} />
      <Route path="onboarding/add-section" element={<OnboardingAddSection />} />
      <Route path="onboarding/choose-time" element={<OnboardingChooseTime />} />
      <Route path="*" element={<Navigate to="/my/onboarding" replace />} />
    </Routes>
  );
};

const OnboardingProxy = ({ children, user }: Props) => {
  if (user.isOnboarded) return <>{children}</>;

  return <OnboardingRouter />;
};

export default OnboardingProxy;
