import React from "react";

import { H1, Text, UL } from "@blueprintjs/core";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
};

const EmailSpam = () => {
  return (
    <div style={styles.container}>
      <H1>How can I prevent my emails from landing in the spam folder?</H1>
      <div style={{ marginBottom: 20 }} />
      <Text>
        Email providers use several factors such as the sender, content, and other indicators to determine if an email should go to the Spam
        folder.
      </Text>
      <div style={{ marginBottom: 10 }} />
      <Text>Here are a few steps that may help:</Text>
      <UL>
        <li>
          If a newsletter ends up in the Spam folder, make sure to mark it as "not spam" and move it to the Inbox in your email client.
        </li>
        <li>Add our email address to your contacts list. The emails will be sent from this address: no-reply@ittybrief.com.</li>
        <li>Send an email to the email address: no-reply@ittybrief.com.</li>
      </UL>
      <Text>
        By taking these steps, your email client will be more likely to recognize emails from ittybrief as legitimate and not spam.
      </Text>
    </div>
  );
};

export default EmailSpam;
