import { useEffect, useState } from "react";
import { useDebounce } from "../useDebounce";
import { Article } from "@/types";
import { clientAxiosInstance } from "@/services";
import { toaster } from "@/utils";
import { Intent } from "@blueprintjs/core";

interface UseAdminArticleSearch {
  loading: boolean;
  articles: Array<Article>;
}

export const useAdminArticleSearch = (query: string): UseAdminArticleSearch => {
  const [loading, setLoading] = useState<boolean>(false);
  const [articles, setArticles] = useState<Array<Article>>([]);

  const getArticles = async () => {
    setLoading(true);
    try {
      const response = await clientAxiosInstance.get<Array<Article>>(`/admin/articles`, {
        params: {
          prompt: query,
        },
      });
      setArticles(response.data);
    } catch (e) {
      console.log(e);
      toaster.show({
        message: e.message,
        intent: Intent.DANGER,
      });
    }
    setLoading(false);
  };

  const debouncedQuery = useDebounce(query, 1500);

  useEffect(() => {
    setArticles([]);
    if (debouncedQuery) getArticles();
  }, [debouncedQuery]);

  return {
    articles,
    loading,
  };
};
