import React from "react";

import { Intent, Switch } from "@blueprintjs/core";
import { toaster } from "@/utils";
import { UseBrief } from "@/hooks/data";

interface Props {
  isSubscribed: boolean;
  changeIsSubscribed: UseBrief["changeIsSubscribed"];
}

const UnsubscribeSection = ({ isSubscribed, changeIsSubscribed }: Props) => {
  const onToggle = async (e: React.SyntheticEvent) => {
    try {
      await changeIsSubscribed(!isSubscribed);
    } catch (err) {
      console.log(err);
      toaster.show({
        message: err?.message || "Error updating. Please reach out to our team.",
        intent: Intent.DANGER,
      });
    }
  };

  return (
    <Switch onChange={onToggle} style={{ marginTop: 20 }} large checked={isSubscribed}>
      {isSubscribed ? (
        <span style={{ fontWeight: 600 }}>&#128513; Subscribed</span>
      ) : (
        <span style={{ fontWeight: 600 }}>&#128532; Unsubscribed</span>
      )}
    </Switch>
  );
};

export default UnsubscribeSection;
