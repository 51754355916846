import { ReactNode } from "react";
import { JwtRole, User } from "@/types";
import { NotFound } from "@/scenes";

interface Props {
  children: ReactNode;
  user: User;
}

const ListsProxy = ({ children, user }: Props) => {
  const isListUser = user.roles.includes(JwtRole.LIST_USER);

  if (isListUser) return <>{children}</>;

  return <NotFound />;
};

export default ListsProxy;
