import { useStock } from "@/hooks/data";
import { IDailyOpenClose, Ticker } from "@/types";
import { decimalToTickerPercentage } from "@/utils";
import { Intent, Spinner, Tag, Text } from "@blueprintjs/core";
import { useEffect, useMemo, useState } from "react";

interface Props {
  ticker: Ticker;
  hideText?: boolean;
}

const UserTickerTag = ({ ticker, hideText }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dailyData, setDailyData] = useState<IDailyOpenClose>();
  const [previousData, setPreviousDailyData] = useState<IDailyOpenClose>();
  const { getStockDailyOpenClose } = useStock();

  const loadStockDailyOpenClose = async () => {
    try {
      setLoading(true);
      setDailyData(undefined);
      setPreviousDailyData(undefined);
      const { current, previous } = await getStockDailyOpenClose(ticker.ticker);
      setDailyData(current.result);
      setPreviousDailyData(previous.result);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadStockDailyOpenClose();
  }, [ticker]);

  const percentChange = useMemo(() => {
    if (dailyData && previousData) {
      return (dailyData.close - previousData.close) / previousData.close;
    }
  }, [dailyData, previousData]);

  return (
    <div>
      <Tag
        rightIcon={loading ? <Spinner size={12} /> : dailyData && previousData && <Text>{decimalToTickerPercentage(percentChange)}</Text>}
        intent={!percentChange ? Intent.PRIMARY : percentChange > 0 ? Intent.SUCCESS : Intent.DANGER}
        large
        minimal
        interactive
      >
        {!hideText && <span style={{ fontWeight: 600 }}>{ticker.ticker}</span>}
      </Tag>
    </div>
  );
};

export default UserTickerTag;
