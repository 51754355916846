import { ArticleRow } from "@/components";
import { useSectionNews } from "@/hooks/data";
import { Section } from "@/types";
import { useWidth } from "@/utils";
import { Colors, Divider, H2, Text } from "@blueprintjs/core";
import React from "react";
import Skeleton from "react-loading-skeleton";

const styles: Record<string, React.CSSProperties> = {
  container: { display: "flex", flexDirection: "column" },
  column: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    flex: 1,
    flexBasis: 0,
    flexGrow: 1,
    minWidth: 0,
  },
  borderLeftStyle: {
    borderLeft: `1px solid ${Colors.LIGHT_GRAY2}`,
    paddingLeft: 20,
  },
  headerContainer: {
    display: "flex",
    gap: 10,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },
};

interface Props {
  section: Section;
}

const SectionNewsSection = ({ section }: Props) => {
  const { xs, sm } = useWidth();

  const { articles, loading } = useSectionNews(section.id);

  const Header = () => (
    <div style={styles.headerContainer}>
      <H2 style={{ margin: 0 }}>{section.originalPrompt}</H2>
    </div>
  );

  const divide = sm ? 1 : 2;

  return (
    <div style={{ ...styles.container, ...(!xs && styles.borderLeftStyle), flex: sm ? 1 : 2 }}>
      <Header />
      <Divider style={{ marginBottom: 20 }} />
      <div style={{ display: "flex", gap: 20 }}>
        {!loading && !Boolean(articles.length) && (
          <Text>
            <i>No related news found.</i>
          </Text>
        )}
        {Array.from({ length: divide }).map((_outer, i) => (
          <div
            key={i}
            className="fade-in"
            style={{
              ...styles.column,
              ...(i !== 0 && styles.borderLeftStyle),
            }}
          >
            {loading && (
              <Skeleton
                wrapper={({ children }) => <div style={{ display: "flex", flexDirection: "column" }}>{children}</div>}
                height={100}
                width="100%"
                count={5}
              />
            )}
            {articles
              .slice(0, 10)
              .filter((_inner, i2) => (i2 + 1) % divide === i)
              .map((article, i2) => (
                <React.Fragment key={article.id}>
                  <ArticleRow
                    hideImage={(i2 % 3) - i !== 0}
                    orientation={(i2 % 3) - i === 0 ? "vertical" : "horizontal"}
                    article={article}
                  />
                  <Divider style={{ width: "100%", marginBottom: 10 }} />
                </React.Fragment>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionNewsSection;
