export enum AdminPage {
  METRICS = "metrics",
  MANUAL_TEST = "manual-test",
}

export const AdminPageMapping: Record<AdminPage, { tabLabel: string }> = {
  [AdminPage.METRICS]: {
    tabLabel: "Metrics",
  },
  [AdminPage.MANUAL_TEST]: {
    tabLabel: "Manual Test",
  },
};
