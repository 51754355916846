import { useWidth } from "@/utils";
import { Colors, H5, Icon } from "@blueprintjs/core";
import { Link } from "react-router-dom";

const containerStyle: React.CSSProperties = {
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: "0.75rem",
  background: Colors.BLUE3,
  gap: "0.5rem",
  color: "white",
};

const PersonalizedBanner = () => {
  const { xs } = useWidth();

  return (
    <div style={containerStyle}>
      <Icon icon="issue" size={18} />
      <H5 style={{ margin: 0, textAlign: "center", color: "white", fontSize: xs ? "0.8rem" : "" }}>
        Want more control?{" "}
        <span style={{ fontWeight: 400 }}>
          Build{" "}
          <Link style={{ textDecoration: "underline" }} to="/">
            a personalized brief
          </Link>
          !
        </span>
      </H5>
      <Icon icon="build" size={18} />
    </div>
  );
};

export default PersonalizedBanner;
