import React from "react";

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const InnerContainer = ({ children, style }: Props) => {
  const container: React.CSSProperties = {
    width: "100%",
    maxWidth: "70rem",
    margin: "0 auto",
    padding: "0 1rem",
    display: "flex",
    flexDirection: "column",
    ...style,
  };

  return <div style={container}>{children}</div>;
};

export default InnerContainer;
