import { useStock } from "@/hooks/data";
import { IAggsResults, Ticker } from "@/types";
import { H1, Intent, Tag, Text } from "@blueprintjs/core";
import { useEffect, useState } from "react";
import { convertToDecimalWithCommas } from "@/utils";
import StockChart from "./StockChart";
import moment, { Moment } from "moment";
import Skeleton from "react-loading-skeleton";

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
};

interface Props {
  ticker: Ticker;
  prevClose?: number;
  // timestamp
  today?: Moment;
}

const StockAggregateData = ({ ticker, prevClose, today }: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [aggregates, setAggregates] = useState<Array<IAggsResults>>([]);
  const { getAggregateData } = useStock();

  const loadAggregates = async () => {
    try {
      setLoading(true);
      const aggs = await getAggregateData(ticker.ticker, today.format());
      setAggregates(aggs);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (ticker && today) loadAggregates();
  }, [ticker, today]);

  const percentChange = ((aggregates?.[aggregates?.length - 1]?.c - prevClose) / prevClose) * 100;

  if (!loading && !aggregates.length) return null;

  return (
    <div style={styles.container}>
      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        <H1 style={{ margin: 0 }}>
          {aggregates.length ? convertToDecimalWithCommas(aggregates?.[aggregates?.length - 1]?.c, 2) : <Skeleton width={100} />}
        </H1>
        {Boolean(aggregates.length) && (
          <div>
            <Tag minimal large intent={percentChange > 0 ? Intent.SUCCESS : Intent.DANGER}>
              {percentChange > 0 && "+"}
              {percentChange.toFixed(2) + "%"}
            </Tag>
          </div>
        )}
      </div>
      <Text style={{ fontSize: "0.8rem", marginBottom: "1rem" }}>
        {aggregates.length ? moment.unix(aggregates[0].t / 1000).format("MMMM Do") : <Skeleton width={50} />}
      </Text>
      {aggregates.length ? <StockChart prevClose={prevClose} aggregates={aggregates} /> : <Skeleton width="100%" height={300} />}
    </div>
  );
};

export default StockAggregateData;
