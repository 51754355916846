import { clientAxiosInstance } from "@/services";
import { Section } from "@/types";
import { useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";

interface AddSectionInput {
  originalPrompt: string;
  enhancedPrompt: string;
}

interface UpdateSectionColorInput {
  sectionId: string;
  color: string;
}

interface ReorderSectionsInput {
  draggedSection: Section;
  belowDestinationSection: Section | null;
  belowSourceSection: Section | null;
}

export interface UseSections {
  loading: boolean;
  sections: Array<Section>;
  reloadSections: () => Promise<void>;
  reorderSections: (input: ReorderSectionsInput, result: DropResult) => Promise<void>;
  addSection: (input: AddSectionInput) => Promise<void>;
  deleteSection: (sectionId: string) => Promise<void>;
  updateSectionColor: (input: UpdateSectionColorInput) => Promise<void>;
}

export const useSections = (): UseSections => {
  const [loading, setLoading] = useState<boolean>(true);
  const [sections, setSections] = useState<Array<Section>>([]);

  const getSections = async () => {
    setLoading(true);
    try {
      const response = await clientAxiosInstance.get<Array<Section>>("/sections");
      setSections(response.data);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const addSection = async (input: AddSectionInput): Promise<void> => {
    await clientAxiosInstance.post<void>(`/sections`, input);
  };

  const deleteSection = async (sectionId: string): Promise<void> => {
    await clientAxiosInstance.delete<void>(`/sections/${sectionId}`);
    setSections(sections.filter((section) => section.id !== sectionId));
  };

  const reorderSections = async (input: ReorderSectionsInput, result: DropResult): Promise<void> => {
    const newList = Array.from(sections);
    const [removed] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, removed);
    setSections(newList);
    await clientAxiosInstance.put<void>("/sections/reorder", input);
  };

  const updateSectionColor = async (input: UpdateSectionColorInput): Promise<void> => {
    const response = await clientAxiosInstance.put<Section>("/sections/color", input);
    setSections(sections.map((section) => (section.id === response.data.id ? { ...section, ...response } : section)));
  };

  useEffect(() => {
    getSections();
  }, []);

  return {
    loading,
    sections,
    reloadSections: getSections,
    reorderSections,
    addSection,
    deleteSection,
    updateSectionColor,
  };
};
