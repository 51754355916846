import { useSections } from "@/hooks/data";
import { Button, Divider, H5, Intent, NonIdealState, Text } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";
import { UilPlusCircle } from "@iconscout/react-unicons";
import { Link, useNavigate } from "react-router-dom";
import SectionsList from "./SectionsList";

const styles: Record<string, React.CSSProperties> = {
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginTop: 30,
  },
};

const SectionsView = () => {
  const navigate = useNavigate();

  const useSectionsInstance = useSections();
  const { sections, loading } = useSectionsInstance;

  return (
    <>
      <div style={styles.headerContainer}>
        <H5 style={{ marginBottom: 2 }}>
          Interests {Boolean(sections.length) && <span style={{ fontSize: "0.7rem", fontWeight: 300 }}>( {sections.length} / 10 )</span>}
        </H5>
        <Tooltip2
          hoverOpenDelay={1000}
          minimal
          content={
            sections.length >= 10 ? (
              <Text style={{ fontSize: "0.75rem" }}>
                You must remove a section <br /> to add another
              </Text>
            ) : null
          }
        >
          <Button
            onClick={() => {
              if (sections.length < 10) navigate("/my/add-section");
            }}
            disabled={sections.length >= 10}
            intent={Intent.PRIMARY}
            small
            icon={<UilPlusCircle size={18} />}
          >
            Add
          </Button>
        </Tooltip2>
      </div>
      <Divider style={{ marginBottom: 10 }} />
      <Text style={{ marginBottom: 5 }}>
        For each of your interests, between 5 and 10 articles or podcasts will be included in your daily brief. The ordering of the sections
        here will dictate the order in which they appear in your brief.
      </Text>
      <Text style={{ marginBottom: 15 }}>To assign each section a color, click the color icon next to the section name.</Text>
      {loading && (
        <Text>
          <i>Loading...</i>
        </Text>
      )}
      {!loading && (
        <>
          {Boolean(sections.length) && <SectionsList useSectionsInstance={useSectionsInstance} sections={sections} />}
          {!Boolean(sections.length) && (
            <NonIdealState
              icon="th-disconnect"
              title="No brief sections found"
              description="Add a section to start receiving your personalized brief!"
              action={
                <Link to="/my/add-section">
                  <Button icon={<UilPlusCircle size={18} />} intent={Intent.PRIMARY}>
                    Add section
                  </Button>
                </Link>
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default SectionsView;
