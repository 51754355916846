export enum JwtRole {
  USER = "user",
  M2M = "m2m",
  ADMIN = "admin",
  LIST_USER = "list-user",
}

export interface User {
  id: string;
  email: string;
  created: string;
  emailConfirmed: boolean;
  isOnboarded: boolean;
  roles: Array<JwtRole>;
}
