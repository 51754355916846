export const convertToDecimalWithCommas = (num: number, fractionDigits: number = 0): string => {
  return num.toLocaleString("en-US", { style: "decimal", minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits });
};

export const convertToAbbreviatedNumber = (num: number): string => {
  return Intl.NumberFormat("en-US", {
    notation: "compact",
    maximumFractionDigits: 2,
  }).format(num);
};

export function decimalToTickerPercentage(decimal: number) {
  return (decimal > 0 ? "+" : "") + (decimal * 100).toFixed(2) + "%";
}
