import { clientAxiosInstance } from "@/services";
import { Article } from "@/types";

interface GetArticlesResponse {
  topic: string;
  displayTopic: string;
  articles: Array<Article>;
}

export const getSectionArticles = async (prompt: string): Promise<GetArticlesResponse> => {
  const articlesResponse = await clientAxiosInstance.post<GetArticlesResponse>("/articles", { prompt });
  return articlesResponse.data;
};
