import { useWidth } from "@/utils";
import { Button, Colors, H2, Text } from "@blueprintjs/core";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

const containerStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  position: "relative",
  flexWrap: "wrap",
};

const stepPhotoContainerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  aspectRatio: "1:1",
  position: "relative",
};

interface StepPhoto {
  title: string | ReactNode;
  description: string | ReactNode;
  urlToImage: string;
  urlToVideo: string;
  hexColor: string;
}

const stepPhotos: Array<StepPhoto> = [
  {
    title: (
      <span>
        Provide your <br /> interests
      </span>
    ),
    description: "Your hobbies, passions, and the things you're most curious about.",
    urlToImage: require("@/assets/steps/interests-thumbnail.png"),
    urlToVideo: require("@/assets/steps/interests.mp4"),
    hexColor: Colors.BLUE3 + "50",
  },
  {
    title: (
      <span>
        Set your <br /> delivery time
      </span>
    ),
    description: "Specify the time your brief will arrive each day.",
    urlToImage: require("@/assets/steps/time-thumbnail.png"),
    urlToVideo: require("@/assets/steps/time.mp4"),
    hexColor: Colors.BLUE4 + "50",
  },
  {
    title: (
      <span>
        Add <br /> market data
      </span>
    ),
    description: "Stay up to date on your portfolio or other equities of interest.",
    urlToImage: require("@/assets/steps/market-thumbnail.png"),
    urlToVideo: require("@/assets/steps/market.mp4"),
    hexColor: Colors.BLUE5 + "50",
  },
  {
    title: (
      <span>
        Get <br /> briefed!
      </span>
    ),
    description: "Each day, you'll receive a personalized news publication!",
    urlToImage: require("@/assets/steps/briefed-thumbnail.png"),
    urlToVideo: require("@/assets/steps/briefed.mp4"),
    hexColor: Colors.BLUE5 + "30",
  },
];

const StepPhoto = ({ stepPhoto }: { stepPhoto: StepPhoto }) => {
  const { xs, sm, md } = useWidth();

  return (
    <div style={{ ...stepPhotoContainerStyle, width: xs ? "100%" : sm ? "50%" : md ? "50%" : "25%", background: stepPhoto.hexColor }}>
      <H2 style={{ width: "100%", zIndex: 10, marginTop: "2rem", padding: "0 2rem", textAlign: "center" }}>{stepPhoto.title}</H2>
      <div style={{ width: "100%", overflow: "hidden", padding: "1rem 2rem" }}>
        <video
          width="100%"
          poster={stepPhoto.urlToImage}
          autoPlay
          muted
          loop
          playsInline
          style={{ borderRadius: 2, pointerEvents: "none" }}
        >
          <source src={stepPhoto.urlToVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem 2rem 2rem",
          width: "100%",
          justifyContent: "space-between",
          height: xs ? "8rem" : sm ? "10rem" : md ? "8rem" : "10rem",
        }}
      >
        <Text style={{ fontSize: xs ? "0.9rem" : "1rem" }}>{stepPhoto.description}</Text>
        <Link to="/start">
          <Button>Get started</Button>
        </Link>
      </div>
    </div>
  );
};

const StepPhotos = () => {
  return (
    <div style={{ ...containerStyle, maxWidth: "90rem", margin: "auto" }}>
      {stepPhotos.map((stepPhoto, index) => (
        <StepPhoto key={index} stepPhoto={stepPhoto} />
      ))}
    </div>
  );
};

export default StepPhotos;
