import { Breadcrumbs, FullContainer, FullScreenSpinner, InnerContainer, Navbar } from "@/components";
import { Navigate, useParams } from "react-router-dom";
import { useList } from "./useList";
import { Button, Divider, Drawer, DrawerSize, H2 } from "@blueprintjs/core";
import ListUserTable from "./ListUserTable";
import { useEffect, useState } from "react";
import AddRecipientsForm from "./AddRecipientsForm";
import ListUserDetail from "./ListUserDetail";
import { ListUser } from "@/types";
import { useWidth } from "@/utils";

function ListPage() {
  const [isAddingRecipients, setIsAddingRecipients] = useState<boolean>(false);
  const [selectedListUser, setSelectedListUser] = useState<ListUser | undefined>();
  const { listId } = useParams();
  const { xs } = useWidth();

  const useListInstance = useList(listId);
  const { list, loading } = useListInstance;

  useEffect(() => {
    if (list?.users && selectedListUser) {
      setSelectedListUser(list.users.find((listUser) => listUser.id === selectedListUser?.id));
    }
  }, [list?.users]);

  if (loading) return <FullScreenSpinner />;

  if (!list) return <Navigate to="/lists" replace />;

  return (
    <>
      <FullContainer>
        <Navbar />
        <InnerContainer style={{ paddingTop: "2rem" }}>
          <Breadcrumbs
            items={[
              { text: "Email Lists", href: "/lists" },
              { text: list.name, href: `/lists/${list.id}`, isCurrent: true },
            ]}
            style={{ marginBottom: "2rem" }}
          />
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
            <H2 style={{ margin: 0 }}>{list.name}</H2>
            <Button onClick={() => setIsAddingRecipients(true)} icon="add">
              Add Recipients
            </Button>
          </div>
          <Divider />
          <ListUserTable onSelectListUser={(listUser) => setSelectedListUser(listUser)} useListInstance={useListInstance} />
        </InnerContainer>
      </FullContainer>
      <Drawer
        size={xs ? DrawerSize.LARGE : DrawerSize.STANDARD}
        position="right"
        isOpen={isAddingRecipients}
        onClose={() => setIsAddingRecipients(false)}
        style={{ zIndex: 1000000 }}
      >
        <AddRecipientsForm handleClose={() => setIsAddingRecipients(false)} useListInstance={useListInstance} />
      </Drawer>
      <Drawer
        size={xs ? DrawerSize.LARGE : DrawerSize.STANDARD}
        position="right"
        isOpen={Boolean(selectedListUser)}
        onClose={() => setSelectedListUser(undefined)}
        style={{ zIndex: 1000000 }}
      >
        <ListUserDetail useListInstance={useListInstance} listUser={selectedListUser} />
      </Drawer>
    </>
  );
}

export default ListPage;
